<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="merchants"
      hide-default-footer
      class="elevation-1"
      :items-per-page="10000"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">Merchant Akan Habis Kontrak</v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:no-data>
        Belum ada data merchant yang akan habis kontrak...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import LaporanTukarMerchantServices from "../../services/LaporanTukarMerchantServices";
import firebase from 'firebase';
require('firebase/auth');

import { mapMutations } from "vuex";

export default {
  name: "MerchantAkanHabisKontrak",

  data: () => ({
    sessionNIK: "",
    token: "",

    dialog: false,

    headers: [
      {
        text: "NIK",
        align: "start",
        value: "nik",
        sortable: false,
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Role", value: "role", sortable: false },
      { text: "Status", value: "textStatus", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    merchants: [],
    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }

      this.$store.commit("showProgress", {
          message: "Mengambil data Merchant"
        });
        await LaporanTukarMerchantServices.getKontrak(this.token)
          .then(() => {
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
