import axios from "axios";
import Properties from "../properties";

const API_URL = "https://barang." + Properties.DOMAIN_URL + "/api/v1";

export default class BarangAPIService {
  constructor() {}
  static getAllDept = (token, limit, page) => {
    return axios.get(
      API_URL + "/departments/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  };
  static getAllActiveDept = (token, limit, page) => {
    return axios.get(
      API_URL + "/departments/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
        params: { status: 'A'}
      }
    );
  };
  static createDept = (token, dept) =>
    axios.post(API_URL + "/departments", dept, {
      headers: { Authorization: "Bearer " + token },
    });
  static editDept = (token, id, dept) =>
    axios.put(API_URL + "/departments/" + id, dept, {
      headers: { Authorization: "Bearer " + token },
    });
  static delete = (token, kode, nik) =>
    axios.delete(API_URL + "/departments/" + kode, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
