import axios from "axios";
import Properties from "../properties";

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/poin-vouchers/rekap-history-vouchers";
const API_URL_PRODUCTION = "https://api.amigogroup.id/poin-vouchers/v2/rekap-history-vouchers";


export default class LaporanTukarServices {
  constructor() {}
  config = this.$session.get("jwt");

  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }

  static getLaporan = (body, token) => {
    return axios.get(
      this.getURL() + "",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      }
    );
  }
}
