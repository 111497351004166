<template>
  <v-container>
    <v-row align="center" justify="center" class="pt-5">
        <span
          align="center"
          justify="center"
          class="display-1 font-weight-black"
          >Amigo Member Administrator</span
        >
    </v-row>
    <v-row align="center" justify="center" class="pt-5">
    <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="300"
        />
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: "Home",
};
</script>
