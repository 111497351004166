<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="9">
        <v-card class="elevation-1">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Form Laporan Penukaran Poin Gift</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col
                  ><v-combobox
                    ref="gift"
                    v-model="selectedGift"
                    :items="listGifts"
                    return-object
                    item-text="judul"
                    label="Judul Gift"
                    :disabled="showTabel"
                  ></v-combobox
                ></v-col>
                <v-col
                  ><v-combobox
                    ref="toko"
                    :rules="[rules.toko]"
                    v-model="statusGift"
                    :items="listStatus"
                    return-object
                    label="Status Gift"
                    :disabled="showTabel"
                  ></v-combobox
                ></v-col>
                <v-col
                  ><v-combobox
                    v-if="getStatusGift"
                    ref="toko"
                    v-model="isCreated"
                    :items="[
                      'Penukaran Poin di Mobile',
                      'Pengambilan Gift di Toko',
                    ]"
                    return-object
                    label="Periode Berdasarkan"
                    :disabled="showTabel"
                  ></v-combobox
                ></v-col>
              </v-row>
              <v-row>
                <v-col
                  ><v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAwal"
                        v-model="periode_awal"
                        label="Periode Awal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAwal]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_awal"
                      @input="menu1 = false"
                    ></v-date-picker> </v-menu
                ></v-col>
                <v-col
                  ><v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAkhir"
                        v-model="periode_akhir"
                        label="Periode Akhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAkhir]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_akhir"
                      @input="menu2 = false"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="10">
                  <v-combobox
                    ref="toko"
                    :rules="[rules.toko]"
                    v-model="selectedDepartments"
                    :items="listDepartment"
                    item-text="department"
                    label="Pilih Toko"
                    multiple
                    return-object
                    :disabled="showTabel"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="selectAll" :disabled="showTabel" color="warn"
                    >Semua</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer />
            <v-btn color="warn" @click="buat">Buat Laporan</v-btn>
            <v-btn color="primary" :disabled="disableTukar" @click="cetak"
              >Cetak PDF</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-show="showTabel">
      <v-col cols="12" md="12">
        <v-list>
          <v-divider></v-divider>
          <!-- <v-list-item> -->
          <div class="text-h5 mt-5">Laporan Penukaran Poin dengan Gift</div>
          <!-- Laporan Penukaran Poin dengan Gift -->
          <!-- </v-list-item> -->
          <v-list-item>Status: {{ statusGift.text }}</v-list-item>
          <v-list-item
            >Periode Laporan Poin Gift: {{ fperiode_awal }} s/d
            {{ fperiode_akhir }}</v-list-item
          >
          <v-list-item>Periode berdasarkan: {{ isCreated }}</v-list-item>
          <v-list-item>Laporan dicetak pada: {{ fnow }}</v-list-item>

          <!-- <v-list-item>Sub-total: {{ subTotals }}</v-list-item> -->
          <v-list-item v-for="item in unit" :key="item">
            <v-list-item-content>
              <v-data-table
                :headers="headers"
                :items="item.items"
                hide-default-footer
                class="elevation-1"
                :items-per-page="1000"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    Unit: {{ item.department }}
                    <v-spacer />
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Belum ada data penukaran Poin Gift...
                </template>
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import LaporanTukarGiftServices from "../../services/LaporanTukarGiftServices";
import BarangAPIServices from "../../services/BarangAPIServices";
import firebase from "firebase";
require("firebase/auth");

import moment from "moment";
import { mapMutations } from "vuex";
import GiftPoinAPIService from "../../services/GiftPoinAPIServices";

export default {
  data: () => ({
    rules: {
      periodeAwal: (value) => !!value || "Masukkan periode awal.",
      periodeAkhir: (value) => !!value || "Masukkan periode akhir.",
      toko: (value) => !!value || "Masukkan toko.",
      gift: (value) => !!value || "Masukkan gift.",
    },

    selectedDepartments: [],
    selectedKode: [],
    listDepartment: [],
    periode_awal: "",
    periode_akhir: "",
    fperiode_awal: "",
    fperiode_akhir: "",

    listStatus: [
      {
        text: "Sudah diambil",
        kode: "s",
      },
      {
        text: "Belum diambil",
        kode: "b",
      },
      {
        text: "Hangus",
        kode: "k",
      },
    ],
    statusGift: "",
    listGifts: [],
    selectedGift: "",
    isCreated: "",

    subTotals: 0,
    now: "",
    fnow: "",
    unit: [],
    menu1: false,
    menu2: false,

    dialog: false,

    showTabel: false,
    disableTukar: true,

    headers: [
      {
        text: "No",
        align: "start",
        value: "index",
        sortable: false,
        title: "No",
        dataKey: "index",
      },
      {
        text: "Penukaran Poin di Mobile",
        align: "start",
        value: "created_at",
        sortable: false,
        title: "Penukaran Poin di Mobile",
        dataKey: "created_at",
      },
      {
        text: "Kode Pelanggan",
        align: "start",
        value: "pelanggan_id_tukar",
        sortable: false,
        title: "Kode Pelanggan",
        dataKey: "pelanggan_id_tukar",
      },
      {
        text: "Nama Pelanggan",
        align: "start",
        value: "pelanggan_nama",
        sortable: false,
        title: "Nama Pelanggan",
        dataKey: "pelanggan_nama",
      },
      {
        text: "Nomor HP",
        align: "start",
        value: "pelanggan_ponsel",
        sortable: false,
        title: "Nomor HP",
        dataKey: "pelanggan_ponsel",
      },
      {
        text: "Email",
        align: "start",
        value: "pelanggan_email",
        sortable: false,
        title: "Email",
        dataKey: "pelanggan_email",
      },
      {
        text: "Nama Gift",
        align: "start",
        value: "gift_nama",
        sortable: false,
        title: "Nama Gift",
        dataKey: "gift_nama",
      },
      {
        text: "Nilai Poin",
        align: "start",
        value: "harga_poin",
        sortable: false,
        title: "Nilai Poin",
        dataKey: "harga_poin",
      },
      {
        text: "Nilai Gift",
        align: "start",
        value: "fnominal",
        sortable: false,
        title: "Nilai Gift",
        dataKey: "fnominal",
      },
      {
        text: "Kode Gift",
        align: "start",
        value: "kode",
        sortable: false,
        title: "Kode Gift",
        dataKey: "kode",
      },
      {
        text: "Pengambilan Gift di Toko",
        align: "start",
        value: "tanggal_diambil",
        sortable: false,
        title: "Pengambilan Gift di Toko",
        dataKey: "tanggal_diambil",
      },
    ],
  }),

  created() {
    this.initialize();
  },

  computed: {
    getStatusGift() {
      if (this.statusGift.text == "Sudah diambil") return true;
      else return false;
    },
    form() {
      return {
        periodeAwal: this.periode_awal,
        periodeAkhir: this.periode_akhir,
        toko: this.selectedDepartments,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    resetBuatLaporan() {},

    reset() {
      this.selectedGift = "";
      this.subTotals = 0;
      this.unit = [];
      this.selectedKode = [];
      this.periode_awal = "";
      this.periode_akhir = "";
      this.statusGift = "";
      this.selectedDepartments = [];
      this.showTabel = false;
      this.disableTukar = true;
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
    },
    selectAll() {
      this.selectedDepartments = [];
      this.selectedDepartments = this.listDepartment;
    },
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });

      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          var temp = response.data.data;
          temp.forEach((department) => {
            if (department.id_department != "00") {
              this.listDepartment.push(department);
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.$store.commit("showProgress", {
        message: "Mengambil data gift",
      });

      await GiftPoinAPIService.filterOnPeriode(this.token)
        .then((response) => {
          this.listGifts = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "List Gift gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async buat() {
      this.now = new Date().toLocaleString();
      this.fnow = moment(this.now).locale("id").format("DD MMMM yyyy");
      this.fperiode_awal = moment(this.periode_awal)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_akhir = moment(this.periode_akhir)
        .locale("id")
        .format("DD MMMM yyyy");
      this.selectedKode = [];
      this.unit = [];

      // if (this.validate) {
      this.$store.commit("showProgress", {
        message: "Membuat laporan data penukaran Poin Gift",
      });
      var body = {};
      this.selectedDepartments.forEach((selected) => {
        this.selectedKode.push(selected.id_department);
      });

      body.toko = this.selectedKode;

      body.periode_awal = this.periode_awal;
      body.periode_akhir = this.periode_akhir;

      if (this.statusGift.text == "Sudah diambil") {
        if (this.isCreated != "Penukaran Poin di Mobile") body.is_created = 0;
      } else {
        body.is_created = 1;
        this.isCreated = "Penukaran Poin di Mobile";
      }
      if (this.statusGift.kode != "") body.status = this.statusGift.kode;
      body.gift_id = this.selectedGift.id;

      await LaporanTukarGiftServices.getLaporan(body, this.token)
        .then((response) => {
          this.unit = [];
          response.data.forEach((toko) => {
            var stopper = false;
            this.unit.forEach((isi) => {
              if (isi.id_department == toko.id_department) {
                stopper = true;
              }
              if (stopper) return;
            });
            if (!stopper) {
              var tempToko = {};
              tempToko.id_department = toko.id_department;
              tempToko.department = toko.department;

              var tempItems = [];

              var counter = 0;
              var countNama = 0;
              var countPoin = 0;
              var countNominal = 0;
              var tempPelanggan = [];

              toko.items.forEach((item) => {
                counter++;

                var pelStopper = false;
                tempPelanggan.find((p) => {
                  if (p == item.pelanggan_tukar.alias) {
                    pelStopper = true;
                  }
                });
                if (!pelStopper) {
                  countNama++;
                  tempPelanggan.push(item.pelanggan_tukar.alias);
                }
                countPoin += item.harga_poin;
                countNominal += item.nominal;

                item.index = counter;
                item.pelanggan_nama = item.pelanggan_tukar.nama;
                item.pelanggan_email = item.pelanggan_tukar.email;
                item.pelanggan_ponsel = item.pelanggan_tukar.ponsel;
                item.gift_nama = item.gift.judul;

                let val1 = (item.nominal / 1).toFixed(2).replace(".", ",");
                item.fnominal =
                  "Rp. " +
                  val1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                item.created_at = moment(item.created_at)
                  .locale("id")
                  .format("DD MMMM yyyy");
                if (item.tanggal_diambil) {
                  item.tanggal_diambil = moment(item.tanggal_diambil)
                    .locale("id")
                    .format("DD MMMM yyyy");
                }
                tempItems.push(item);
              });
              var rangkum = {};
              rangkum.index = "";
              rangkum.created_at = "Sub Total: ";
              rangkum.pelanggan_nama = countNama;
              rangkum.harga_poin = countPoin;
              rangkum.nominal = countNominal;
              let val3 = (rangkum.nominal / 1).toFixed(2).replace(".", ",");
              rangkum.fnominal =
                "Rp. " + val3.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              rangkum.kode = counter;
              rangkum.tanggal_diambil = "";

              tempItems.push(rangkum);
              tempToko.items = tempItems;
              this.unit.push(tempToko);
            }
          });
          this.showTabel = true;
          this.disableTukar = false;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Gagal membuat laporan",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
      // } else {
      //   this.showSnackbar({
      //     color: "error",
      //     message: "Input tidak valid",
      //   });
      // }
    },

    cetak() {
      var doc = new jsPDF("l", "pt", "a4");
      var counter = 0;
      doc.setFontSize(14);
      doc.setFontType("bold");
      doc.text(25, 50, "Laporan Penukaran Poin dengan Gift");
      doc.setFontSize(10);
      doc.setFontType("normal");
      doc.text(25, 65, "Status: " + this.statusGift.text);
      if (this.isCreated == "Pengambilan Gift di Toko") {
        doc.text(
          25,
          80,
          "Periode: " +
            this.fperiode_awal +
            " s/d " +
            this.fperiode_akhir +
            " (Pengambilan Gift di Toko)"
        );
      } else if (this.isCreated == "Penukaran Poin di Mobile") {
        doc.text(
          25,
          80,
          "Periode: " +
            this.fperiode_awal +
            " s/d " +
            this.fperiode_akhir +
            " (Penukaran Poin di Mobile)"
        );
      }

      doc.setFontSize(12);
      this.unit.forEach((toko) => {
        doc.setFontType("bold");
        doc.text("\n" + toko.department, 40, 90);
        doc.setFontType("normal");

        doc.autoTable(this.headers, toko.items, { margin: { top: 110 } });
        counter++;
        if (counter != this.unit.length) {
          doc.addPage();
        }
      });
      var pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica");
      doc.setFontType("bold");
      doc.setFontSize(9);
      doc.setTextColor(125, 125, 125);

      for (var i = 0; i < pageCount; i++) {
        if (this.isCreated == "Pengambilan Gift di Toko") {
          doc.text(
            25,
            25,
            "Periode: " +
              this.fperiode_awal +
              " s/d " +
              this.fperiode_akhir +
              " (Pengambilan Gift di Toko)"
          );
        } else if (this.isCreated == "Penukaran Poin di Mobile") {
          doc.text(
            25,
            25,
            "Periode: " +
              this.fperiode_awal +
              " s/d " +
              this.fperiode_akhir +
              " (Penukaran Poin di Mobile)"
          );
        }

        doc.setPage(i);
        doc.text(
          25,
          575,
          "Halaman: " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " dari " +
            pageCount
        );
        doc.text(680, 575, "Dicetak pada: " + this.fnow);
      }
      doc.save("Laporan_Poin_Gift_" + this.now + ".pdf");
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
