<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="tokos"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">Setup Toko</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-row>
                    <v-text-field
                      :disabled="formTitle == 'Edit Toko'"
                      ref="kode"
                      v-model="editedItem.id_department"
                      label="Kode Toko"
                      :rules="[rules.id_department]"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      ref="nama"
                      v-model="editedItem.department"
                      label="Nama Toko"
                      counter="50"
                      :rules="[rules.department, rules.counter_nama]"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="nomorTelpon"
                        v-model="editedItem.no_telepon"
                        label="Nomor Telpon"
                        counter="16"
                        :rules="[
                          rules.nomorTelponAngka,
                          rules.nomorTelpon,
                          rules.counter_telp,
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="nomorHP"
                        v-model="editedItem.no_ponsel"
                        label="Nomor HP"
                        counter="16"
                        :rules="[
                          rules.nomorHPAngka,
                          rules.nomorHP,
                          rules.counter_nomor,
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    ref="email"
                    v-model="editedItem.email"
                    label="Email"
                    counter="64"
                    :rules="[rules.email, rules.counter_email]"
                  ></v-text-field>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-combobox
                        ref="kodepos"
                        v-model="inputKodePos"
                        :items="listKodePos"
                        label="Pencarian kodepos"
                        placeholder="Kelurahan/ Kecamatan/ Kabupaten/ Provinsi"
                        @input="selectKodePos"
                        @keyup.enter="searchKodePos"
                        :rules="[rules.kodepos]"
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        disabled="true"
                        v-model="kodepos"
                        label="Kodepos"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-textarea
                      ref="alamat"
                      v-model="editedItem.alamat"
                      label="Keterangan Alamat"
                      placeholder="Nomor, Nama jalan, RT/RW, Dukuh, Desa"
                      auto-grow
                      rows="1"
                      counter="200"
                      :rules="[rules.alamat, rules.counter_alamat]"
                    ></v-textarea>
                  </v-row>
                  <!-- <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.latitude"
                        label="Latitude"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.longitude"
                        label="Longitude"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <h2>Lokasi Toko</h2>
                  </v-row>
                  <v-row class="pa-3">
                    <label>
                      <gmap-autocomplete
                        style="width: 300px; border-bottom-style: solid; border-color: black; border-width: 1px; margin-right: 10px; font-size: 16px; color: black;"
                        placeholder="Masukan pencarian lokasi"
                        :value="selectedPlace"
                        @place_changed="setPlace"
                      />
                      <v-btn small @click="addMarker" color="primary">
                        Tandai Lokasi
                      </v-btn>
                    </label>
                  </v-row>
                  <v-row>
                    <gmap-map
                      :center="center"
                      :zoom="12"
                      style="width:100%;  height: 400px;"
                    >
                      <gmap-marker
                        :key="index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        @click="center = m.position"
                      ></gmap-marker>
                    </gmap-map>
                  </v-row>
                  <v-switch
                    v-model="editedItem.statusBool"
                    inset
                    :label="watchStatus"
                  ></v-switch>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="editItem(item)"
          width="80"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <!-- <v-btn small @click="hapusItem(item)" width="80" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn> -->
      </template>
      <template v-slot:no-data>
        Belum ada data toko...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import firebase from 'firebase';
require('firebase/auth');

import KodePosAPIService from "../services/KodePosAPIServices";
import BarangAPIService from "../services/BarangAPIServices";
import { mapMutations } from "vuex";

export default {
  name: "Setup Toko",

  data: () => ({
    rules: {
      id_department: (value) =>
        value.length == 2 || "Masukkan 2 digit kode toko.",
      department: (value) => !!value || "Masukkan nama department.",
      alamat: (value) => !!value || "Masukkan alamat.",
      nomorHP: (value) => value.length >= 6 || "Masukkan nomor HP dengan benar",
      kodepos: (value) => !!value || "Lakukan pencarian kodepos.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Masukkan email dengan benar.";
      },
      nomorHPAngka: (value) => {
        const pattern = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{9,14}$)/;
        return pattern.test(value) || "Nomor HP hanya mengandung angka";
      },
      nomorTelpon: (value) =>
        value.length >= 6 || "Masukkan nomor telpon dengan benar",

      nomorTelponAngka: (value) => {
        const pattern = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{9,14}$)/;
        return pattern.test(value) || "Nomor telpon hanya mengandung angka";
      },
      counter_nama: (value) =>
        value.length <= 50 || "Nama Toko terlalu panjang.",
      counter_alamat: (value) =>
        value.length <= 200 || "Alamat terlalu panjang.",
      counter_nomor: (value) =>
        value.length <= 16 || "Nomor hp terlalu panjang.",
      counter_telp: (value) =>
        value.length <= 16 || "Nomor telpon terlalu panjang.",
      counter_email: (value) => value.length <= 64 || "Email terlalu panjang.",
    },

    inputKodePos: "",
    listKodePos: [],
    kodepos: "",
    token: "",
    sessionNIK: "",

    dialog: false,

    center: { lat: 7.74, lng: 110.6646 },
    markers: [],
    places: [],
    currentPlace: null,
    selectedPlace: "",

    headers: [
      {
        text: "Kode Toko",
        align: "start",
        value: "id_department",
        sortable: false,
      },
      { text: "Nama Toko", value: "department", sortable: false },
      { text: "Alamat Toko", value: "alamat", sortable: false },
      { text: "Nomor Telpon", value: "no_telepon", sortable: false },
      { text: "Nomor HP", value: "no_ponsel", sortable: false },
      { text: "Status", value: "textStatus", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    tokos: [],

    editedIndex: -1,
    editedItem: {
      id_department: "",
      id_kodepos: "",
      department: "",
      no_telepon: "",
      no_ponsel: "",
      alamat: "",
      email: "",
      status: "",
      statusBool: false,
      textStatus: "",
      latitude: null,
      longitude: null,
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      id_department: "",
      id_kodepos: "",
      department: "",
      no_telepon: "",
      no_ponsel: "",
      alamat: "",
      email: "",
      status: "",
      statusBool: false,
      textStatus: "",
      latitude: null,
      longitude: null,
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  mounted() {
    this.geolocate();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Toko" : "Edit Toko";
    },
    watchStatus() {
      return this.editedItem.statusBool === true ? "Aktif" : "Tidak Aktif";
    },
    form() {
      return {
        kode: this.editedItem.id_department,
        nama: this.editedItem.department,
        nomorHP: this.editedItem.no_ponsel,
        alamat: this.editedItem.alamat,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
      this.praSearch();
    },

    selectKodePos() {
      this.kodepos = this.inputKodePos.kodepos;
    },

    searchKodePos() {
      this.listKodePos = [];
      this.$store.commit("showProgress", {
        message: "Mencari kodepos " + this.inputKodePos,
      });
      KodePosAPIService.search(this.token, this.inputKodePos)
        .then((response) => {
          response.data.data.forEach((kodepos) => {
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kodepos gagal dicari",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });
      BarangAPIService.getAllDept(this.token, this.itemsPerPage, this.page)
        .then((response) => {
          this.tokos = response.data.data;
          this.tokos.forEach((toko) => {
            if (toko.status == "A") {
              toko.statusBool = true;
              toko.textStatus = "Aktif";
            } else {
              toko.statusBool = false;
              toko.textStatus = "Tidak aktif";
            }
          });
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal dimuat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async editItem(item) {
      this.editedIndex = this.tokos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (
        this.editedItem.id_kodepos != null ||
        this.editedItem.id_kodepos != "" ||
        this.editedItem.kodepos != undefined
      ) {
        this.$store.commit("showProgress", {
          message: "Proses mengambil data kodepos",
        });
        await KodePosAPIService.getByID(this.token, this.editedItem.id_kodepos)
          .then((response) => {
            var kodepos = response.data;
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
            this.kodepos = kodepos.kodepos;
            this.inputKodePos = kodepos.text;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kodepos gagal dimuat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
      this.geolocate();
      this.dialog = true;
    },

    hapusItem(item) {
      confirm("Hapus Toko " + item.department + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus toko",
        }),
        BarangAPIService.delete(this.token, item.id_department, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Toko berhasil di hapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Toko gagal di hapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
      this.markers = [];
      this.places = [];
      this.selectedPlace = "";
      this.kodepos = "";
      this.listKodePos = [];
      this.inputKodePos = "";
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      this.listKodePos.forEach((kodepos) => {
        if (this.inputKodePos.text == kodepos.text) {
          this.editedItem.id_kodepos = kodepos.id;
        }
      });
      if (this.validate) {
        if (this.editedItem.statusBool == true) this.editedItem.status = "A";
        else this.editedItem.status = "T";

        if (this.formTitle == "Tambah Toko") {
          this.$store.commit("showProgress", {
            message: "Proses menambah toko",
          });
          this.editedItem.user_create = this.sessionNIK;
          BarangAPIService.createDept(this.token, this.editedItem)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  if (error.response.data.message.includes("PRIMARY")) {
                    this.showSnackbar({
                      color: "error",
                      message: "Kode kategori sudah dipakai",
                    });
                  } else {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kategori promo gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        } else {
          this.editedItem.user_update = this.sessionNIK;
          this.$store.commit("showProgress", {
            message: "Proses mengedit toko",
          });
          BarangAPIService.editDept(
            this.token,
            this.editedItem.id_department,
            this.editedItem
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kategori promo gagal di muat",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        this.markers = [];
        this.places = [];
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;

        this.editedItem.latitude = this.currentPlace.geometry.location.lat();
        this.editedItem.longitude = this.currentPlace.geometry.location.lng();
        this.currentPlace = null;
      }
    },
    geolocate: function() {
      if (
        (this.editedItem.latitude == "" && this.editedItem.longitude == "") ||
        (this.editedItem.latitude == undefined &&
          this.editedItem.longitude == undefined) ||
        (this.editedItem.latitude == null && this.editedItem.longitude == null)
      )
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      else {
        const marker = {
          lat: this.editedItem.latitude,
          lng: this.editedItem.longitude,
        };
        this.markers.push({ position: marker });
        this.center = marker;
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
