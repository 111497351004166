import Vue from "vue";
import Vuex from "vuex";
import firebase from 'firebase';
require('firebase/auth');

import router from "./router";

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    snackbar: false,
    snackbarMessage: "",
    snackbarColor: "",
    progress: false,
    progressText: "",
    initialize: "",

    merchant: "",
    idxMerchant: -1,

    memberBon: "",
    idxMemberBon: -1,

    trMasuk: "",
    trKeluar: "",
  },
  mutations: {
    showSnackbar(state, payload) {
      state.snackbarMessage = payload.message;
      state.snackbarColor = payload.color;
      state.snackbar = true;
    },
    closeSnackbar(state) {
      state.snackbarMessage = "";
      state.snackbarColor = "";
      state.snackbar = false;
    },
    showProgress(state, payload) {
      state.progress = true;
      state.progressText = payload.message;
    },
    closeProgress(state) {
      state.progress = false;
      state.progressText = "";
    },
    storeMerchant(state, payload) {
      state.merchant = payload.merchant;
      state.idxMerchant = payload.idxMerchant;
    },
    removeMerchant(state) {
      state.merchant = "";
      state.idxMerchant = -1;
    },
    storeMemberBon(state, payload) {
      state.memberBon = payload.memberBon;
      state.idxMemberBon = payload.idxMemberBon;
    },
    removeMemberBon(state) {
      state.memberBon = "";
      state.idxMemberBon = -1;
    },
    async logout() {
      await firebase
        .auth()
        .signOut()
        .then(() => {})
        .catch()
        .finally(() => {
          router.replace({ path: "/login" });
          Vue.prototype.$session.destroy();
        });
    },
  },
  actions: {},
  modules: {},
});

export default store