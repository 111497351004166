import axios from "axios";
import Properties from "../properties";

const API_URL =
  "https://backend." + Properties.DOMAIN_URL + "/merchant-api/api/merchants";


export default class MerchantAPIService {
  constructor() { }
  config = this.$session.get("jwt");

  static get = (token, limit, page) => {
    return axios.get(API_URL + "/limit/" + limit + "?page=" + page, {
      Authorization: "Bearer " + token,
    });
  };

  static edit = (token, id, merchant) =>
    axios.post(API_URL + "/" + id, merchant, {
      Authorization: "Bearer " + token,
    });

  static create = (token, merchant) =>
    axios.post(API_URL + "/", merchant, {
      Authorization: "Bearer " + token,
    });

  static delete = (token, kode, nik) =>
    axios.delete(API_URL + "/" + kode, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
