import axios from "axios";
import Properties from "../properties";

const API_URL =
  "https://backend." + Properties.DOMAIN_URL + "/merchant-api/api/kontrak-merchants";

export default class KontrakMerchantAPIService {
  constructor() { }
  config = this.$session.get("jwt");

  static current = (token, body) => {
    return axios.get(API_URL + "/latest", {
      params: body,
      Authorization: "Bearer " + token,
    });
  };

  static getList = (token, body) => {
    return axios.get(API_URL + "/get-list", {
      params: body,
      Authorization: "Bearer " + token,
    });
  };

  static edit = (token, id, kontrak) =>
    axios.post(API_URL + "/" + id, kontrak, {
      Authorization: "Bearer " + token,
    });

  static create = (token, kontrak) =>
    axios.post(API_URL + "/", kontrak, {
      Authorization: "Bearer " + token,
    });

  static delete = (token, kode, nik) =>
    axios.delete(API_URL + "/" + kode, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
