<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="notifikasis"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">Notifikasi</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline"
                  >{{ formTitle }}
                  <span class="headline" v-if="isKhusus">{{
                    tambahanJudul
                  }}</span></span
                >
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-combobox
                    ref="promo"
                    v-model="promo"
                    :items="listP"
                    label="Pilih Promo"
                    :rules="[rules.promo]"
                    v-show="!isKhusus"
                  ></v-combobox>
                  <v-text-field
                    ref="judul"
                    v-model="editedItem.judul"
                    label="Judul Notifikasi"
                    counter="200"
                    :rules="[rules.judul, rules.counter_judul]"
                  ></v-text-field>
                  <v-textarea
                    ref="keterangan"
                    v-model="editedItem.keterangan"
                    label="Isi Notifikasi"
                    auto-grow
                    rows="1"
                    counter="200"
                    :rules="[rules.keterangan, rules.counter_keterangan]"
                  ></v-textarea>
                  <v-file-input
                    ref="foto"
                    :rules="fotoValidator"
                    @change="onAddFiles"
                    accept="image/png, image/jpeg, image/bmp, image/jpg"
                    label="Foto (Landscape 16:9)"
                    prepend-icon="mdi-camera"
                  ></v-file-input>
                  <v-img :key="keyFoto" :src="fotoURL" v-if="showFoto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center">
            <v-col cols="12" md="10">
              <v-combobox
                v-model="filterPromo"
                :items="listP"
                label="Judul Promo"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-spacer />
          <v-row justify="end">
            <v-col cols="12" md="5">
              <v-btn color="primary" @click="getNotifUltah"
                ><v-icon>mdi-pencil</v-icon> Ulang Tahun</v-btn
              >
            </v-col>
            <v-col cols="12" md="5">
              <v-btn color="primary" @click="getNotifWelcome"
                ><v-icon>mdi-pencil</v-icon> Welcome</v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="kirimNotif(item)"
          width="70"
          color="blue"
          class="mr-1"
        >
          <v-icon small>mdi-send</v-icon> Kirim
        </v-btn>
        <v-btn
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn small @click="hapusItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn>
      </template>
      <template v-slot:no-data>
        Silahkan melakukan pencarian notifikasi...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import PromoAPIServices from "../services/PromoAPIServices";
import NotifikasiAPIServices from "../services/NotifikasiAPIServices";
import firebase from 'firebase';
require('firebase/auth');

import { mapMutations } from "vuex";

export default {
  name: "Notifikasi",

  data: () => ({
    rules: {
      promo: (value) => !!value || "Pilih promo.",
      judul: (value) => !!value || "Masukkan judul.",
      keterangan: (value) => !!value || "Masukkan isi notifikasi.",
      counter_judul: (value) => value.length <= 200 || "Judul terlalu panjang.",
      counter_keterangan: (value) =>
        value.length <= 200 || "Keterangan terlalu panjang.",
    },

    sessionNIK: "",
    token: "",
    listP: [],
    filterPromo: "",

    isKhusus: false,
    tambahanJudul: "",

    menu1: false,
    menu2: false,

    searchText: "",
    dialog: false,
    fotoURL: "",
    showFoto: false,
    tempFoto: null,
    keyFoto: 0,

    headers: [
      {
        text: "Judul Promo",
        align: "start",
        value: "judul_promo",
        sortable: false,
      },
      { text: "Judul Notifikasi", value: "judul", sortable: false },
      { text: "Isi Notifikasi", value: "keterangan", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    promos: [],
    promo: "",
    notifikasis: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      kode_promo: "",
      judul: "",
      keterangan: "",
      foto: "",
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      id: "",
      kode_promo: "",
      judul: "",
      keterangan: "",
      foto: "",
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    fotoValidator() {
      return [
        (this.tempFoto != null && this.tempFoto.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Notifikasi" : "Edit Notifikasi";
    },
    formReset() {
      return {
        promo: this.editedItem.kode_promo,
        judul: this.editedItem.judul,
        keterangan: this.editedItem.keterangan,
        foto: this.tempFoto,
      };
    },

    formTambah() {
      return {
        promo: this.editedItem.kode_promo,
        judul: this.editedItem.judul,
        keterangan: this.editedItem.keterangan,
      };
    },

    formEdit() {
      return {
        promo: this.editedItem.kode_promo,
        judul: this.editedItem.judul,
        keterangan: this.editedItem.keterangan,
      };
    },

    formKhusus() {
      return {
        judul: this.editedItem.judul,
        keterangan: this.editedItem.keterangan,
      };
    },

    validate() {
      var formHasErrors = false;
      if (this.isKhusus) {
        Object.keys(this.formKhusus).forEach((f) => {
          if (!this.formKhusus[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });
      } else {
        if (this.formTitle == "Tambah Notifikasi")
          Object.keys(this.formTambah).forEach((f) => {
            if (!this.formTambah[f]) formHasErrors = true;

            this.$refs[f].validate(true);
          });
        else {
          Object.keys(this.formEdit).forEach((f) => {
            if (!this.formEdit[f]) formHasErrors = true;

            this.$refs[f].validate(true);
          });
        }
      }

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data promo yang aktif",
      });
      PromoAPIServices.getActivePromoKhusus(this.token, 100, this.page)
        .then((response) => {
          this.promos = response.data.data;
          this.promos.forEach((item) => {
            this.listP.push(item.judul);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Promo gagal dimuat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async getNotifUltah() {
      this.isKhusus = true;
      this.editedIndex = 0;
      this.tambahanJudul = "Ulang Tahun";
      this.$store.commit("showProgress", {
        message: "Mengambil data notifikasi ulang tahun",
      });
      await NotifikasiAPIServices.getConst(this.token, "UT")
        .then((response) => {
          this.editedItem = Object.assign({}, response.data);
          this.fotoURL = this.editedItem.foto;
          this.showFoto = true;
          this.dialog = true;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Notifikasi gagal dimuat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async getNotifWelcome() {
      this.isKhusus = true;
      this.editedIndex = 0;
      this.tambahanJudul = "Welcome Member";
      this.$store.commit("showProgress", {
        message: "Mengambil data notifikasi welcome member",
      });
      await NotifikasiAPIServices.getConst(this.token, "WC")
        .then((response) => {
          this.editedItem = Object.assign({}, response.data);
          this.fotoURL = this.editedItem.foto;
          this.showFoto = true;
          this.dialog = true;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Notifikasi gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    onAddFiles(e) {
      this.tempFoto = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.fotoURL = fr.result;
          this.showFoto = true;
        });
      } else {
        if (this.editedItem.foto != "") {
          this.fotoURL = this.editedItem.foto;
          this.showFoto = true;
        } else {
          this.fotoURL = "";
          this.showFoto = false;
          this.tempFoto = null;
        }
      }
    },

    resetForm() {
      this.promo = "";
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.notifikasis = [];
      var k_promo = "";
      this.promos.forEach((promo) => {
        if (this.filterPromo == promo.judul) {
          k_promo = promo.kode;
        }
      });
      if (k_promo != "") {
        this.$store.commit("showProgress", {
          message: "Mengambil data notifikasi promo " + this.filterPromo,
        });
        NotifikasiAPIServices.getNotifikasi(
          this.token,
          this.itemsPerPage,
          this.page,
          k_promo
        )
          .then((response) => {
            this.page = response.data.current_page;
            this.pageCount = response.data.last_page;
            response.data.data.forEach((notifikasi) => {
              this.promos.forEach((promo) => {
                if (promo.kode == notifikasi.kode_promo) {
                  notifikasi.judul_promo = promo.judul;
                }
              });

              if (notifikasi.aktif) {
                notifikasi.otomatis = "Ya";
                notifikasi.waktu = notifikasi.tanggal;
              } else {
                notifikasi.otomatis = "Tidak";
              }

              this.notifikasis.push(notifikasi);
            });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Notifikasi gagal di muat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.notifikasis.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.fotoURL = item.foto;
      this.showFoto = true;

      this.promos.forEach((promo) => {
        if (promo.kode == this.editedItem.kode_promo) {
          this.promo = promo.judul;
        }
      });
      this.dialog = true;
    },

    hapusItem(item) {
      confirm("Hapus Notifikasi " + item.judul + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus notifikasi",
        }),
        NotifikasiAPIServices.deleteNotifikasi(
          this.token,
          item.id,
          this.sessionNIK
        )
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Notifikasi berhasil di hapus",
            });
            this.search();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Notifikasi gagal di hapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    kirimNotif(item) {
      this.$store.commit("showProgress", {
        message: "Proses mengirim notifikasi",
      });
      NotifikasiAPIServices.sendNotifikasi(this.token, item.id, this.sessionNIK)
        .then(() => {
          this.dialog = false;
          this.showSnackbar({
            color: "secondary",
            message: "Notifikasi berhasil di kirim",
          });
          this.search();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Notifikasi gagal di kirim",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    close() {
      this.dialog = false;
      this.resetForm();
      this.isKhusus = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.keyFoto++;
        this.keyThumbnail++;
        this.showFoto = false;
        this.showThumbnail = false;

        this.onAddFiles(null);

        Object.keys(this.formReset).forEach((f) => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    save() {
      if (this.isKhusus) {
        let formData = new FormData();
        formData.append("judul", this.editedItem.judul);
        formData.append("keterangan", this.editedItem.keterangan);
        if (this.tempFoto != null) formData.append("foto", this.tempFoto);
        formData.append("user_update", this.sessionNIK);
        if (this.tambahanJudul == "Ulang Tahun") {
          if (this.validate) {
            this.$store.commit("showProgress", {
              message: "Proses mengedit notifikasi",
            });

            NotifikasiAPIServices.editConstNotif(
              this.token,
              this.editedItem.kode_kategori_promo,
              formData
            )
              .then(() => {
                this.close();
                this.showSnackbar({
                  color: "secondary",
                  message: "Data notifikasi ulang tahun berhasil di edit",
                });
                this.search();
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message) {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                } else if (error.request) {
                  this.showSnackbar({
                    color: "error",
                    message: "Koneksi ke server gagal (ERROR)",
                  });
                } else {
                  this.showSnackbar({
                    color: "error",
                    message: "Notifikasi ulang tahun gagal di edit",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("closeProgress");
              });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Input tidak valid",
            });
          }
        } else if (this.tambahanJudul == "Welcome Member") {
          if (this.validate) {
            this.$store.commit("showProgress", {
              message: "Proses mengedit notifikasi",
            });

            NotifikasiAPIServices.editConstNotif(
              this.token,
              this.editedItem.kode_kategori_promo,
              formData
            )
              .then(() => {
                this.close();
                this.showSnackbar({
                  color: "secondary",
                  message: "Data notifikasi welcome member berhasil di edit",
                });
                this.search();
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message) {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                } else if (error.request) {
                  this.showSnackbar({
                    color: "error",
                    message: "Koneksi ke server gagal (ERROR)",
                  });
                } else {
                  this.showSnackbar({
                    color: "error",
                    message: "Notifikasi welcome member gagal diedit",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("closeProgress");
              });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Input tidak valid",
            });
          }
        }
      } else {
        this.promos.forEach((promo) => {
          if (this.promo == promo.judul) {
            this.editedItem.kode_promo = promo.kode;
          }
        });
        if (this.validate) {
          let formData = new FormData();
          formData.append("id", this.editedItem.id);
          formData.append("kode_promo", this.editedItem.kode_promo);
          formData.append("judul", this.editedItem.judul);
          formData.append("keterangan", this.editedItem.keterangan);
          if (this.tempFoto != null) formData.append("foto", this.tempFoto);

          formData.append("user_create", this.sessionNIK);
          formData.append("user_update", this.sessionNIK);

          if (this.formTitle == "Tambah Notifikasi") {
            this.$store.commit("showProgress", {
              message: "Proses menambah notifikasi",
            });

            NotifikasiAPIServices.createNotifikasi(this.token, formData)
              .then(() => {
                this.close();
                this.showSnackbar({
                  color: "secondary",
                  message: "Data berhasil di tambah",
                });
                this.search();
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message) {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                } else if (error.request) {
                  this.showSnackbar({
                    color: "error",
                    message: "Koneksi ke server gagal (ERROR)",
                  });
                } else {
                  this.showSnackbar({
                    color: "error",
                    message: "Notifikasi gagal di tambah",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("closeProgress");
              });
          } else {
            this.$store.commit("showProgress", {
              message: "Proses mengedit notifikasi",
            });

            NotifikasiAPIServices.editNotifikasi(
              this.token,
              this.editedItem.id,
              formData
            )
              .then(() => {
                this.close();
                this.showSnackbar({
                  color: "secondary",
                  message: "Data berhasil di edit",
                });
                this.search();
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message) {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                } else if (error.request) {
                  this.showSnackbar({
                    color: "error",
                    message: "Koneksi ke server gagal (ERROR)",
                  });
                } else {
                  this.showSnackbar({
                    color: "error",
                    message: "Notifikasi gagal di muat",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("closeProgress");
              });
          }
        } else {
          this.showSnackbar({
            color: "error",
            message: "Input tidak valid",
          });
        }
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
