import axios from "axios";
import Properties from "../properties"

const API_URL = "https://pelanggan." + Properties.DOMAIN_URL + "/api/v1";
// const API_URL = "http://localhost:8000/api";

export default class PromoAPIService {
    constructor() {}
    static getAllPromo = (token, limit, page, body) =>
        axios.get(API_URL + "/promos/limit/" + limit + "?page=" + page, {
            params: body,
            headers: { Authorization: "Bearer " + token },
        });
    static getListPromo = (token, body) =>
        axios.get(API_URL + "/promos/list", {
            params: body,
            headers: { Authorization: "Bearer " + token },
        });
    static getActivePromo = (token, limit, page) => {
        return axios.get(API_URL + "/promos/limit/" + limit + "?page=" + page, {
            params: {
                onPeriode: "true",
                status: "A",
            },
            headers: { Authorization: "Bearer " + token },
        });
    };
    static getActivePromoKhusus = (token, limit, page) => {
        return axios.get(API_URL + "/promos/limit/" + limit + "?page=" + page, {
            params: {
                onPeriode: "true",
                khusus: "0",
                status: "A",
            },
            headers: { Authorization: "Bearer " + token },
        });
    };
    static editPromo = (token, kode, promo) =>
        axios.post(API_URL + "/promos/" + kode, promo, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
            },
        });
    static createPromo = (token, promo) =>
        axios.post(API_URL + "/promos", promo, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
            },
        });
    static deletePromo = (token, nik, kode) =>
        axios.delete(API_URL + "/promos/" + kode, {
            params: {
                user_update: nik,
            },
            headers: { Authorization: "Bearer " + token },
        });

    static tukarPromo = (token, body) =>
        axios.post(API_URL + "/tukar-promos", body, {
            headers: { Authorization: "Bearer " + token },
        });

    static getTukarPromo = (token, kodePromo, kodeCust, limit) =>
        axios.get(
            API_URL +
            "/tukar-promos/" +
            kodePromo +
            "/kode-member/" +
            kodeCust +
            "/limit/" +
            limit, {
                headers: { Authorization: "Bearer " + token },
            }
        );

    static getTukarPromoVoucher = (token, voucher, limit, body) => {
        return axios.get(
            API_URL +
            "/tukar-promos" +
            "/kode-voucher/" +
            voucher +
            "/limit/" +
            limit, {
                params: body,
                headers: { Authorization: "Bearer " + token },
            }
        );
    }

    static getPromo = (token, kode) =>
        axios.get(API_URL + "/promos/" + kode, {
            headers: { Authorization: "Bearer " + token },
        });
}
