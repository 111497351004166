import axios from "axios";
import Properties from "../properties";

const API_URL = "https://pelanggan." + Properties.DOMAIN_URL + "/api/v1";

export default class PelangganAPIService {
  constructor() {}
  static search = (token, input, limit, kolomUrut, desc, page) =>
    axios.get(
      API_URL +
        "/pelanggans/punya-alias/2/searching-pelanggan/" +
        input +
        "/kolom-urut/" +
        kolomUrut +
        "/desc/" +
        desc +
        "/limit/" +
        limit +
        "?page=" +
        page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  static search2 = (token, input, limit, kolomUrut, desc, page) =>
    axios.get(
      API_URL +
        "/pencarian/pelanggan",
      {
        params: {
          punyaAlias: 2,
          input: input,
          kolomUrut: kolomUrut,
          desc: desc,
          limit: limit,
          page: page
        },
        headers: { Authorization: "Bearer " + token },
      }
    );
  static searchUltah = (token, bulan, hari, limit, page) =>
    axios.get(
      API_URL +
        "/pelanggans/bulan/" +
        bulan +
        "/hari/" +
        hari +
        "/limit/" +
        limit +
        "?page=" +
        page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  static create = (token, kdToko, profil) =>
    axios.post(API_URL + "/pelanggans/toko/" + kdToko, profil, {
      headers: { Authorization: "Bearer " + token },
    });
  static editProfile = (token, alias, profil) =>
    axios.put(API_URL + "/pelanggans/alias/" + alias, profil, {
      headers: { Authorization: "Bearer " + token },
    });
  static editProfileKdCust = (token, kd_cust, profil) =>
    axios.put(API_URL + "/pelanggans/" + kd_cust, profil, {
      headers: { Authorization: "Bearer " + token },
    });
  static getAgamas = (token) =>
    axios.get(API_URL + "/agamas", {
      headers: { Authorization: "Bearer " + token },
    });
  static getPekerjaans = (token) =>
    axios.get(API_URL + "/pekerjaans", {
      headers: { Authorization: "Bearer " + token },
    });
  static resetUID = (token, alias, nik) =>
    axios.post(
      API_URL + "/pelanggans/reset-uid/alias/" + alias,
      { user_update: nik },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  static getPelangganByAlias = (token, alias) =>
    axios.get(API_URL + "/pelanggans/kode-member/" + alias, {
      headers: { Authorization: "Bearer " + token },
    });
  // static getPelangganByKDCust = (token, kode) => {
  //   return axios.get(API_URL + "/pelanggans/" + kode, {
  //     headers: { Authorization: "Bearer " + token },
  //   });
  // }

  static generateAlias = (token) =>
    axios.get(API_URL + "/pelanggans/alias/new/J", {
      headers: { Authorization: "Bearer " + token },
    });
}
