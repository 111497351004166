import axios from "axios";
import Properties from "../properties";

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/poin-gifts/history-gift-poins";
const API_URL_PRODUCTION = "https://api.amigogroup.id/poin-gifts/v2/history-gift-poins";

export default class SetupPoinAPIService {
  constructor() {}
  config = this.$session.get("jwt");

  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }

  static get = (token, body) =>{
    return axios.get(this.getURL() + "/", {
      params: body,
      headers: { Authorization: "Bearer " + token },
    });
  }
  static ambil = (token, body, kode) =>{
    return axios.put(this.getURL() + "/ambil-gift/" +kode, body, {
      headers: { Authorization: "Bearer " + token },
    });
  }
}
