import axios from "axios";
import Properties from "../properties";

// const API_URL = "https://api.amigogroup.id/poin-vouchers/vouchers";
const API_URL =
  "https://backend." + Properties.DOMAIN_URL + "/poin-voucher/api/vouchers";

export default class VoucherPoinAPIService {
  constructor() {}
  config = this.$session.get("jwt");

  static getAll = (token, limit, page) =>
    axios.get(API_URL + "/limit/" + limit + "?page=" + page, {
      Authorization: "Bearer " + token,
    });
  static filter = (token, body, page) =>
    axios.get(API_URL + "/searching/filter-voucher?page=" + page, {
      params: body,
      Authorization: "Bearer " + token,
    });
  static filterOnPeriode = (token) =>
    axios.get(API_URL + "/filter/periode", {
      // params: body,
      Authorization: "Bearer " + token,
    });
  static getByID = (token, id) =>
    axios.get(API_URL + "/" + id, {
      Authorization: "Bearer " + token,
    });

  static edit = (token, id, voucher) =>
    axios.post(API_URL + "/" + id, voucher, {
      Authorization: "Bearer " + token,
    });
  static create = (token, voucher) =>
    axios.post(API_URL + "/", voucher, {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    });

  static delete = (token, id, nik) =>
    axios.delete(API_URL + "/" + id, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
