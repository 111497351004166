import axios from "axios";
import Properties from "../properties";

const API_URL = "https://karyawan." + Properties.DOMAIN_URL + "/api/v1";

export default class AdminAPIService {
  constructor() { }
  config = this.$session.get("jwt");

  static getAll = (token, limit, page) =>
    axios.get(
      API_URL + "/member-admins/" + "limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  static filter = (token, body, page) => {
    return axios.get(
      API_URL + "/member-admins/searching/filter-admin?page=" + page,

      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      }
    );
  }
  static getByNIK = (token, nik) =>
    axios.get(API_URL + "/member-admins/nik/" + nik, {
      headers: { Authorization: "Bearer " + token },
    });
  static getByID = (token, id) =>
    axios.get(API_URL + "/member-admins/by-id/" + id, {
      headers: { Authorization: "Bearer " + token },
    });
  static edit = (token, kode, admin) =>
    axios.put(API_URL + "/member-admins/" + kode, admin, {
      headers: { Authorization: "Bearer " + token },
    });
  static create = (token, notif) =>
    axios.post(API_URL + "/member-admins", notif, {
      headers: { Authorization: "Bearer " + token },
    });
  static delete = (token, kode, nik) =>
    axios.delete(API_URL + "/member-admins/" + kode, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
