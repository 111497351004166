<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="7">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Pengambilan Gift</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col cols="4">
                  <v-combobox
                    ref="toko"
                    v-model="tukarDepartment"
                    :items="listDepartment"
                    item-text="department"
                    return-object
                    label="Toko Pengambilan"
                  ></v-combobox>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="4">
                  <v-text-field
                    ref="kodeGift"
                    v-model="kodeGift"
                    prepend-icon="mdi-barcode-scan"
                    label="Kode Gift"
                    rules=""
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    ref="kodePelanggan"
                    v-model="kodePelanggan"
                    prepend-icon="mdi-account"
                    label="Kode Pelanggan"
                    rules=""
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="ambilGift">Tukar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="listGifts"
          hide-default-footer
          class="elevation-5"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:top>
            <v-container mx-1>
              <v-row align="center" justify="left">
                <v-col cols="3">
                  <v-combobox
                    ref="toko"
                    v-model="pencarianDepartment"
                    item-text="department"
                    :items="listDepartment"
                    return-object
                    label="Toko Pengambilan"
                  ></v-combobox>
                </v-col>
                <v-col cols="3">
                  <v-combobox
                    ref="status"
                    v-model="statusGift"
                    :items="listStatus"
                    return-object
                    label="Status Pengambilan"
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="1">
                  <v-btn color="primary" @click="praSearch"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
            <!-- </v-toolbar> -->
          </template>
          <template v-slot:no-data>
            Belum ada setup poin selanjutnya...
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              @click="deleteItem(item)"
              width="70"
              color="error"
              class="mr-1"
            >
              <v-icon small>mdi-delete</v-icon> Hapus
            </v-btn>
            <!-- <v-btn small @click="hapusItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from 'firebase';
require('firebase/auth');

import BarangAPIServices from "../../services/BarangAPIServices";
import HistoryPoinGiftAPIServices from "../../services/HistoryPoinGiftAPIServices";
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {},

    headers: [
      {
        text: "Nama Gift",
        align: "start",
        value: "gift.judul",
        sortable: false,
      },
      {
        text: "Ditukar Oleh",
        align: "start",
        value: "pelanggan_tukar.nama",
        sortable: false,
      },
      {
        text: "Tanggal Penukaran",
        align: "start",
        value: "created_at",
        sortable: false,
      },
      {
        text: "Diambil Setelah",
        align: "start",
        value: "diambil_setelah",
        sortable: false,
      },
    ],
    listStatus: [
      { text: "Belum diambil", value: 0 },
      { text: "Sudah diambil", value: 1 },
    ],
    listDepartment: [],
    tukarDepartment: "",
    kodeGift: "",
    kodePelanggan: "",
    pencarianDepartment: "",
    listGifts: [],
    currentSetup: {},
    itemsPerPage: 100,
  }),

  created() {
    this.initialize();
  },

  computed: {},

  methods: {
    formatTanggal(value) {
      return moment(String(value)).locale("id").format("DD MMM YYYY");
    },

    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.$store.commit("showProgress", {
        message: "Mengambil data Toko",
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          this.departments = response.data.data;
          this.departments.forEach((department) => {
            if (department.id_department != "00") {
              this.listDepartment.push(department);
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    praSearch() {
      var body = {};
      body.kode_toko = this.pencarianDepartment.id_department;
      body.diambil = this.statusGift.value;
      body.limit = this.itemsPerPage;
      this.$store.commit("showProgress", {
        message: "Mengambil data penukaran Gift",
      });
      HistoryPoinGiftAPIServices.get(this.token, body)
        .then((response) => {
          this.listGifts = [];
          response.data.data.forEach((item) => {
            item.diambil_setelah = this.formatTanggal(item.diambil_setelah);
            item.created_at = this.formatTanggal(item.created_at);
            this.listGifts.push(item);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Setup Poin gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    reset() {
      this.tukarDepartment = "";
      this.kodeGift = "";
      this.kodePelanggan = "";
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
    },

    ambilGift() {
      if (
        this.tukarDepartment == null ||
        this.tukarDepartment == "" || 
        this.kodeGift == "" ||
        this.kodePelanggan == ""
      ){
        this.showSnackbar({
              color: "error",
              message: "Lengkapi Inputan",
            });
      } else {
        var body = {};
        body.pelanggan_id_pakai = this.kodePelanggan;
        body.diambil_di = this.tukarDepartment.id_department;
        body.user_update = this.sessionNIK;
        this.$store.commit("showProgress", {
          message: "Mengambil data penukaran Gift",
        });
        HistoryPoinGiftAPIServices.ambil(this.token, body, this.kodeGift)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Gift berhasil diambil",
            });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gift gagal diambil.",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
