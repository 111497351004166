import axios from "axios";
import Properties from "../properties"

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/api-merchants/v2/rekap-promo-merchant";
const API_URL_PRODUCTION = "https://api.amigogroup.id/api-merchants/v2/rekap-promo-merchant";


export default class LaporanTukarMerchantServices {
  constructor() { }
  config = this.$session.get("jwt");

  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }

  static get = (body, token) => {
    return axios.get(
      this.getURL() + "/",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
  }
  static getMerchant = (body, token) =>
    axios.get(
      this.getURL() + "/get-id-merchant",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
  static getPromo = (body, token) =>
    axios.get(
      this.getURL() + "/get-id-promo",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
  static getRekap = (body, token) =>
    axios.get(
      this.getURL() + "/get-rekap",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
  static getKontrak = (token) =>
    axios.get(
      this.getURL() + "/get-kontrak",
      {
        headers: { Authorization: "Bearer " + token },
      },
    );

}
