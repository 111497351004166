<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="members"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-col cols="2">
            <v-toolbar-title class="mr-2">
              MEMBER BON
            </v-toolbar-title>
          </v-col>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
            small
            @click="tambahMemberBon()"
            ><v-icon class="white--text">mdi-plus</v-icon></v-btn
          >
          <v-divider vertical light class="mx-2"></v-divider>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="detailItem(item)"
          width="70"
          color="info"
          class="mr-1"
        >
          <v-icon small>mdi-sheet</v-icon> Edit
        </v-btn>
      </template>
      <template v-slot:no-data>
        Silahkan melakukan pencarian member...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="cekSearch"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import PelangganAPIService from "../../services/PelangganAPIServices";
import BarangAPIServices from "../../services/BarangAPIServices";
import KodePosAPIService from "../../services/KodePosAPIServices";
import firebase from "firebase";
require("firebase/auth");

import { mapMutations } from "vuex";

export default {
  name: "Member",

  data: () => ({
    sessionNIK: "",
    token: "",
    headers: [
      {
        text: "Kode",
        align: "start",
        value: "alias",
        sortable: false
      },
      { text: "Toko", value: "toko", sortable: false },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat_lengkap", sortable: false },
      { text: "Nomor HP", value: "ponsel", sortable: false },
      { text: "Tanggal Daftar", value: "tgl_entry", sortable: false },
      { text: "Poin", value: "poin", sortable: false },
      { text: "Action", value: "actions", sortable: false }
    ],
    members: [],
    editedIndex: -1,

    page: 1,
    pageCount: 1,
    itemsPerPage: 10
  }),

  watch: {},

  created() {
    this.initialize();
  },

  computed: {},

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(token => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data toko"
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then(response => {
          this.departments = response.data.data;
          this.departments.forEach(department => {
            this.listDepartment.push(department.department);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    tambahMemberBon() {
      this.storeMemberBon({
        merchant: null,
        idxMerchant: -1
      });
      this.$router.push("/member-bon-detail");
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.members = [];
      this.$store.commit("showProgress", {
        message: "Sedang mencari member"
      });
      PelangganAPIService.search(
        this.token,
        this.searchText,
        this.itemsPerPage,
        this.page
      )
        .then(response => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;

          response.data.data.forEach(member => {
            member.alamat_lengkap = member.alamat;
            if (member.id_kodepos !== null) {
              KodePosAPIService.getByID(this.token, member.id_kodepos)
                .then(responseKodepos => {
                  member.alamat_lengkap =
                    member.alamat_lengkap +
                    ", " +
                    responseKodepos.data.kelurahan +
                    ", " +
                    responseKodepos.data.kecamatan +
                    ", " +
                    responseKodepos.data.kabupaten +
                    ", " +
                    responseKodepos.data.provinsi;
                })
                .catch(error => {
                  if (error.response) {
                    if (error.response.data.message) {
                      this.showSnackbar({
                        color: "error",
                        message: error.response.data.message
                      });
                    }
                  } else if (error.request) {
                    this.showSnackbar({
                      color: "error",
                      message: "Koneksi ke server gagal (ERROR)"
                    });
                  } else {
                    this.showSnackbar({
                      color: "error",
                      message:
                        "Alamat lengkap " + member.nama + " gagal di muat"
                    });
                  }
                })
                .finally(() => {
                  this.dialogProgress = false;
                  this.$store.commit("closeProgress");
                });
            }
            this.members.push(member);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Member gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    ...mapMutations(["showSnackbar", "storeMemberBon"])
  }
};
</script>
<style scoped>
.v-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-outer-spin-button,
.v-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
