<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Login form</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text ref="form">
            <v-text-field
              ref="nik"
              v-model="nik"
              label="NIK"
              prepend-icon="mdi-account"
              type="text"
              :rules="[rules.nik]"
            />

            <v-text-field
              v-model="password"
              label="Password"
              prepend-icon="mdi-lock"
              type="password"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="login">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from 'firebase';
require('firebase/auth');

import AdminAPIServices from "../services/AdminAPIServices";
import KaryawanAPIServices from "../services/KaryawanAPIServices";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {
      nik: (value) => !!value || "Masukkan NIK.",
    },

    nik: "",
    password: "",

    dialog: false,

    headers: [
      {
        text: "Kode Kategori",
        align: "start",
        value: "kode",
        sortable: false,
      },
      { text: "Nama Kategori", value: "nama", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    kategoris: [],

    editedIndex: -1,
    editedItem: {
      kode: "",
      nama: "",
    },
    defaultItem: {
      kode: "",
      nama: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initialize();
  },

  computed: {
    form() {
      return {
        nik: this.nik,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    initialize() {},
    async login() {
      if (this.validate) {
        this.$store.commit("showProgress", {
          message: "Login ke Firebase",
        });
        firebase
          .auth()
          .signInWithEmailAndPassword("develop.amigogroup@gmail.com", "p4g14m1g0")
          .then(() => {
            this.$store.commit("showProgress", {
              message: "Cek token firebase",
            });
            firebase
              .auth()
              .currentUser.getIdTokenResult()
              .then((token) => {
                this.$store.commit("showProgress", {
                  message: "Validasi NIK",
                });
                AdminAPIServices.getByNIK(token.token, this.nik)
                  .then((responseAdmin) => {
                    this.$store.commit("showProgress", {
                      message: "Memproses Login",
                    });
                    var body = {};
                    body.pin = this.password;
                    KaryawanAPIServices.login(token.token, this.nik, body)
                      .then(() => {
                        this.$session.start();
                        this.$session.set("nik", this.nik);
                        this.$session.set("role", responseAdmin.data.role);

                        this.$store.commit("closeProgress");
                        this.$router.replace({ path: "/" });
                      })
                      .catch((error) => {
                        firebase
                          .auth()
                          .signOut()
                          .then(() => {
                            this.$store.commit("closeProgress");
                            if (error.response) {
                              if (error.response.data.message) {
                                this.showSnackbar({
                                  color: "error",
                                  message: error.response.data.message,
                                });
                              }
                            } else if (error.request) {
                              this.showSnackbar({
                                color: "error",
                                message: "Koneksi ke server gagal (ERROR)",
                              });
                            } else {
                              this.showSnackbar({
                                color: "error",
                                message: "Login gagal",
                              });
                            }
                          })
                          .catch()
                          .finally(() => {
                             
                            this.$router.replace({ path: "/" });
                          });
                      })
                      .finally(() => {
                         
                        this.$router.replace({ path: "/" });
                      });
                  })
                  .catch((error) => {
                    firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        this.$store.commit("closeProgress");
                        if (error.response) {
                          if (error.response.data.message) {
                            this.showSnackbar({
                              color: "error",
                              message: error.response.data.message,
                            });
                          }
                        } else if (error.request) {
                          this.showSnackbar({
                            color: "error",
                            message: "Koneksi ke server gagal (ERROR)",
                          });
                        } else {
                          this.showSnackbar({
                            color: "error",
                            message: "Gagal memuat NIK",
                          });
                        }
                      })
                      .catch()
                      .finally(() => {
                         
                        this.$router.replace({ path: "/" });
                      });
                  })
                  .finally(() => {});
              })
              .catch(() => {
                this.$store.commit("closeProgress");
              })
              .finally(() => {});
          })
          .catch(() => {
            this.$store.commit("closeProgress");
          })
          .finally(() => {});
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },
    ...mapMutations(["showSnackbar"]),
  },
};
</script>
