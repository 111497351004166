import axios from "axios";
import Properties from "../properties";

const API_URL = "https://karyawan." + Properties.DOMAIN_URL + "/api/v1";

export default class KaryawanAPIService {
  constructor() { }
  static getByNIK = (token, nik) =>
    axios.get(API_URL + "/karyawans/" + nik, {
      headers: { Authorization: "Bearer " + token },
    });
  static getByID = (token, id) =>
    axios.get(API_URL + "/member-admins/by-id/" + id, {
      headers: { Authorization: "Bearer " + token },
    });
  static login = (token, nik, body) =>
    axios.post(API_URL + "/karyawans/" + nik + "/auth/pin", body, {
      headers: { Authorization: "Bearer " + token },
    });

  static gantiPassword = (token, nik, body) =>
    axios.put(API_URL + "/karyawans/" + nik + "/auth/pin", body, {
      headers: { Authorization: "Bearer " + token },
    });

  static getRoleKaryawan = (token) =>
    axios.get(API_URL + "/role", {
      headers: { Authorization: "Bearer " + token },
    });
  static defaultPassword = (token, nik) =>
    axios.put(
      API_URL + "/karyawans/" + nik + "/auth/default",
      {},
      {
        headers: { Authorization: "Bearer " + token },
      }
    );

  static resetPassword = (token, nik) =>
    axios.put(
      API_URL + "/karyawans/" + nik + "/auth/reset",
      {},
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
}
