<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="gifts"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">GIFT POIN SETUP</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card v-show="!formStock">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-text-field
                    ref="judul"
                    v-model="editedItem.judul"
                    label="Judul"
                    :rules="[rules.judul]"
                  ></v-text-field>
                  <p class="mt-5 mb-1 subtitle-1">Keterangan</p>
                  <v-sheet elevation="6">
                    <ckeditor
                      :editor="editor"
                      v-model="editedItem.keterangan"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-sheet>
                  <br />
                  <v-row>
                    <v-col cols="10">
                      <v-combobox
                        ref="toko"
                        :rules="[rules.toko]"
                        v-model="selectedDepartments"
                        :items="listDepartment"
                        label="Toko yang ikut Penukaran Gift"
                        multiple
                      ></v-combobox>
                    </v-col>
                    <v-col cols="2">
                      <v-btn @click="selectAll" color="warn" class="mr-1"
                        >Pilih Semua</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        ref="nominal"
                        v-model="editedItem.nominal"
                        min="0"
                        prefix="Rp. "
                        suffix=",00"
                        label="Nominal"
                        :rules="[rules.nominal, rules.nominal_null]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="harga_poin"
                        v-model="editedItem.harga_poin"
                        min="0"
                        label="Harga Poin"
                        :rules="[rules.harga_poin, rules.harga_poin_null]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="maksimal_tukar"
                        v-model="editedItem.maksimal_tukar"
                        min="0"
                        label="Maksimal penukaran"
                        :rules="[
                          rules.maksimal_tukar_null,
                          rules.maksimal_tukar,
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="pengambilan"
                        v-model="editedItem.pengambilan"
                        suffix="hari"
                        label="Pengambilan setelah"
                        :rules="[rules.pengambilan, rules.pengambilan_null]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="hangus"
                        v-model="editedItem.hangus_setelah"
                        suffix="hari"
                        label="Hangus setelah"
                        :rules="[rules.hangus, rules.hangus]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="periodeAwal"
                            v-model="editedItem.periode_awal"
                            label="Periode Awal"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            :rules="[rules.periodeAwal]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          header-color="primary"
                          color="secondary"
                          v-model="editedItem.periode_awal"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="periodeAkhir"
                            v-model="editedItem.periode_akhir"
                            label="Periode Akhir"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            :rules="[rules.periodeAkhir]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          header-color="primary"
                          color="secondary"
                          v-model="editedItem.periode_akhir"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto_thumbnail"
                        :rules="fotoThumbnailValidator"
                        @change="onAddFilesThumbnail"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Landscape 16:9"
                        prepend-icon="mdi-camera"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto"
                        :rules="fotoValidator"
                        @change="onAddFiles"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Landscape 16:9"
                        prepend-icon="mdi-camera"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span class="title">Foto Landscape 16:9</span>
                      <v-img
                        :key="keyThumbnail"
                        :src="thumbnailURL"
                        :v-if="showThumbnail"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col cols="12" md="6">
                      <span class="title">Foto Landscape 16:9</span>
                      <v-img :key="keyFoto" :src="fotoURL" v-if="showFoto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-show="formStock">
              <v-card-title>
                <span class="headline"
                  >Tambah Stock {{ editedItem.judul }}</span
                >
              </v-card-title>

              <v-card-text ref="formStock">
                <v-container>
                  <v-text-field
                    ref="tambahanStock"
                    v-model="editedItem.tambahanStock"
                    label="Tambahan Stock"
                    :rules="[rules.tambahanStock]"
                  ></v-text-field>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="tambahStock">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center" width="500px">
            <!-- <v-col cols="12" md="3">
              <v-combobox
                v-model="filterKategori"
                :items="listK"
                label="Kategori Promo"
                hide-details
              ></v-combobox>
            </v-col> -->
            <v-col cols="12" md="3">
              <v-combobox
                v-model="filterDepartment"
                :items="listFilterDepartment"
                item-text="department"
                label="Toko"
                hide-details
                return-object
                clearable="true"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="searchText"
                label="Judul Gift"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filterTahun"
                label="Tahun"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="tambahStockDialog(item)"
          width="140"
          color="green"
          class="mr-1 white--text"
        >
          <v-icon small>mdi-plus</v-icon> Tambah Stock
        </v-btn>
        <v-btn
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn
          small
          @click="deleteItem(item)"
          width="70"
          color="error"
          class="mr-1"
        >
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn>
        <!-- <v-btn small @click="hapusItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn> -->
      </template>
      <template v-slot:no-data>
        Belum ada data gift...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import GiftPoinAPIServices from "../../services/GiftPoinAPIServices";
import RestockGiftPoinAPIServices from "../../services/RestockGiftPoinAPIServices";
import BarangAPIServices from "../../services/BarangAPIServices";
import firebase from "firebase";
require("firebase/auth");

import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import { mapMutations } from "vuex";

export default {
  name: "SetupTukarGift",

  data: () => ({
    rules: {
      judul: (value) => !!value || "Masukkan Judul.",
      toko: (value) => !!value || "Pilih toko.",
      nominal_null: (value) => !!value || "Masukkan nominal.",
      nominal: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor identitas hanya mengandung angka";
      },
      harga_poin_null: (value) => !!value || "Masukkan harga poin.",
      harga_poin: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Harga poin hanya mengandung angka";
      },
      maksimal_tukar_null: (value) => !!value || "Masukkan Maksimal Penukaran.",
      maksimal_tukar: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Harga poin hanya mengandung angka";
      },
      pengambilan_null: (value) => !!value || "Masukkan pengambilan.",
      pengambilan: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Pengambilan hanya mengandung angka";
      },
      periodeAwal: (value) => !!value || "Masukkan Periode Awal.",
      periodeAkhir: (value) => !!value || "Masukkan Periode Akhir.",
      tambahanStock: (value) => !!value || "Masukkan Tambahan Stock.",
    },

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    },

    sessionNIK: "",
    token: "",

    sampleGift: {},
    tempFoto: null,
    tempThumbnail: null,
    showFoto: false,
    showThumbnail: false,
    fotoURL: "",
    thumbnailURL: "",
    keyFoto: 0,
    keyThumbnail: 0,

    filterDepartment: "",
    searchText: "",
    filterTahun: "",

    dialog: false,
    dialogCode: "",

    headers: [
      {
        text: "Judul",
        align: "start",
        value: "judul",
        sortable: false,
      },
      { text: "Periode Awal", value: "periode_awal", sortable: false },
      { text: "Periode Akhir", value: "periode_akhir", sortable: false },
      { text: "Harga Poin", value: "harga_poin", sortable: false },
      { text: "Stock", value: "stock", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    departments: [],
    listDepartment: [],
    selectedDepartments: [],
    gifts: [],
    editedIndex: -1,
    editedItem: {
      foto: "",
      foto_thumbnail: "",
      department: "",
      harga_poin: "",
      id: "",
      judul: "",
      keterangan: "",
      maksimal_tukar: "",
      nominal: "",
      pengambilan: "",
      hangus_setelah: "",
      periode_akhir: "",
      periode_awal: "",
      status: "",
      stock: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      foto: "",
      foto_thumbnail: "",
      department: "",
      harga_poin: "",
      id: "",
      judul: "",
      keterangan: "",
      maksimal_tukar: "",
      nominal: "",
      pengambilan: "",
      hangus_setelah: "",
      periode_akhir: "",
      periode_awal: "",
      status: "",
      stock: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    fotoValidator() {
      return [
        (this.tempFoto != null && this.tempFoto.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },
    fotoThumbnailValidator() {
      return [
        (this.tempThumbnail != null && this.tempThumbnail.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "Tambah Gift" : "Edit Gift";
    },

    formStock() {
      return this.dialogCode === "tambahStock" ? true : false;
    },

    formEdit() {
      return {
        judul: this.editedItem.judul,
        nominal: this.editedItem.nominal,
        maksimal_tukar: this.editedItem.maksimal_tukar,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        pengambilan: this.editedItem.pengambilan,
        harga_poin: this.editedItem.harga_poin,
      };
    },

    formTambah() {
      return {
        judul: this.editedItem.judul,
        foto: this.tempFoto,
        foto_thumbnail: this.tempThumbnail,
        nominal: this.editedItem.nominal,
        maksimal_tukar: this.editedItem.maksimal_tukar,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        pengambilan: this.editedItem.pengambilan,
        harga_poin: this.editedItem.harga_poin,
      };
    },

    validate() {
      var formHasErrors = false;

      if (this.formTitle == "Tambah Voucher")
        Object.keys(this.formTambah).forEach((f) => {
          if (!this.formTambah[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });
      else
        Object.keys(this.formEdit).forEach((f) => {
          if (!this.formEdit[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          this.departments = [];
          this.listDepartment = [];
          this.listFilterDepartment = [];
          this.departments = response.data.data;
          this.departments.forEach((department) => {
            if (department.id_department != "00") {
              this.listDepartment.push(department.department);
              this.listFilterDepartment.push(department);
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: error,
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
      this.praSearch();
    },

    selectAll() {
      this.selectedDepartments = [];
      this.selectedDepartments = this.listDepartment;
    },

    onAddFiles(e) {
      this.tempFoto = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.fotoURL = fr.result;
          this.showFoto = true;
          this.editedItem.foto = this.tempFoto;
        });
      } else {
        if (this.editedItem.foto != "") {
          this.fotoURL = "http://" + this.editedItem.foto;
          this.showFoto = true;
        } else {
          this.fotoURL = "";
          this.showFoto = false;
          this.tempFoto = null;
        }
      }
    },

    onAddFilesThumbnail(e) {
      this.tempThumbnail = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.thumbnailURL = fr.result;
          this.showThumbnail = true;
          this.editedItem.foto_thumbnail = this.tempThumbnail;
        });
      } else {
        if (this.editedItem.foto_thumbnail != "") {
          this.thumbnailURL = "http://" + this.editedItem.foto_thumbnail;
          this.showThumbnail = true;
        } else {
          this.thumbnailURL = "";
          this.showThumbnail = false;
          this.tempThumbnail = null;
        }
      }
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.$store.commit("showProgress", {
        message: "Mengambil data gift",
      });
      var body = {};
      if(this.filterDepartment != "" && this.filterDepartment != null)
        body.toko = this.filterDepartment.id_department;
      if(this.searchText != "" && this.searchText != null)
        body.judul = this.searchText;
      if(this.filterTahun != "" && this.filterTahun != null)
        body.tahun = this.filterTahun;
      body.limit = this.itemsPerPage;
      
      GiftPoinAPIServices.filter(this.token, body, this.page)
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.gifts = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Gift gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    editItem(item) {
      this.editedIndex = this.gifts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.selectedDepartments = [];
      this.editedItem.gift_department.forEach((toko) => {
        this.departments.forEach((department) => {
          if (toko.department_id == department.id_department) {
            this.selectedDepartments.push(department.department);
            return;
          }
        });
      });

      this.fotoURL = "http://" + this.editedItem.foto;
      this.thumbnailURL = "http://" + this.editedItem.foto_thumbnail;
      this.showFoto = true;
      this.showThumbnail = true;

      this.dialog = true;
    },
    tambahStockDialog(item) {
      this.dialogCode = "tambahStock";
      this.editedIndex = this.gifts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    tambahStock() {
      this.$store.commit("showProgress", {
        message: "Proses menambah stock Gift",
      });

      var body = {};
      body.user_create = this.sessionNIK;
      body.gift_id = this.editedItem.id;
      body.jumlah = this.editedItem.tambahanStock;

      RestockGiftPoinAPIServices.create(this.token, body)
        .then(() => {
          this.close();
          this.showSnackbar({
            color: "secondary",
            message: "Stock berhasil di tambah",
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Stock gagal di tambah",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
          this.initialize();
        });
    },

    deleteItem(item) {
      confirm("Hapus " + item.judul + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus gift",
        }),
        GiftPoinAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Gift berhasil dihapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gift gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      this.dialog = false;
      this.dialogCode = "";
      this.selectedDepartments = [];
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.keyFoto++;
        this.keyThumbnail++;
        this.showFoto = false;
        this.showThumbnail = false;

        this.onAddFiles(null);
        this.onAddFilesThumbnail(null);

        Object.keys(this.formTambah).forEach((f) => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    save() {
      if (this.validate) {
        if (this.formTitle == "Tambah Gift") {
          this.$store.commit("showProgress", {
            message: "Proses menambah Gift",
          });
          this.editedItem.user_create = this.sessionNIK;

          this.editedItem.toko = [];
          this.selectedDepartments.forEach((toko) => {
            this.departments.forEach((department) => {
              if (toko == department.department) {
                this.editedItem.toko.push(department.id_department);
                return;
              }
            });
          });

          let formData = new FormData();
          formData.append("department", JSON.stringify(this.editedItem.toko));
          if (this.editedItem.foto != null)
            formData.append("foto", this.editedItem.foto);
          if (this.editedItem.foto_thumbnail != null)
            formData.append("foto_thumbnail", this.editedItem.foto_thumbnail);
          formData.append("harga_poin", this.editedItem.harga_poin);
          formData.append("judul", this.editedItem.judul);
          formData.append("pengambilan", this.editedItem.pengambilan);
          formData.append("hangus_setelah", this.editedItem.hangus_setelah);
          formData.append("keterangan", this.editedItem.keterangan);
          formData.append("maksimal_tukar", this.editedItem.maksimal_tukar);
          formData.append("nominal", this.editedItem.nominal);
          formData.append("periode_awal", this.editedItem.periode_awal);
          formData.append("periode_akhir", this.editedItem.periode_akhir);
          formData.append("user_create", this.sessionNIK);
          this.editedItem.user_create = this.sessionNIK;

          GiftPoinAPIServices.create(this.token, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Gift gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              this.initialize();
            });
        } else {
          this.$store.commit("showProgress", {
            message: "Proses mengedit Gift",
          });
          this.editedItem.user_update = this.sessionNIK;
          this.editedItem.toko = [];
          this.selectedDepartments.forEach((toko) => {
            this.departments.forEach((department) => {
              if (toko == department.department) {
                this.editedItem.toko.push(department.id_department);
                return;
              }
            });
          });

          let formData = new FormData();
          formData.append("department", JSON.stringify(this.editedItem.toko));
          if (this.editedItem.foto != null)
            formData.append("foto", this.editedItem.foto);
          if (this.editedItem.foto_thumbnail != null)
            formData.append("foto_thumbnail", this.editedItem.foto_thumbnail);
          formData.append("harga_poin", this.editedItem.harga_poin);
          formData.append("judul", this.editedItem.judul);
          formData.append("pengambilan", this.editedItem.pengambilan);
          formData.append("hangus_setelah", this.editedItem.hangus_setelah);
          formData.append("keterangan", this.editedItem.keterangan);
          formData.append("maksimal_tukar", this.editedItem.maksimal_tukar);
          formData.append("nominal", this.editedItem.nominal);
          formData.append("periode_awal", this.editedItem.periode_awal);
          formData.append("periode_akhir", this.editedItem.periode_akhir);
          formData.append("user_update", this.sessionNIK);
          GiftPoinAPIServices.edit(this.token, this.editedItem.id, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Gift gagal di edit",
                });
              }
              this.snackbar = true;
              this.snackbarColor = "error";
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              this.initialize();
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
