import Vue from "vue";
import Home from "./components/Home";
import Member from "./components/Member";
import Promo from "./components/Promo";
import PromoKhusus from "./components/PromoKhusus";
import Notifikasi from "./components/Notifikasi";
import AdminSetup from "./components/AdminSetup";
import SetupKategoriPromo from "./components/KategoriPromoSetup";
import Login from "./components/Login";
// import TukarPromo from "./components/TukarPromo";
import LaporanTukarPromo from "./components/LaporanTukarPromo";
import LaporanTukarPromoMerchant from "./components/v2/LaporanTukarPromoMerchant";
import RekapTukarPromoMerchant from "./components/v2/RekapTukarPromoMerchant";
import RekapMerchantHabisKontrak from "./components/v2/RekapMerchantHabisKontrak";
import SetupToko from "./components/TokoSetup";
import GantiPassword from "./components/GantiPassword";
import SetupTukarPoin from "./components/v2/SetupTukarPoin";
import SetupTukarPoinAdmin from "./components/v2/SetupTukarPoinAdmin";
import SetupTukarGift from "./components/v2/SetupTukarGift";
import SetupPoin from "./components/v2/SetupPoin";
import AmbilGift from "./components/v2/AmbilGift";
import LaporanTukarGift from "./components/v2/LaporanTukarGift";
import LaporanTukarVoucher from "./components/v2/LaporanTukarVoucher";
import SetupMerchant from "./components/v2/SetupMerchant";
import FormMerchant from "./components/v2/FormMerchant";
import DetailMerchant from "./components/v2/DetailMerchant";
import MemberBon from "./components/v3/MemberBon";
import MemberBonDetail from "./components/v3/MemberBonDetail";
import VueRouter from "vue-router";
import firebase from 'firebase';
require('firebase/auth');

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BABEL_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/member",
      name: "member",
      component: Member,
      meta: { requiresAuth: true },
    },
    {
      path: "/promo",
      name: "promo",
      component: Promo,
      meta: { requiresAuth: true },
    },
    {
      path: "/promo-khusus",
      name: "promoKhusus",
      component: PromoKhusus,
      meta: { requiresAuth: true },
    },
    {
      path: "/notifikasi",
      name: "notifikasi",
      component: Notifikasi,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-kategori-promo",
      name: "setupKategoriPromo",
      component: SetupKategoriPromo,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-toko",
      name: "setupToko",
      component: SetupToko,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-admin",
      name: "setupAdmin",
      component: AdminSetup,
      meta: { requiresAuth: true },
    },
    // {
    //   path: "/tukar-promo",
    //   name: "tukarPromo",
    //   component: TukarPromo,
    //   meta: { requiresAuth: true },
    // },
    {
      path: "/laporan-tukar-promo",
      name: "laporanTukarPromo",
      component: LaporanTukarPromo,
      meta: { requiresAuth: true },
    },
    {
      path: "/laporan-tukar-promo-merchant",
      name: "laporanTukarPromoMerchant",
      component: LaporanTukarPromoMerchant,
      meta: { requiresAuth: true },
    },
    {
      path: "/rekap-tukar-promo-merchant",
      name: "rekapTukarPromoMerchant",
      component: RekapTukarPromoMerchant,
      meta: { requiresAuth: true },
    },
    {
      path: "/merchant-habis",
      name: "merchantHabis",
      component: RekapMerchantHabisKontrak,
      meta: { requiresAuth: true },
    },
    {
      path: "/laporan-tukar-poin-gift",
      name: "laporanTukarGift",
      component: LaporanTukarGift,
      meta: { requiresAuth: true },
    },
    {
      path: "/laporan-tukar-poin-voucher",
      name: "laporanTukarVoucher",
      component: LaporanTukarVoucher,
      meta: { requiresAuth: true },
    },
    {
      path: "/ganti-password",
      name: "gantiPassword",
      component: GantiPassword,
      meta: { requiresAuth: true },
    },

    {
      path: "/setup-tukar-voucher-admin",
      name: "setupTukarPoinAdmin",
      component: SetupTukarPoinAdmin,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-tukar-voucher",
      name: "setupTukarPoin",
      component: SetupTukarPoin,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-tukar-gift",
      name: "setupTukarGift",
      component: SetupTukarGift,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-poin",
      name: "setupPoin",
      component: SetupPoin,
      meta: { requiresAuth: true },
    },
    {
      path: "/ambil-gift",
      name: "ambilGift",
      component: AmbilGift,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-merchant",
      name: "setupMerchant",
      component: SetupMerchant,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-merchant/form-merchant",
      name: "formMerchant",
      component: FormMerchant,
      meta: { requiresAuth: true },
    },
    {
      path: "/setup-merchant/detail-merchant",
      name: "detailMerchant",
      component: DetailMerchant,
      meta: { requiresAuth: true },
    },
    {
      path: "/member-bon",
      name: "memberBon",
      component: MemberBon,
      meta: { requiresAuth: true },
    },
    {
      path: "/member-bon-detail",
      name: "memberBonDetail",
      component: MemberBonDetail,
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = firebase.auth().currentUser;

  if (to.name == "login" && isAuthenticated && !requiresAuth) {
    next("/");
  }
  else if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else {
      next();
  }
});

export default router;
