<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="kategoris"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">Kategori Promo</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-text-field
                    :disabled="formTitle == 'Edit Kategori Promo'"
                    ref="kode"
                    v-model="editedItem.kode"
                    label="Kode Kategori"
                    :rules="[rules.kode]"
                  ></v-text-field>
                  <v-text-field
                    ref="nama"
                    v-model="editedItem.nama"
                    counter="200"
                    label="Nama Kategori"
                    :rules="[rules.nama, rules.counter_nama]"
                  ></v-text-field>
                  <v-row>
                    <!-- TODO: HIDE KHUSUS -->
                    <!-- <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="editedItem.khusus"
                        inset
                        :label="watchKhusus"
                      ></v-switch>
                    </v-col> -->
                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="editedItem.statusBool"
                        inset
                        :label="watchStatus"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>

          <v-row align="center">
            <v-col>
              <v-text-field
                ref="searchKode"
                v-model="searchKode"
                label="Kode Kategori"
                hide-details
                clearable
                :rules="[rules.kode]"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="editItem(item)"
          width="80"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
      <template v-slot:no-data>
        Belum ada data kategori promo...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import KategoriPromoAPIServices from "../services/KategoriPromoAPIServices";
import firebase from 'firebase';
require('firebase/auth');

import { mapMutations } from "vuex";

export default {
  name: "Kategori Promo",

  data: () => ({
    rules: {
      kode: (value) => value.length == 2 || "Masukkan 2 digit kode kategori.",
      nama: (value) => !!value || "Masukkan nama kategori.",
      counter_nama: (value) =>
        value.length <= 200 || "Nama kategori terlalu panjang.",
    },

    searchKode: "",
    token: "",
    sessionNIK: "",

    dialog: false,

    headers: [
      {
        text: "Kode Kategori",
        align: "start",
        value: "kode",
        sortable: false,
      },
      { text: "Nama Kategori", value: "nama", sortable: false },
      { text: "Tipe Kategori", value: "textKhusus", sortable: false },
      { text: "Status", value: "textStatus", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    kategoris: [],

    editedIndex: -1,
    editedItem: {
      kode: "",
      nama: "",
      khusus: false,
      status: "A",
      statusBool: true,
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      kode: "",
      nama: "",
      khusus: false,
      status: "A",
      statusBool: true,
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    watchStatus() {
      return this.editedItem.statusBool === true ? "Aktif" : "Tidak Aktif";
    },

    watchKhusus() {
      return this.editedItem.khusus == 1 ? "Khusus" : "Umum";
    },

    formTitle() {
      return this.editedIndex === -1
        ? "Tambah Kategori Promo"
        : "Edit Kategori Promo";
    },
    form() {
      return {
        kode: this.editedItem.kode,
        nama: this.editedItem.nama,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
      this.praSearch();
    },

    praSearch() {
      this.page = 1;
      if (this.searchKode != "") this.searchKodeKategori();
      else this.search();
    },

    searchKodeKategori() {
      this.kategoris = [];
      this.$store.commit("showProgress", {
        message: "Mengambil data kategori promo",
      });
      KategoriPromoAPIServices.getKode(
        this.token,
        this.itemsPerPage,
        this.page,
        this.searchKode
      )
        .then((response) => {
          response.data.data.forEach((kategori) => {
            if (kategori.status == "A") {
              kategori.statusBool = true;
              kategori.textStatus = "Aktif";
            } else {
              kategori.statusBool = false;
              kategori.textStatus = "Tidak aktif";
            }

            if (kategori.khusus == 1) {
              kategori.textKhusus = "Khusus";
            } else {
              kategori.textKhusus = "Umum";
            }
            this.kategoris.push(kategori);
          });
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kategori Promo gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    search() {
      this.kategoris = [];
      this.$store.commit("showProgress", {
        message: "Mengambil data kategori promo",
      });
      KategoriPromoAPIServices.getAll(this.token, this.itemsPerPage, this.page)
        .then((response) => {
          response.data.data.forEach((kategori) => {
            if (kategori.status == "A") {
              kategori.statusBool = true;
              kategori.textStatus = "Aktif";
            } else {
              kategori.statusBool = false;
              kategori.textStatus = "Tidak aktif";
            }

            if (kategori.khusus == 1) {
              kategori.textKhusus = "Khusus";
            } else {
              kategori.textKhusus = "Umum";
            }
            this.kategoris.push(kategori);
          });
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kategori Promo gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    editItem(item) {
      this.editedIndex = this.kategoris.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    hapusItem(item) {
      confirm("Hapus Kategori Promo " + item.nama + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus kategori promo",
        }),
        KategoriPromoAPIServices.delete(this.token, item.kode, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Kategori Promo berhasil di hapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kategori Promo gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.validate) {
        if (this.editedItem.statusBool) this.editedItem.status = "A";
        else this.editedItem.status = "T";

        if (this.formTitle == "Tambah Kategori Promo") {
          this.editedItem.khusus = 0;

          this.$store.commit("showProgress", {
            message: "Proses menambah kategori promo",
          });
          this.editedItem.user_create = this.sessionNIK;
          KategoriPromoAPIServices.create(this.token, this.editedItem)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil ditambah",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  if (error.response.data.message.includes("PRIMARY")) {
                    this.showSnackbar({
                      color: "error",
                      message: "Kode kategori sudah dipakai.",
                    });
                  } else {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kategori Promo gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.dialogProgress = false;
              this.$store.commit("closeProgress");
            });
        } else {
          this.editedItem.user_update = this.sessionNIK;
          this.$store.commit("showProgress", {
            message: "Proses mengedit kategori promo",
          });
          KategoriPromoAPIServices.edit(
            this.token,
            this.editedItem.kode,
            this.editedItem
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.snackbarText = error.response.data.message;
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kategori Promo gagal di muat",
                });
              }
            })
            .finally(() => {
              this.dialogProgress = false;
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },
    
    ...mapMutations(["showSnackbar"]),
  },
};
</script>
