<template>
  <v-container>
    <v-container>
      <v-card class="elevation-3">
        <v-card-title>
          <span class="headline">{{ judulMerchant }}</span>
        </v-card-title>
        <v-card-text ref="formMerchant">
          <v-text-field
            ref="nama"
            v-model="editedMerchant.nama"
            label="Nama"
            counter="200"
            :rules="[rules.nama, rules.counter_nama]"
          ></v-text-field>
          <v-text-field
            ref="bidang_usaha"
            v-model="editedMerchant.bidang_usaha"
            label="Bidang Usaha"
            counter="64"
            :rules="[rules.bidang_usaha, rules.bidang_usaha]"
          ></v-text-field>
          <v-textarea
            ref="alamat"
            v-model="editedMerchant.alamat"
            label="Alamat"
            auto-grow
            rows="1"
            counter="500"
            :rules="[rules.alamat, rules.counter_alamat]"
          ></v-textarea>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-combobox
                ref="kodepos"
                v-model="inputKodePos"
                :items="listKodePos"
                label="Pencarian kodepos"
                placeholder="Kelurahan/ Kecamatan/ Kabupaten/ Provinsi"
                @input="selectKodePos"
                @keyup.enter="searchKodePos"
                :rules="[rules.kodepos]"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                disabled="true"
                v-model="kodepos"
                label="Kodepos"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                ref="ponsel"
                v-model="editedMerchant.no_ponsel"
                label="Nomor Handphone"
                counter="200"
                :rules="[rules.ponsel]"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                ref="telpon"
                v-model="editedMerchant.no_telpon"
                label="Nomor Telepon"
                counter="200"
                :rules="[rules.telpon]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                ref="logo"
                :rules="logoValidator"
                @change="onAddFiles"
                accept="image/png, image/jpeg, image/bmp, image/jpg"
                label="Logo"
                prepend-icon="mdi-camera"
              ></v-file-input>
            </v-col>
            <v-col>
              <v-img width="500" :key="keyLogo" :src="logoURL" v-if="showLogo">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
          <v-row>
            <h2>Lokasi Toko</h2>
          </v-row>
          <v-row class="pa-3">
            <label>
              <gmap-autocomplete
                style="
                  width: 300px;
                  border-bottom-style: solid;
                  border-color: black;
                  border-width: 1px;
                  margin-right: 10px;
                  font-size: 16px;
                  color: black;
                "
                placeholder="Masukan pencarian lokasi"
                :value="selectedPlace"
                @place_changed="setPlace"
              />
              <v-btn small @click="addMarker" color="primary">
                Tandai Lokasi
              </v-btn>
            </label>
          </v-row>
          <v-row>
            <gmap-map
              :center="center"
              :zoom="12"
              style="width: 100%; height: 400px"
            >
              <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
              ></gmap-marker>
            </gmap-map>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="textGrey" text @click="close">Batal</v-btn>
          <v-btn color="accent" text @click="saveMerchant">Simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import KodePosAPIService from "../../services/KodePosAPIServices";
import MerchantAPIServices from "../../services/MerchantAPIServices";
import firebase from "firebase";
require("firebase/auth");

import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import { mapMutations } from "vuex";

export default {
  name: "SetupMerchant",

  data: () => ({
    rules: {
      nama: (value) => !!value || "Masukkan nama merchant.",
      bidang_usaha: (value) => !!value || "Masukkan bidang usaha.",
      alamat: (value) => !!value || "Masukkan alamat merchant.",
      kodepos: (value) => !!value || "Lakukan pencarian kodepos.",
      telpon: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor telepon hanya mengandung angka";
      },
      ponsel: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor ponsel hanya mengandung angka";
      },
      counter_nama: (value) =>
        value.length <= 200 || "Nama Toko terlalu panjang.",
      counter_bidang_usaha: (value) =>
        value.length <= 64 || "Nama Toko terlalu panjang.",
      counter_alamat: (value) =>
        value.length <= 200 || "Alamat terlalu panjang.",
    },

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    },

    inputKodePos: "",
    listKodePos: [],
    kodepos: "",

    center: { lat: 7.74, lng: 110.6646 },
    markers: [],
    places: [],
    currentPlace: null,
    selectedPlace: "",

    sessionNIK: "",
    token: "",

    departments: [],
    selectedDepartments: [],

    tempLogo: null,
    keyLogo: 0,
    logoURL: "",
    showLogo: false,

    editedIndexMerchant: -1,
    editedMerchant: {
      id_kodepos: "",
      nama: "",
      bidang_usaha: "",
      alamat: "",
      no_telpon: "",
      no_ponsel: "",
      logo: "",
      longitude: "",
      latitude: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },
    defaultMerchant: {
      id_kodepos: "",
      nama: "",
      bidang_usaha: "",
      alamat: "",
      no_telpon: "",
      no_ponsel: "",
      logo: "",
      longitude: "",
      latitude: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initialize();
  },

  computed: {
    logoValidator() {
      return [
        (this.tempLogo != null && this.tempLogo.size < 1000000) ||
          "Logo tidak boleh kosong, ukuran max 1 MB",
      ];
    },

    judulMerchant() {
      return this.editedIndexMerchant === -1
        ? "Tambah Merchant"
        : "Edit Merchant";
    },

    formTambah() {
      return {
        nama: this.editedMerchant.nama,
        bidang_usaha: this.editedMerchant.bidang_usaha,
        alamat: this.editedMerchant.alamat,
        longitude: this.editedMerchant.longitude,
        latitude: this.editedMerchant.latitude,
        no_telpon: this.editedMerchant.no_telpon,
        no_ponsel: this.editedMerchant.no_ponsel,
        logo: this.tempLogo,
      };
    },

    validateMerchant() {
      var formHasErrors = false;

      // if (this.formTitle == "Form Merchant")
      //   Object.keys(this.formTambah).forEach((f) => {
      //     if (!this.formTambahMerchant[f]) formHasErrors = true;

      //     this.$refs[f].validate(true);
      //   });
      // else
      //   Object.keys(this.formEdit).forEach((f) => {
      //     if (!this.formEditMerchant[f]) formHasErrors = true;

      //     this.$refs[f].validate(true);
      //   });

      return !formHasErrors;
    },
  },

  methods: {
    selectKodePos() {
      this.kodepos = this.inputKodePos.kodepos;
    },

    searchKodePos() {
      this.listKodePos = [];
      this.$store.commit("showProgress", {
        message: "Mencari kodepos " + this.inputKodePos,
      });
      KodePosAPIService.search(this.token, this.inputKodePos)
        .then((response) => {
          response.data.data.forEach((kodepos) => {
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kodepos gagal dicari",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    setPlace(place) {
      this.currentPlace = place;
    },
    addMarker() {
      if (this.currentPlace) {
        this.markers = [];
        this.places = [];
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;

        this.editedMerchant.latitude =
          this.currentPlace.geometry.location.lat();
        this.editedMerchant.longitude =
          this.currentPlace.geometry.location.lng();
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      if (
        (this.editedMerchant.latitude == "" &&
          this.editedMerchant.longitude == "") ||
        (this.editedMerchant.latitude == undefined &&
          this.editedMerchant.longitude == undefined) ||
        (this.editedMerchant.latitude == null &&
          this.editedMerchant.longitude == null)
      )
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      else {
        const marker = {
          lat: this.editedMerchant.latitude,
          lng: this.editedMerchant.longitude,
        };
        this.markers.push({ position: marker });
        this.center = marker;
      }
    },

    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      if (this.$store.state.merchant != null) {
        this.editedMerchant = Object.assign({}, this.$store.state.merchant);
        this.editedIndexMerchant = this.$store.idxMerchant;

        if (
          this.editedMerchant.id_kodepos != null ||
          this.editedMerchant.id_kodepos != "" ||
          this.editedMerchant.kodepos != undefined
        ) {
          this.$store.commit("showProgress", {
            message: "Proses mengambil data merchant",
          });
          await KodePosAPIService.getByID(
            this.token,
            this.editedMerchant.id_kodepos
          )
            .then((response) => {
              var kodepos = response.data;
              kodepos.text =
                kodepos.kelurahan +
                ", " +
                kodepos.kecamatan +
                ", " +
                kodepos.kabupaten +
                ", " +
                kodepos.provinsi;
              this.listKodePos.push(kodepos);
              this.kodepos = kodepos.kodepos;
              this.inputKodePos = kodepos.text;
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kodepos gagal dimuat",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
        this.logoURL = "https://" + this.editedMerchant.logo;
        this.showLogo = true;
        this.geolocate();
      } else {
        this.editedMerchant = Object.assign({}, this.defaultMerchant);
        this.editedIndexMerchant = -1;
      }
    },

    onAddFiles(e) {
      this.tempLogo = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.logoURL = fr.result;
          this.showLogo = true;
          this.editedMerchant.logo = this.tempLogo;
        });
      } else {
        if (this.editedMerchant.logo != "") {
          this.logoURL = "http://" + this.editedMerchant.logo;
          this.showLogo = true;
        } else {
          this.logoURL = "";
          this.showLogo = false;
          this.tempLogo = null;
        }
      }
    },

    saveMerchant() {
      this.listKodePos.forEach((kodepos) => {
        if (this.inputKodePos.text == kodepos.text) {
          this.editedMerchant.id_kodepos = kodepos.id;
        }
      });
      if (this.validateMerchant) {
        let formData = new FormData();
        if (this.editedMerchant.logo != null)
          formData.append("logo", this.editedMerchant.logo);
        formData.append("id_kodepos", this.editedMerchant.id_kodepos);
        formData.append("nama", this.editedMerchant.nama);
        formData.append("bidang_usaha", this.editedMerchant.bidang_usaha);
        formData.append("alamat", this.editedMerchant.alamat);
        formData.append("no_telpon", this.editedMerchant.no_telpon);
        formData.append("no_ponsel", this.editedMerchant.no_ponsel);
        formData.append("latitude", this.editedMerchant.latitude);
        formData.append("longitude", this.editedMerchant.longitude);

        if (this.judulMerchant == "Tambah Merchant") {
          this.editedMerchant.user_create = this.sessionNIK;
          formData.append("user_create", this.sessionNIK);
          this.$store.commit("showProgress", {
            message: "Proses menambah merchant",
          });
          this.editedMerchant.user_create = this.sessionNIK;
          MerchantAPIServices.create(this.token, formData)
            .then(() => {
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah",
              });
              this.$store.commit("removeMerchant");
              this.$router.replace("/setup-merchant");
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Merchant gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        } else {
          this.editedMerchant.user_update = this.sessionNIK;
          formData.append("status", this.editedMerchant.status);
          formData.append("user_update", this.sessionNIK);

          this.$store.commit("showProgress", {
            message: "Proses mengedit merchant",
          });
          MerchantAPIServices.edit(
            this.token,
            this.editedMerchant.id,
            formData
          )
            .then(() => {
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });

              this.$store.commit("removeMerchant");
              this.$router.replace("/setup-merchant");
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Merchant promo gagal di muat",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
