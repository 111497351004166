import axios from "axios";
import Properties from "../properties"

const API_URL = "https://pelanggan." +Properties.DOMAIN_URL +"/api/v1";

export default class KodePosAPIService {
  constructor() {}
  static search = (token, input) =>
    axios.get(API_URL + "/kode-pos/" +input +"/limit/100", {
      headers: { Authorization: "Bearer " + token },
    });
  static getByID = (token, id) => axios.get(API_URL +"/kode-pos/" +id, {
    headers: { Authorization: "Bearer " + token },
  });
}
