<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="9">
        <v-card class="elevation-1">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Form Laporan Tukar Promo</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAwal"
                        v-model="periode_awal"
                        label="Periode Awal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAwal]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_awal"
                      @input="
                        () => {
                          menu1 = false;
                          ambilPromo();
                        }
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAkhir"
                        v-model="periode_akhir"
                        label="Periode Akhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAkhir]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_akhir"
                      @input="
                        () => {
                          menu2 = false;
                          ambilPromo();
                        }
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-combobox
                    ref="promo"
                    v-model="selectedPromo"
                    :items="listPromos"
                    return-object
                    item-text="judul"
                    label="Judul Voucher"
                    :disabled="showTabel"
                    multiple
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    @click="selectAllPromo"
                    :disabled="showTabel"
                    color="warn"
                    >Semua</v-btn
                  >
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAwal"
                        v-model="periode_awal"
                        label="Periode Awal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAwal]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_awal"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAkhir"
                        v-model="periode_akhir"
                        label="Periode Akhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAkhir]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_akhir"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row> -->

              <v-row align="center">
                <v-col cols="10">
                  <v-combobox
                    ref="toko"
                    :rules="[rules.toko]"
                    v-model="selectedDepartments"
                    :items="listDepartment"
                    label="Pilih Toko"
                    multiple
                    :disabled="showTabel"
                  ></v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="selectAll" :disabled="showTabel" color="warn"
                    >Semua</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer />
            <v-btn color="warn" @click="buat">Buat Laporan</v-btn>
            <v-btn color="primary" :disabled="disableTukar" @click="cetak"
              >Cetak PDF</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-show="showTabel">
      <v-col cols="12" md="12">
        <v-list>
          <div class="text-h5 mt-5">Laporan Penukaran Promo</div>
          <v-list-item>Laporan dicetak pada: {{ fnow }}</v-list-item>
          <v-list-item
            >Periode Laporan Promo: {{ fperiode_awal }} s/d
            {{ fperiode_akhir }}</v-list-item
          >
          <v-list-item>Sub-total: {{ subTotals }}</v-list-item>
          <v-list-item v-for="item in unit" :key="item">
            <v-list-item-content>
              <v-data-table
                :headers="headers"
                :items="item.items"
                hide-default-footer
                class="elevation-1"
                :items-per-page="1000"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    Unit: {{ item.department }}
                    <v-spacer />
                    Total Rupiah: {{ item.totals }}
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Belum ada data penukaran promo...
                </template>
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import LaporanTukarServices from "../services/LaporanTukarServices";
import BarangAPIServices from "../services/BarangAPIServices";
import firebase from "firebase";
require("firebase/auth");

import moment from "moment";
import { mapMutations } from "vuex";
import PromoAPIService from "../services/PromoAPIServices";

export default {
  data: () => ({
    rules: {
      periodeAwal: value => !!value || "Masukkan periode awal.",
      periodeAkhir: value => !!value || "Masukkan periode akhir.",
      toko: value => !!value || "Masukkan toko."
    },

    listPromos: [],
    selectedPromo: [],

    departments: [],
    selectedDepartments: [],
    selectedKode: [],
    listDepartment: [],
    periode_awal: "",
    periode_akhir: "",
    fperiode_awal: "",
    fperiode_akhir: "",

    subTotals: 0,
    now: "",
    fnow: "",
    unit: [],
    menu1: false,
    menu2: false,

    dialog: false,

    showTabel: false,
    disableTukar: true,

    headers: [
      {
        text: "No",
        align: "start",
        value: "index",
        sortable: false,
        title: "No",
        dataKey: "index"
      },
      {
        text: "Tanggal Transaksi",
        align: "start",
        value: "tgl_entry",
        sortable: false,
        title: "Tanggal Transaksi",
        dataKey: "tgl_entry"
      },
      {
        text: "Kode Promo",
        align: "start",
        value: "kode_promo",
        sortable: false,
        title: "Kode Promo",
        dataKey: "kode_promo"
      },
      {
        text: "Judul Promo",
        align: "start",
        value: "nama_promo",
        sortable: false,
        title: "Judul Promo",
        dataKey: "nama_promo"
      },
      {
        text: "Kode Pelanggan",
        align: "start",
        value: "alias",
        sortable: false,
        title: "Kode Pelanggan",
        dataKey: "alias"
      },
      {
        text: "Nama Pelanggan",
        align: "start",
        value: "nama_pelanggan",
        sortable: false,
        title: "Nama Pelanggan",
        dataKey: "nama_pelanggan"
      },
      {
        text: "Ponsel Pelanggan",
        align: "start",
        value: "ponsel_pelanggan",
        sortable: false,
        title: "Nama Pelanggan",
        dataKey: "ponsel_pelanggan"
      },
      {
        text: "Rupiah Voucher",
        align: "start",
        value: "rupiah",
        sortable: false,
        title: "Rupiah Voucher",
        dataKey: "rupiah"
      },
      {
        text: "Nominal Belanja",
        align: "start",
        value: "nominal_belanja",
        sortable: false,
        title: "Nominal Belanja",
        dataKey: "nominal_belanja"
      },
      {
        text: "Prosentase Voucher",
        align: "start",
        value: "persentase",
        sortable: false,
        title: "Prosentase Voucher",
        dataKey: "persentase"
      },
      {
        text: "User Admin",
        align: "start",
        value: "nama_karyawan",
        sortable: false,
        title: "User Admin",
        dataKey: "nama_karyawan"
      }
    ]
  }),

  created() {
    this.initialize();
  },

  computed: {
    form() {
      return {
        periodeAwal: this.periode_awal,
        periodeAkhir: this.periode_akhir,
        toko: this.selectedDepartments
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    }
  },

  methods: {
    resetBuatLaporan() {},

    async ambilPromo() {
      var body = {};
      body.periode_awal = this.periode_awal;
      body.periode_akhir = this.periode_akhir;
      if (
        this.periode_awal != null &&
        this.periode_awal != "" &&
        this.periode_akhir != null &&
        this.periode_akhir != ""
      ) {
        this.$store.commit("showProgress", {
          message: "Mengambil data promo"
        });
        await PromoAPIService.getListPromo(this.token, body)
          .then(response => {
            this.listPromos = response.data;
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Promo gagal di muat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress", {
              message: "Mengambil data toko"
            });
          });
      }
    },

    reset() {
      this.subTotals = 0;
      this.unit = [];
      this.selectedKode = [];
      this.periode_awal = "";
      this.periode_akhir = "";
      this.selectedDepartments = [];
      this.showTabel = false;
      this.disableTukar = true;
      Object.keys(this.form).forEach(f => {
        this.$refs[f].resetValidation();
      });
    },
    selectAll() {
      this.selectedDepartments = this.listDepartment;
    },
    selectAllPromo() {
      this.selectedPromo = this.listPromos;
    },
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(token => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });

          var body = {};
          body.khusus = 0;
          body.desc = 1;
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.$store.commit("showProgress", {
        message: "Mengambil data toko"
      });

      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then(response => {
          this.departments = response.data.data;
          this.departments.forEach(department => {
            if (department.id_department != "00") {
              this.listDepartment.push(department.department);
            }
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async buat() {
      this.now = new Date().toLocaleString();
      this.fnow = moment(this.now)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_awal = moment(this.periode_awal)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_akhir = moment(this.periode_akhir)
        .locale("id")
        .format("DD MMMM yyyy");
      this.selectedKode = [];
      this.unit = [];
      this.selectedDepartments.forEach(select => {
        this.departments.forEach(department => {
          if (select == department.department) {
            this.selectedKode.push(department.id_department);
            return;
          }
        });
      });
      if (this.validate) {
        this.$store.commit("showProgress", {
          message: "Membuat laporan data penukaran promo"
        });
        var promos = [];
        this.selectedPromo.forEach(promo => {
          promos.push(promo.kode);
        });
        await LaporanTukarServices.getLaporan(
          this.periode_awal,
          this.periode_akhir,
          { toko: this.selectedKode, promos: promos },
          this.token
        )
          .then(response => {
            this.unit = response.data;
            var counter = 0;
            this.unit.forEach(toko => {
              counter = 0;
              this.subTotals += toko.totals;
              let val = (toko.totals / 1).toFixed(2).replace(".", ",");
              toko.totals =
                "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              toko.items.forEach(item => {
                counter++;
                item.index = counter;
                if (item.rupiah != "-") {
                  let val = (item.rupiah / 1).toFixed(2).replace(".", ",");
                  item.rupiah =
                    "Rp. " +
                    val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
                if (item.nominal_belanja != "-") {
                  let val = (item.nominal_belanja / 1).toFixed(2).replace(".", ",");
                  item.nominal_belanja =
                    "Rp. " +
                    val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }

                if (item.persentase != "-") {
                  item.persentase = item.persentase + "%";
                }
              });
            });
            let val = (this.subTotals / 1).toFixed(2).replace(".", ",");
            this.subTotals =
              "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            this.showTabel = true;
            this.disableTukar = false;
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gagal membuat laporan"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid"
        });
      }
    },

    cetak() {
      var doc = new jsPDF("l", "pt", "a4");
      var counter = 0;
      doc.setFontSize(14);
      doc.setFontType("bold");
      doc.text(25, 50, "Laporan Penukaran Poin dengan Voucher");
      doc.setFontSize(10);
      doc.setFontType("normal");
      doc.text(
        25,
        65,
        "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
      );
      doc.setFontSize(12);
      this.unit.forEach(toko => {
        doc.text("\n" + toko.department + ": " + toko.totals, 40, 80);
        doc.autoTable(this.headers, toko.items, { margin: { top: 110 } });
        counter++;
        if (counter != this.unit.length) {
          doc.addPage();
        }
      });
      var pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica");
      doc.setFontType("bold");
      doc.setFontSize(9);
      doc.setTextColor(125, 125, 125);

      for (var i = 0; i < pageCount; i++) {
        doc.text(
          25,
          25,
          "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
        );
        doc.text(
          680,
          25,
          "Sub-total " + this.unit.length + " unit: " + this.subTotals
        );

        doc.setPage(i);
        doc.text(
          25,
          575,
          "Halaman: " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " dari " +
            pageCount
        );
        doc.text(680, 575, "Dicetak pada: " + this.fnow);
      }
      doc.save("Laporan_Tukar_Promo_" + this.now + ".pdf");
    },

    ...mapMutations(["showSnackbar"])
  }
};
</script>
