import Vue from "vue";
import Vuetify from "vuetify/lib";
import Properties from "../properties"

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#31D8A7",
        secondary: "#006062",
        accent: "#61B136",
        warn: "#FEC72E",
        error: "#8E0000",
        textGrey: "#888888",
        appBarColor: Properties.BUILD_TYPE == "production" ? "#31D8A7" : "#444444",
        appBarText: Properties.BUILD_TYPE == "production" ? "#006062" : "#EEEEEE"
      }
    }
  }
});
