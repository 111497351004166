import axios from "axios";
import Properties from "../properties";

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/poin-gifts/rekap-history-gifts";
const API_URL_PRODUCTION = "https://api.amigogroup.id/poin-gifts/v2/rekap-history-gifts";


export default class LaporanTukarServices {
  constructor() {}
  config = this.$session.get("jwt");

  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }

  static getLaporan = (body, token) =>
    axios.get(
      this.getURL() + "",
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
}
