import axios from "axios";
import Properties from "../properties"

const API_URL = "https://pelanggan." +Properties.DOMAIN_URL +"/api/v1";

export default class NotifikasiAPIService {
  constructor() {}
  static getAllNotifikasi = (token, limit, page) =>
    axios.get(API_URL + "/notifikasis/limit/" + limit + "?page=" + page, {
      headers: { Authorization: "Bearer " + token },
    });
  static getNotifikasi = (token, limit, page, kode) =>
    axios.get(
      API_URL +
        "/notifikasis/kode_promo/" +
        kode +
        "/limit/" +
        limit +
        "?page=" +
        page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  static editNotifikasi = (token, kode, notif) =>
    axios.post(API_URL + "/notifikasis/" + kode, notif, {
      "Content-Type": "multipart/form-data",
      headers: { Authorization: "Bearer " + token },
    });
  static sendNotifikasi = (token, kode, nik) =>
    axios.post(
      API_URL + "/notifikasis/send-notification/" + kode,
      {
        user_update: nik,
      },
      {
        headers: { Authorization: "Bearer " + token },
      }
    );

  static createNotifikasi = (token, notif) =>
    axios.post(API_URL + "/notifikasis", notif, {
      "Content-Type": "multipart/form-data",
      headers: { Authorization: "Bearer " + token },
    });
  static deleteNotifikasi = (token, kode, nik) =>
    axios.delete(
      API_URL + "/notifikasis/" + kode,
      {
        params: {
          user_update: nik,
        },
        headers: { Authorization: "Bearer " + token },
      }
    );

  static getConst = (token, kode) =>
  axios.get(
    API_URL + "/cosnt-notifs/kategori/" + kode,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );

  static editConstNotif = (token, kode, notif) =>
    axios.post(API_URL + "/cosnt-notifs/" + kode, notif, {
      "Content-Type": "multipart/form-data",
      headers: { Authorization: "Bearer " + token },
    });
}
