<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="5">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Form Ganti Password</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text ref="form">
            <v-text-field
              ref="password_lama"
              v-model="passwordLama"
              label="Password Lama"
              prepend-icon="mdi-account-key"
              type="password"
              :rules="[rules.password_lama]"
            />

            <v-text-field
              ref="password_baru"
              v-model="passwordBaru"
              label="Password Baru"
              prepend-icon="mdi-key-plus"
              type="password"
              :rules="[rules.password_baru]"
            />
            <v-text-field
              ref="password_konfirmasi"
              v-model="passwordKonfirmasi"
              label="Konfirmasi Password Baru"
              prepend-icon="mdi-shield-key"
              type="password"
              :rules="passwordMatcher"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="gantiPassword">Ganti Password</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from 'firebase';
require('firebase/auth');

import KaryawanAPIServices from "../services/KaryawanAPIServices";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {
      password_lama: (value) => !!value || "Masukkan password lama.",
      password_baru: (value) => !!value || "Masukkan password baru.",
    },

    dialog: false,

    passwordLama: "",
    passwordBaru: "",
    passwordKonfirmasi: "",

    editedIndex: -1,
  }),

  created() {
    this.initialize();
  },

  computed: {
    passwordMatcher() {
      return [
        () =>
          this.passwordBaru === this.passwordKonfirmasi ||
          "Password konfirmasi salah",
        (v) => !!v || "Masukkan password konfirmasi",
      ];
    },

    form() {
      return {
        password_lama: this.passwordLama,
        password_baru: this.passwordBaru,
        password_konfirmasi: () => (
          this.email === this.emailToMatch || "E-mail must match",
          (v) => !!v || "Confirmation E-mail is required"
        ),
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
    },
    gantiPassword() {
      if (this.validate) {
        this.$store.commit("showProgress", {
          message: "Proses ganti password",
        });
        var body = {};
        body.pin_lama = this.passwordLama;
        body.pin_baru = this.passwordBaru;
        KaryawanAPIServices.gantiPassword(this.token, this.sessionNIK, body)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Password berhasil diganti",
            });

            this.passwordLama = "";
            this.passwordBaru = "";
            this.passwordKonfirmasi = "";

             
            this.$router.replace({ path: "/" });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gagal memuat NIK",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
