import axios from "axios";
import Properties from "../properties";

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/poin-apis/setup-poin";
const API_URL_PRODUCTION = "https://api.amigogroup.id/poin-apis/v2/setup-poin";

export default class SetupPoinAPIService {
  constructor() {}
  config = this.$session.get("jwt");
  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }

  static nextSetup = (token) =>
    axios.get(this.getURL() + "/next", {
      Authorization: "Bearer " + token,
    });

  static current = (token) =>
    axios.get(this.getURL() + "/latest", {
      Authorization: "Bearer " + token,
    });

  static create = (token, setup) =>
    axios.post(this.getURL() + "/", setup, {
      Authorization: "Bearer " + token,
    });

  static delete = (token, id, nik) =>
    axios.delete(this.getURL() + "/" + id, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
