<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="promos"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-col cols="12" md="2"
            ><v-toolbar-title>PROMO KHUSUS</v-toolbar-title></v-col
          >

          <v-dialog v-model="dialog" max-width="1000px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-combobox
                    ref="kategori"
                    v-model="kategori"
                    :items="listK"
                    label="Kategori Promo"
                    :rules="[rules.kategori]"
                  ></v-combobox>
                  <v-text-field
                    ref="judul"
                    v-model="editedItem.judul"
                    label="Judul Promo"
                    counter="200"
                    :rules="[rules.judul, rules.counter_judul]"
                  ></v-text-field>
                  <p class="mt-5 mb-1 subtitle-1">Keterangan Promo</p>
                  <v-sheet elevation="6">
                    <ckeditor
                      :editor="editor"
                      v-model="editedItem.keterangan"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-sheet>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="periodeAwal"
                            v-model="editedItem.periode_awal"
                            label="Periode Awal"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            :rules="[rules.periodeAwal]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          header-color="primary"
                          color="secondary"
                          v-model="editedItem.periode_awal"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            ref="periodeAkhir"
                            v-model="editedItem.periode_akhir"
                            label="Periode Akhir"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                            :rules="[rules.periodeAkhir]"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          header-color="primary"
                          color="secondary"
                          v-model="editedItem.periode_akhir"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-combobox
                        ref="tipe_diskon"
                        v-model="tipeDiskon"
                        @change="changeTipe"
                        :items="['Persentase', 'Potongan Rupiah']"
                        label="Tipe Diskon"
                      ></v-combobox>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="nilai_diskon"
                        v-model="editedItem.nilai"
                        min="0"
                        :prefix="prefixNilai"
                        :suffix="suffixNilai"
                        label="Nilai diskon"
                        :rules="[rules.nilaiDiskon]"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        ref="minimal_belanja"
                        v-model="editedItem.minimal_belanja"
                        min="0"
                        prefix="Rp."
                        suffix=",00"
                        label="Minimal Belanja"
                        :rules="[rules.minimalBelanja]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        ref="limit_tukar"
                        v-model="editedItem.limit_tukar"
                        min="0"
                        label="Maksimal penukaran promo"
                        :rules="[rules.limit_tukar_null, rules.limit_tukar]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row justify="center" align="center">
                    <v-col cols="12" md="10">
                      <v-combobox
                        ref="toko"
                        :rules="[rules.toko]"
                        v-model="selectedDepartments"
                        :items="listDepartment"
                        label="Toko yang ikut Promo"
                        multiple
                      ></v-combobox>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn @click="selectAll" color="warn" class="mr-1"
                        >Pilih Semua</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto"
                        :rules="fotoValidator"
                        @change="onAddFiles"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Thumbnail (Landscape 16:9)"
                        prepend-icon="mdi-camera"
                      ></v-file-input>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-file-input
                        ref="foto_thumbnail"
                        :rules="fotoThumbnailValidator"
                        @change="onAddFilesThumbnail"
                        accept="image/png, image/jpeg, image/bmp, image/jpg"
                        label="Foto Thumbnail (Landscape 16:9)"
                        prepend-icon="mdi-camera"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <span class="title">Foto Thumbnail (Landscape 16:9)</span>
                      <v-img :key="keyFoto" :src="fotoURL" v-if="showFoto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                      <span class="title">Foto Thumbnail (Landscape 16:9)</span>
                      <v-img
                        :key="keyThumbnail"
                        :src="thumbnailURL"
                        :v-if="showThumbnail"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center" width="500px">
            <v-col cols="12" md="3">
              <v-combobox
                v-model="filterKategori"
                :items="listK"
                label="Kategori Promo"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="12" md="3">
              <v-combobox
                v-model="filterDepartment"
                :items="listDepartment"
                label="Toko"
                hide-details
                clearable="true"
              ></v-combobox>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="searchText"
                label="Judul Promo"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filterTahun"
                label="Tahun"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn small @click="deleteItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn>
      </template>
      <template v-slot:no-data>
        Silahkan melakukan pencarian promo khusus...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="searchPromo"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import PromoAPIServices from "../services/PromoAPIServices";
import KategoriPromoAPIServices from "../services/KategoriPromoAPIServices";
import BarangAPIServices from "../services/BarangAPIServices";
import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import firebase from 'firebase';
require('firebase/auth');

import { mapMutations } from "vuex";

export default {
  name: "PromoKhusus",

  data: () => ({
    rules: {
      kategori: (value) => !!value || "Pilih kategori.",
      judul: (value) => !!value || "Masukkan judul.",
      periodeAwal: (value) => !!value || "Masukkan periode awal.",
      periodeAkhir: (value) => !!value || "Masukkan periode akhir.",
      toko: (value) => value.length > 0 || "Masukkan toko.",
      limit_tukar_null: (value) =>
        !!value || "Masukkan jumalah maksimal penukaran.",
      limit_tukar: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor identitas hanya mengandung angka";
      },
      counter_judul: (value) => value.length <= 200 || "Judul terlalu panjang.",
    },

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    },

    token: "",
    filterTahun: "",
    departments: [],
    selectedDepartments: [],
    filterDepartment: "",
    listDepartment: [],

    kategoris: [],
    kategori: "",
    filterKategori: "",
    listK: [],

    menu1: false,
    menu2: false,
    prefixNilai: "",
    suffixNilai: "",

    searchText: "",
    dialog: false,

    tempFoto: null,
    tempThumbnail: null,
    showFoto: false,
    showThumbnail: false,
    fotoURL: "",
    thumbnailURL: "",
    keyFoto: 0,
    keyThumbnail: 0,

    headers: [
      {
        text: "Kode",
        align: "start",
        value: "kode",
        sortable: false,
      },
      { text: "Judul Promo", value: "judul", sortable: false },
      { text: "Periode Awal", value: "periode_awal", sortable: false },
      { text: "Periode Akhir", value: "periode_akhir", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    promos: [],
    editedIndex: -1,
    editedItem: {
      kode: "",
      judul: "",
      keterangan: "",
      periode_awal: "",
      periode_akhir: "",
      foto: "",
      foto_thumbnail: "",
      toko: [],
      minimal_belanja: 0,
      tipe: "",
      nilai: "",
      limit_tukar: "",
      highlight: false,
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      kode: "",
      judul: "",
      keterangan: "",
      periode_awal: "",
      periode_akhir: "",
      foto: "",
      foto_thumbnail: "",
      toko: [],
      minimal_belanja: 0,
      tipe: "",
      nilai: "",
      limit_tukar: "",
      highlight: false,
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    fotoValidator() {
      return [
        (this.tempFoto != null && this.tempFoto.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },
    fotoThumbnailValidator() {
      return [
        (this.tempThumbnail != null && this.tempThumbnail.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },

    formTitle() {
      return this.editedIndex === -1
        ? "Tambah Promo Khusus"
        : "Edit Promo Khusus";
    },
    formEdit() {
      return {
        kategori: this.kategori,
        judul: this.editedItem.judul,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        toko: this.editedItem.toko,
        limit_tukar: this.editedItem.limit_tukar,
      };
    },
    formTambah() {
      return {
        kategori: this.kategori,
        judul: this.editedItem.judul,
        periodeAwal: this.editedItem.periode_awal,
        periodeAkhir: this.editedItem.periode_akhir,
        foto: this.tempFoto,
        foto_thumbnail: this.tempThumbnail,
        toko: this.editedItem.toko,
        limit_tukar: this.editedItem.limit_tukar,
      };
    },

    validate() {
      var formHasErrors = false;

      if (this.formTitle == "Tambah Promo Khusus")
        Object.keys(this.formTambah).forEach((f) => {
          if (!this.formTambah[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });
      else
        Object.keys(this.formEdit).forEach((f) => {
          if (!this.formEdit[f]) formHasErrors = true;

          this.$refs[f].validate(true);
        });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data kategori promo",
      });
      await KategoriPromoAPIServices.getAllKhusus(this.token, 100, 1)
        .then((response) => {
          this.kategoris = response.data.data;
          this.kategoris.forEach((kategori) => {
            this.listK.push(kategori.nama);
          });
          this.resetForm();
          this.resetSearch();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kategori gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          this.departments = response.data.data;
          this.departments.forEach((department) => {
            if (department.id_department != "00") {
              this.listDepartment.push(department.department);
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    changeTipe() {
      this.suffixNilai = "";
      this.prefixNilai = "";
      if (this.tipeDiskon == "Persentase") {
        this.suffixNilai = "%";
      } else if (this.tipeDiskon == "Potongan Rupiah") {
        this.prefixNilai = "Rp.";
        this.suffixNilai = ",00";
      }
    },

    onAddFiles(e) {
      this.tempFoto = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.fotoURL = fr.result;
          this.showFoto = true;
        });
      } else {
        if (this.editedItem.foto != "") {
          this.fotoURL = this.editedItem.foto;
          this.showFoto = true;
        } else {
          this.fotoURL = "";
          this.showFoto = false;
          this.tempFoto = null;
        }
      }
    },

    onAddFilesThumbnail(e) {
      this.tempThumbnail = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.thumbnailURL = fr.result;
          this.showThumbnail = true;
        });
      } else {
        if (this.editedItem.foto_thumbnail != "") {
          this.thumbnailURL = this.editedItem.foto_thumbnail;
          this.showThumbnail = true;
        } else {
          this.thumbnailURL = "";
          this.showThumbnail = false;
          this.tempThumbnail = null;
        }
      }
    },

    selectAll() {
      this.selectedDepartments = this.listDepartment;
    },

    praSearch() {
      this.page = 1;
      this.searchPromo();
    },

    searchPromo() {
      this.promos = [];
      var body = {};
      this.kategoris.forEach((kategori) => {
        if (this.filterKategori == kategori.nama) {
          body.kode_kategori = kategori.kode;
        }
      });
      if (this.searchText != "") body.judul = this.searchText;
      this.departments.forEach((department) => {
        if (this.filterDepartment == department.department) {
          body.toko = department.id_department;
        }
      });
      if (this.filterTahun != "") body.tahun = this.filterTahun;
      body.khusus = 1;

      this.$store.commit("showProgress", {
        message: "Mengambil data promo khusus",
      });
      PromoAPIServices.getAllPromo(
        this.token,
        this.itemsPerPage,
        this.page,
        body
      )
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.promos = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Promo khusus gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    resetForm() {
      this.selectedDepartments = [];
    },

    resetSearch() {
      this.filterDepartment = "";
    },

    editItem(item) {
      this.editedIndex = this.promos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.fotoURL = item.foto;

      this.thumbnailURL = item.foto_thumbnail;
      this.showFoto = true;
      this.showThumbnail = true;

      this.kategoris.forEach((kategori) => {
        if (kategori.kode == this.editedItem.kode_kategori) {
          this.kategori = kategori.nama;
        }
      });

      this.editedItem.toko.forEach((toko) => {
        this.departments.forEach((department) => {
          if (toko.kode_toko == department.id_department) {
            this.selectedDepartments.push(department.department);
            return;
          }
        });
      });
      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Hapus Promo Khusus" + item.judul + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus promo khusus",
        }),
        PromoAPIServices.deletePromo(this.token, this.sessionNIK, item.kode)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Promo khusus berhasil di hapus",
            });
            this.searchPromo();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Promo khusus gagal di hapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      this.dialog = false;
      this.resetForm();
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.keyFoto++;
        this.keyThumbnail++;
        this.showFoto = false;
        this.showThumbnail = false;

        this.onAddFiles(null);
        this.onAddFilesThumbnail(null);

        Object.keys(this.formTambah).forEach((f) => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    save() {
      this.kategoris.forEach((kategori) => {
        if (this.kategori == kategori.nama) {
          this.editedItem.kode_kategori = kategori.kode;
        }
      });

      this.editedItem.toko = [];
      this.selectedDepartments.forEach((toko) => {
        this.departments.forEach((department) => {
          if (toko == department.department) {
            this.editedItem.toko.push(department.id_department);
            return;
          }
        });
      });
      let formData = new FormData();
      formData.append("kode_kategori", this.editedItem.kode_kategori);
      formData.append("judul", this.editedItem.judul);
      formData.append("keterangan", this.editedItem.keterangan);
      formData.append("periode_awal", this.editedItem.periode_awal);
      formData.append("periode_akhir", this.editedItem.periode_akhir);
      if (this.tempFoto != null) formData.append("foto", this.tempFoto);
      if (this.tempThumbnail != null)
        formData.append("thumbnail_foto", this.tempThumbnail);

      formData.append("limit_tukar", this.editedItem.limit_tukar);
      formData.append("highlight", 0);
      if (this.tipeDiskon == "Persentase") {
        formData.append("tipe", "P");
      } else if (this.tipeDiskon == "Potongan Rupiah") {
        formData.append("tipe", "R");
      }
      formData.append("nilai", this.editedItem.nilai);
      formData.append("minimal_belanja", this.editedItem.minimal_belanja);
      formData.append("user_create", this.sessionNIK);
      formData.append("user_update", this.sessionNIK);
      formData.append("toko", JSON.stringify(this.editedItem.toko));
      formData.append("is_share", 0);
      formData.append("link_share", "");

      if (this.editedItem.keterangan == "") {
        this.showSnackbar({
          color: "error",
          message: "Masukkan keterangan",
        });
        return;
      }

      if (this.validate) {
        if (this.editedItem.keterangan == "") {
          this.showSnackbar({
            color: "error",
            message: "Masukkan keterangan",
          });
          return;
        }
        if (this.formTitle == "Tambah Promo Khusus") {
          this.$store.commit("showProgress", {
            message: "Proses menambah promo khusus",
          });
          PromoAPIServices.createPromo(this.token, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah",
              });
              this.searchPromo();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.snackbarText = this.showSnackbar({
                  color: "error",
                  message: "Promo khusus gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        } else {
          this.$store.commit("showProgress", {
            message: "Proses mengedit promo khusus",
          });
          PromoAPIServices.editPromo(this.token, this.editedItem.kode, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.searchPromo();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Promo khusus gagal di muat",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
