<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="5" md="5">
        <v-card class="elevation-12" height="275">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Aturan Poin Saat Ini</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row
                ><p>Aturan dimulai sejak:</p>
                <v-spacer></v-spacer>
                <p>{{ currentSetup.tanggal_mulai }}</p></v-row
              >
              <v-row
                ><p>Harga Konversi (1 poin sama dengan):</p>
                <v-spacer></v-spacer>
                <p>Rp. {{ currentSetup.harga_konversi }}</p></v-row
              >
              <v-row
                ><p>Kadaluwarsa setelah:</p>
                <v-spacer></v-spacer>
                <p>{{ currentSetup.kadaluwarsa }} hari</p></v-row
              >
            </v-card-text>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" md="6">
        <v-card class="elevation-12" height="275">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Form Setup Poin</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    ref="harga_konversi"
                    v-model="harga_konversi"
                    label="Harga Konversi"
                    prepend-icon="mdi-ticket-percent"
                    type="text"
                    :rules="[rules.harga_konversi, harga_konversi_null]"
                  />
                </v-col>
                <v-col cols="4">
                  <!-- <v-text-field
                    ref="tanggal_mulai"
                    v-model="tanggal_mulai"
                    label="Aturan dimulai"
                    prepend-icon="mdi-ticket-percent"
                    type="text"
                    :rules="[rules.tanggal_mulai]"
                  /> -->
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="tanggal_mulai"
                        v-model="tanggal_mulai"
                        label="Tanggal mulai"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.tanggal_mulai]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="tanggal_mulai"
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    ref="kadaluwarsa"
                    v-model="kadaluwarsa"
                    label="Kadaluwarsa"
                    prepend-icon="mdi-ticket-percent"
                    type="text"
                    :rules="[rules.kadaluwarsa, kadaluwarsa_null]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer />
            <v-btn color="primary" :disabled="disableTukar" @click="simpan"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="setupPoins"
          hide-default-footer
          class="elevation-5"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              Setup Poin yang akan datang
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            Belum ada setup poin selanjutnya...
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              small
              @click="deleteItem(item)"
              width="70"
              color="error"
              class="mr-1"
            >
              <v-icon small>mdi-delete</v-icon> Hapus
            </v-btn>
            <!-- <v-btn small @click="hapusItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn> -->
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from 'firebase';
require('firebase/auth');

import SetupPoinAPIServices from "../../services/SetupPoinAPIServices";
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {
      tanggal_mulai: (value) => !!value || "Masukkan tanggal aturan dimulai.",
      kadaluwarsa_null: (value) => !!value || "Masukkan kadaluwarsa.",
      harga_konversi_null: (value) => !!value || "Masukkan kadaluwarsa.",
      harga_konversi: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Harga konversi hanya mengandung angka";
      },
      kadaluwarsa: (value) => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Kadaluwarsa hanya mengandung angka";
      },
    },

    harga_konversi: "",
    tanggal_mulai: "",
    kadaluwarsa: "",
    menu1: "",
    sessionNIK: "",

    headers: [
      {
        text: "Aturan Akan Dimulai Tanggal",
        align: "start",
        value: "tanggal_mulai",
        sortable: false,
      },
      {
        text: "Harga Konversi (1 poin sama dengan)",
        align: "start",
        value: "harga_konversi",
        sortable: false,
      },
      {
        text: "Poin Kadaluwarsa Setelah",
        align: "start",
        value: "kadaluwarsa",
        sortable: false,
      },
      {
        text: "Actions",
        align: "start",
        value: "actions",
        sortable: false,
      },
    ],
    setupPoins: [],
    currentSetup: {},
    itemsPerPage: 100,
  }),

  created() {
    this.initialize();
  },

  computed: {
    form() {
      return {
        harga_konversi: this.harga_konversi,
        tanggal_mulai: this.tanggal_mulai,
        kadaluwarsa: this.kadaluwarsa,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    formatHarga(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatTanggal(value) {
      return moment(String(value)).locale("id").format("DD MMM YYYY");
    },

    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.$store.commit("showProgress", {
        message: "Mengambil data setup poin terakhir",
      });
      SetupPoinAPIServices.current(this.token)
        .then((response) => {
          this.currentSetup = response.data;
          this.currentSetup.harga_konversi = this.formatHarga(
            this.currentSetup.harga_konversi
          );
          this.currentSetup.tanggal_mulai = this.formatTanggal(
            this.currentSetup.tanggal_mulai
          );
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Setup Poin gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.$store.commit("showProgress", {
        message: "Mengambil data setup poin",
      });
      SetupPoinAPIServices.nextSetup(this.token)
        .then((response) => {
          this.setupPoins = [];
          response.data.forEach((data) => {
            this.setupPoins.push(data);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Setup Poin gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    reset() {
      this.kadaluwarsa = "";
      this.tanggal_mulai = "";
      this.harga_konversi = "";
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
    },

    async simpan() {
      if (this.validate) {
        this.$store.commit("showProgress", {
          message: "Proses simpan Setup Poin",
        });
        var body = {};
        body.kadaluwarsa = this.kadaluwarsa;
        body.tanggal_mulai = this.tanggal_mulai;
        body.harga_konversi = this.harga_konversi;
        body.user_create = this.sessionNIK;
        await SetupPoinAPIServices.create(this.token, body)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Setup berhasil disimpan",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gagal menyimpan setup",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    deleteItem(item) {
      confirm("Hapus Setup Poin " + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus setup poin",
        }),
        SetupPoinAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Setup Poin berhasil di hapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Setup Poin gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
