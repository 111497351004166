<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="9">
        <v-card class="elevation-1">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title
              >Form Laporan Penukaran Promo Merchant</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAwal"
                        v-model="periode_awal"
                        label="Periode Awal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAwal]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_awal"
                      @input="
                        menu1 = false;
                        getMerchant();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAkhir"
                        v-model="periode_akhir"
                        label="Periode Akhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAkhir]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_akhir"
                      @input="
                        menu2 = false;
                        getMerchant();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-combobox
                    ref="merchant"
                    :rules="[rules.merchant]"
                    v-model="selectedMerchant"
                    :items="merchants"
                    label="Pilih Merchant"
                    item-text="nama"
                    @change="getPromo()"
                    multiple
                    :disabled="showTabel"
                  ></v-combobox>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-combobox
                    ref="penukaran_di"
                    :rules="[rules.penukaran_di]"
                    v-model="penukaran_di"
                    :items="['All', 'Amigo', 'Merchant']"
                    label="Pilih Tempat Penukaran"
                    @change="getPromo()"
                    :disabled="showTabel"
                  >
                  </v-combobox>
                </v-col>
              </v-row>

              <v-combobox
                ref="promo"
                :rules="[rules.promo]"
                v-model="selectedPromo"
                :items="promos"
                label="Pilih Promo"
                item-text="judul"
                multiple
                :disabled="showTabel"
              ></v-combobox>

              <v-row align="center">
                <v-col cols="10">
                  <v-combobox
                    ref="toko"
                    :rules="[rules.toko]"
                    v-model="selectedDepartments"
                    :items="departments"
                    label="Pilih Toko"
                    item-text="department"
                    multiple
                    return-object
                    :disabled="showTabel"
                  >
                    <!-- <template slot="selection" slot-scope="{ item }">
                      {{ item.departmet }}
                    </template> -->
                  </v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-btn @click="selectAll" :disabled="showTabel" color="warn"
                    >Semua</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer />
            <v-btn color="warn" @click="buat">Buat Laporan</v-btn>
            <v-btn color="primary" :disabled="disableTukar" @click="cetak"
              >Cetak PDF</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-show="showTabel">
      <v-col cols="12" md="12">
        <v-list v-if="unit.length != 0">
          <div class="text-h5 mt-5">Laporan Penukaran Promo Merchant</div>
          <v-list-item>Laporan dicetak pada: {{ fnow }}</v-list-item>
          <v-list-item
            >Periode Laporan Promo: {{ fperiode_awal }} s/d
            {{ fperiode_akhir }}</v-list-item
          >
          <!-- <v-list-item>Sub-total: {{ subTotals }}</v-list-item> -->
          <v-list-item v-for="item in unit" :key="item">
            <v-list-item-content>
              <v-data-table
                :headers="headers"
                hide-default-footer
                class="elevation-1"
                :items="item.items"
                :items-per-page="1000"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    Unit: {{ item.department }}
                    <v-spacer />
                    Total Rupiah: {{ item.t_rupiah }}
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Belum ada data penukaran promo...
                </template>
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="unit2.length != 0">
          <div class="text-h5 mt-5">Laporan Penukaran Promo Merchant</div>
          <v-list-item>Laporan dicetak pada: {{ fnow }}</v-list-item>
          <v-list-item
            >Periode Laporan Promo: {{ fperiode_awal }} s/d
            {{ fperiode_akhir }}</v-list-item
          >
          <v-list-item v-for="item in unit2" :key="item">
            <v-list-item-content>
              <v-data-table
                :headers="headers2"
                hide-default-footer
                class="elevation-1"
                :items="item.items"
                :items-per-page="1000"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    Unit: {{ item.department }}
                  </v-toolbar>
                </template>
                <template v-slot:no-data>
                  Belum ada data penukaran promo...
                </template>
              </v-data-table>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import BarangAPIServices from "../../services/BarangAPIServices";
import LaporanTukarMerchantServices from "../../services/LaporanTukarMerchantServices";
import firebase from "firebase";
require("firebase/auth");
import moment from "moment";

import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {
      periodeAwal: (value) => !!value || "Masukkan periode awal.",
      periodeAkhir: (value) => !!value || "Masukkan periode akhir.",
      toko: (value) => !!value || "Masukkan toko.",
      merchant: (value) => !!value || "Masukkan merchant.",
      promo: (value) => !!value || "Masukkan promo.",
      penukaran_di: (value) => !!value || "Masukkan tempat penukaran.",
    },
    headers: [
      {
        text: "No",
        align: "start",
        value: "index",
        sortable: false,
        title: "No",
        dataKey: "index",
      },
      {
        text: "Merchant",
        align: "start",
        value: "merchant",
        sortable: false,
        title: "Merchant",
        dataKey: "merchant",
      },
      {
        text: "Tanggal Transaksi",
        align: "start",
        value: "tgl_entry",
        sortable: false,
        title: "Tanggal Transaksi",
        dataKey: "tgl_entry",
      },
      {
        text: "Kode Promo",
        align: "start",
        value: "kode_promo",
        sortable: false,
        title: "Kode Promo",
        dataKey: "kode_promo",
      },
      {
        text: "Judul Promo",
        align: "start",
        value: "promo",
        sortable: false,
        title: "Judul Promo",
        dataKey: "promo",
      },
      {
        text: "Kode Pelanggan",
        align: "start",
        value: "pelanggan_id",
        sortable: false,
        title: "Kode Pelanggan",
        dataKey: "pelanggan_id",
      },
      {
        text: "Nama Pelanggan",
        align: "start",
        value: "nama_pelanggan",
        sortable: false,
        title: "Nama Pelanggan",
        dataKey: "nama_pelanggan",
      },
      {
        text: "No. Nota",
        align: "start",
        value: "nomor_nota",
        sortable: false,
        title: "No. Nota",
        dataKey: "nomor_nota",
      },
      {
        text: "Rupiah Voucher",
        align: "start",
        value: "rupiah",
        sortable: false,
        title: "Rupiah Voucher",
        dataKey: "rupiah",
      },
      {
        text: "Persentase",
        align: "start",
        value: "persentase",
        sortable: false,
        title: "Persentase",
        dataKey: "persentase",
      },
      {
        text: "Total Belanja (Nett dikurangi voucher)",
        align: "start",
        value: "total_belanja",
        sortable: false,
        title: "Total Belanja (Nett dikurangi voucher)",
        dataKey: "total_belanja",
      },
      {
        text: "User Admin",
        align: "start",
        value: "user_create",
        sortable: false,
        title: "User Admin",
        dataKey: "user_create",
      },
    ],

    headers2: [
      {
        text: "No",
        align: "start",
        value: "index",
        sortable: false,
        title: "No",
        dataKey: "index",
      },
      {
        text: "Merchant",
        align: "start",
        value: "merchant",
        sortable: false,
        title: "Merchant",
        dataKey: "merchant",
      },
      {
        text: "Tanggal Transaksi",
        align: "start",
        value: "tgl_entry",
        sortable: false,
        title: "Tanggal Transaksi",
        dataKey: "tgl_entry",
      },
      {
        text: "Kode Promo",
        align: "start",
        value: "kode_promo",
        sortable: false,
        title: "Kode Promo",
        dataKey: "kode_promo",
      },
      {
        text: "Judul Promo",
        align: "start",
        value: "promo",
        sortable: false,
        title: "Judul Promo",
        dataKey: "promo",
      },
      {
        text: "Kode Pelanggan",
        align: "start",
        value: "pelanggan_id",
        sortable: false,
        title: "Kode Pelanggan",
        dataKey: "pelanggan_id",
      },
      {
        text: "Nama Pelanggan",
        align: "start",
        value: "nama_pelanggan",
        sortable: false,
        title: "Nama Pelanggan",
        dataKey: "nama_pelanggan",
      },
    ],

    now: "",
    fnow: "",
    fperiode_awal: "",
    fperiode_akhir: "",

    token: "",
    sessionNik: "",
    showTabel: false,
    rekapAmigo: [],
    rekapMerchant: [],
    disableTukar: true,

    selectedDepartments: [],
    departments: [],

    selectedMerchant: [],
    merchants: [],

    selectedPromo: [],
    promos: [],

    penukaran_di: "",

    menu1: false,
    periode_awal: "",
    menu2: false,
    periode_akhir: "",
    unit: [],
    unit2: [],
  }),

  created() {
    this.initialize();
  },

  computed: {
    form() {
      return {};
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          response.data.data.forEach((toko) => {
            if (toko.id_department != "00") this.departments.push(toko);
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    selectAll() {
      this.selectedDepartments = this.departments;
    },

    async getMerchant() {
      if (this.periode_awal != "" && this.periode_akhir != "") {
        var body = {};
        body.periode_awal = this.periode_awal;
        body.periode_akhir = this.periode_akhir;

        this.$store.commit("showProgress", {
          message: "Mengambil data Merchant",
        });
        await LaporanTukarMerchantServices.getMerchant(body, this.token)
          .then((response) => {
            this.merchants = [];
            this.selectedMerchant = [];
            this.merchants = response.data;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
      this.promos = [];
      this.selectedPromo = [];
    },

    async getPromo() {
      if (
        this.periode_awal != "" &&
        this.periode_akhir != "" &&
        this.selectedMerchant != [] &&
        this.penukaran_di != ""
      ) {
        var body = {};
        body.periode_awal = this.periode_awal;
        body.periode_akhir = this.periode_akhir;
        body.id_merchant = [];
        this.selectedMerchant.forEach((merchant) => {
          body.id_merchant.push(merchant.id);
        });
        if (this.penukaran_di != "All") {
          if (this.penukaran_di == "Amigo") {
            body.penukaran_di = 1;
          } else if (this.penukaran_di == "Merchant") {
            body.penukaran_di = 0;
          }
        }

        this.$store.commit("showProgress", {
          message: "Mengambil data Promo",
        });
        await LaporanTukarMerchantServices.getPromo(body, this.token)
          .then((response) => {
            this.promos = [];
            this.selectedPromo = [];
            this.promos = response.data;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    async buat() {
      this.now = new Date().toLocaleString();
      this.fnow = moment(this.now).locale("id").format("DD MMMM yyyy");
      this.fperiode_awal = moment(this.periode_awal)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_akhir = moment(this.periode_akhir)
        .locale("id")
        .format("DD MMMM yyyy");
      if (
        this.periode_awal != "" &&
        this.periode_akhir != "" &&
        this.selectedMerchant != {} &&
        this.penukaran_di != "" &&
        this.selectedPromo != {}
      ) {
        var body = {};
        body.periode_awal = this.periode_awal;
        body.periode_akhir = this.periode_akhir;
        body.id_merchant = [];
        this.selectedMerchant.forEach((merchant) => {
          body.id_merchant.push(merchant.id);
        });
        if (this.penukaran_di != "All") {
          if (this.penukaran_di == "Amigo") {
            body.penukaran_di = 1;
          } else if (this.penukaran_di == "Merchant") {
            body.penukaran_di = 0;
          }
        }
        body.id_promo_merchant = [];
        this.selectedPromo.forEach((promo) => {
          body.id_promo_merchant.push(promo.id);
        });
        body.id_department = [];
        this.selectedDepartments.forEach((toko) => {
          body.id_department.push(toko.id_department);
        });
        this.$store.commit("showProgress", {
          message: "Mengambil data Promo",
        });
        await LaporanTukarMerchantServices.get(body, this.token)
          .then((response) => {
            this.unit = [];
            this.unit2 = [];
            response.data.rekap_amigo.forEach((toko) => {
              var tempToko = {};
              if (toko.id_department != "00") {
                tempToko.id_department = toko.id_department;
                tempToko.department = toko.department;
                let val = (toko.t_rupiah / 1).toFixed(2).replace(".", ",");
                tempToko.t_rupiah =
                  "Rp. " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                var tempItems = [];

                var counter = 0;
                toko.items.forEach((item) => {
                  counter++;
                  item.index = counter;

                  if (item.rupiah != "-") {
                    let val1 = (item.rupiah / 1).toFixed(2).replace(".", ",");
                    item.rupiah =
                      "Rp. " +
                      val1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                  }

                  let val2 = (item.total_belanja / 1)
                    .toFixed(2)
                    .replace(".", ",");
                  item.total_belanja =
                    "Rp. " +
                    val2.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                  if (item.persentase != "-") {
                    item.persentase = item.persentase + "%";
                  }
                  tempItems.push(item);
                });

                tempToko.items = tempItems;
                this.unit.push(tempToko);
              }
            });

            if (response.data.rekap_merchant.length > 0) {
              var tempToko = {};
              tempToko.id_department = "99";
              tempToko.department = "Merchant";
              var tempItems = [];
              var counter = 0;
              response.data.rekap_merchant.forEach((item) => {
                counter++;
                item.index = counter;
                tempItems.push(item);
              });

              tempToko.items = tempItems;
              this.unit2.push(tempToko);
            }

            this.showTabel = true;
            this.disableTukar = false;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    cetak() {
      var doc = new jsPDF("l", "pt", "a4");
      var counter = 0;

      if (this.unit.length > 0) {
        doc.setFontSize(14);
        doc.setFontType("bold");
        doc.text(25, 50, "Laporan Promo Merchant");
        doc.setFontSize(10);
        doc.setFontType("normal");

        doc.text(
          25,
          65,
          "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
        );

        var counterMerch = 0;
        var listMerch = "";
        var maxMerch = this.selectedMerchant.length;
        this.selectedMerchant.forEach((item) => {
          if (counterMerch < maxMerch - 1) listMerch += item.nama + ", ";
          else listMerch += item.nama;
          counterMerch++;
        });

        var maxPromo = this.selectedPromo.length;
        doc.text(25, 80, "List Merchant: " + listMerch);
        doc.text(25, 95, "Tempat Penukaran: " + this.penukaran_di);
        doc.text(25, 110, "Jumlah Promo yang dipilih: " + maxPromo);

        doc.setFontSize(12);
        this.unit.forEach((toko) => {
          doc.setFontType("bold");
          doc.text("\n" + toko.department, 40, 125);
          doc.setFontType("normal");

          doc.autoTable(this.headers, toko.items, { margin: { top: 140 } });
          counter++;
          if (counter != this.unit.length) {
            doc.addPage();
          }
        });
        if (this.unit2.length > 0) {
          doc.addPage();
        }
      }
      if (this.unit2.length > 0) {
        doc.setFontSize(14);
        doc.setFontType("bold");
        doc.text(25, 50, "Laporan Promo Merchant");
        doc.setFontSize(10);
        doc.setFontType("normal");

        doc.text(
          25,
          65,
          "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
        );

        doc.text(25, 80, "List Merchant: " + listMerch);
        doc.text(25, 95, "Tempat Penukaran: " + this.penukaran_di);
        doc.text(25, 110, "Jumlah Promo yang dipilih: " + maxPromo);

        doc.setFontSize(12);
        this.unit2.forEach((toko) => {
          doc.setFontType("bold");
          doc.text("\n" + toko.department, 40, 125);
          doc.setFontType("normal");

          doc.autoTable(this.headers, toko.items, { margin: { top: 140 } });
          counter++;
          if (counter != this.unit.length) {
            doc.addPage();
          }
        });
      }
      var pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica");
      doc.setFontType("bold");
      doc.setFontSize(9);
      doc.setTextColor(125, 125, 125);

      for (var i = 0; i < pageCount; i++) {
        doc.text(
          25,
          25,
          "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
        );

        doc.setPage(i);
        doc.text(
          25,
          575,
          "Halaman: " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " dari " +
            pageCount
        );
        doc.text(680, 575, "Dicetak pada: " + this.fnow);
      }
      doc.save("Laporan_Promo_Merchant_" + this.now + ".pdf");
    },

    reset() {
      this.selectedMerchant = [];
      this.selectedPromo = [];
      this.penukaran_di = "";
      this.periode_awal = "";
      this.periode_akhir = "";
      this.selectedDepartments = [];
      this.showTabel = false;
      this.disableTukar = true;
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
