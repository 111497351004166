import axios from "axios";
import Properties from "../properties";

const API_URL_DEVELOPMENT = "https://api.amigogroup.id/poin-gifts/restock-gifts";
const API_URL_PRODUCTION = "https://api.amigogroup.id/poin-gifts/v2/restock-gifts";

export default class RestockGiftPoinAPIService {
  constructor() {}
  config = this.$session.get("jwt");
  headers = {};

  static getURL = () => {
    return Properties.BUILD_TYPE == "production" ? API_URL_PRODUCTION : API_URL_DEVELOPMENT;
  }
  static create = (token, gift) =>
    axios.post(this.getURL() + "/", gift, {
      Authorization: "Bearer " + token,
    });
}
