<template>
  <v-container>
    <v-container>
      <v-container>
        <v-card class="elevation-3" height="700">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Merchant</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text>
              <p>Nama Merchant: {{ editedMerchant.nama }}</p>
              <p>Bidang Usaha: {{ editedMerchant.bidang_usaha }}</p>
              <p>Alamat: {{ editedMerchant.alamat + " " + inputKodePos }}</p>
              <p>Kodepos: {{ kodepos }}</p>
              <p>Nomor Telepon: {{ editedMerchant.no_telpon }}</p>
              <p>Nomor Handphone: {{ editedMerchant.no_ponsel }}</p>
              <v-row>
                <v-col cols="6">
                  <p>Logo:</p>
                  <v-img
                    :key="keyFoto"
                    :src="logoURL"
                    :v-if="showLogo"
                    width="400"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="6">
                  <p>Peta:</p>
                  <gmap-map
                    :center="center"
                    :zoom="12"
                    style="width: 100%; height: 300px"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      @click="center = m.position"
                    ></gmap-marker>
                  </gmap-map>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
        </v-card>
      </v-container>
      <v-row align="center" justify="center">
        <v-col>
          <v-container>
            <v-card class="elevation-3" height="100%">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Kontrak Saat ini</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-container>
                <v-card-text>
                  <p>
                    Periode Kontrak:
                    {{
                      currentKontrak.periode_awal +
                      " s/d " +
                      currentKontrak.periode_akhir
                    }}
                  </p>

                  <p>Fasilitas:</p>

                  <ckeditor
                    :editor="editor"
                    v-model="currentKontrak.fasilitas"
                    :config="editorConfig"
                  ></ckeditor>

                  <p>Pin: {{ currentKontrak.pin_merchant }}</p>
                  <v-btn color="green" @click="downloadCurrentMou"
                    >Download Mou</v-btn
                  >
                </v-card-text>
              </v-container>
            </v-card>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <v-data-table
              :headers="headers"
              :items="listKontrakMerchant"
              hide-default-footer
              class="elevation-5"
              :items-per-page="itemsPerPage"
              height="400"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title class="mr-2"
                    >Histori Kontrak Merchant</v-toolbar-title
                  >
                  <v-dialog
                    v-model="dialogKontrak"
                    max-width="1000px"
                    persistent
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" dark class="mb-2" v-on="on" small
                        ><v-icon class="white--text">mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">{{ judulKontrak }}</span>
                      </v-card-title>

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col>
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    ref="periode_awal"
                                    v-model="editedKontrak.periode_awal"
                                    label="Periode Awal"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-on="on"
                                    :rules="[rules.periode_awal]"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  header-color="primary"
                                  color="secondary"
                                  v-model="editedKontrak.periode_awal"
                                  @input="menu1 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>

                            <v-col>
                              <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    ref="periode_akhir"
                                    v-model="editedKontrak.periode_akhir"
                                    label="Periode Akhir"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-on="on"
                                    :rules="[rules.periode_akhir]"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  header-color="primary"
                                  color="secondary"
                                  v-model="editedKontrak.periode_akhir"
                                  @input="menu2 = false"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col>
                              <v-text-field
                                ref="pin_merchant"
                                v-model="editedKontrak.pin_merchant"
                                label="Pin"
                                counter="64"
                                :rules="[rules.pin_merchant]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <p class="mt-5 mb-1 subtitle-1">Fasilitas</p>
                          <v-sheet elevation="6">
                            <ckeditor
                              :editor="editor"
                              v-model="editedKontrak.fasilitas"
                              :config="editorConfig"
                            ></ckeditor>
                          </v-sheet>
                          <v-file-input
                            ref="mou"
                            :rules="mouValidator"
                            @change="onAddMou"
                            accept=".pdf"
                            label="File MOU"
                            prepend-icon="mdi-file"
                          ></v-file-input>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="textGrey" text @click="close"
                          >Batal</v-btn
                        >
                        <v-btn color="accent" text @click="saveKontrak"
                          >Simpan</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-divider vertical light class="mx-2"></v-divider>
                  <v-row align="center" width="500px"> </v-row>
                </v-toolbar>
              </template>

              <template v-slot:no-data> Belum ada histori... </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  small
                  @click="editKontrak(item)"
                  width="70"
                  color="warn"
                  class="mr-1"
                >
                  <v-icon small>mdi-pencil</v-icon> Edit
                </v-btn>
                <v-btn
                  small
                  @click="downloadMou(item)"
                  width="70"
                  color="green"
                  class="mr-1"
                >
                  <v-icon small>mdi-file</v-icon> MOU
                </v-btn>
                <v-btn
                  small
                  @click="deleteKontrak(item)"
                  width="70"
                  color="error"
                  class="mr-1"
                >
                  <v-icon small>mdi-delete</v-icon> Hapus
                </v-btn>
              </template>
            </v-data-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="12"> </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-data-table
        :headers="headersPromo"
        :items="historyPromo"
        hide-default-footer
        class="elevation-5"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title class="mr-2">Promo Merchant</v-toolbar-title>
            <v-dialog v-model="dialogPromo" max-width="1000px" persistent>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2" v-on="on" small
                  ><v-icon class="white--text">mdi-plus</v-icon></v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ judulPromo }}</span>
                </v-card-title>

                <v-card-text ref="form">
                  <v-container>
                    <v-combobox
                      v-model="filterKontrak"
                      :items="listKontrak"
                      label="Periode Kontrak"
                      hide-details
                      return-object
                      :item-text="
                        (item) =>
                          item.periode_awal + ' s/d ' + item.periode_akhir
                      "
                      clearable="true"
                    >
                      <template slot="selection" slot-scope="{ item }">
                        {{ item.periode_awal + " s/d " + item.periode_akhir }}
                      </template>
                    </v-combobox>
                    <v-combobox
                      ref="tukar_di_amigo"
                      v-model="editedPromo.tukar_di_amigo"
                      :items="['Amigo', 'Merchant']"
                      label="Penukaran di"
                      :rules="[rules.penukaran_di]"
                    ></v-combobox>
                    <v-switch
                      v-model="editedPromo.highlight"
                      inset
                      :label="`Tampilkan di Home`"
                    ></v-switch>
                    <v-text-field
                      ref="judul"
                      v-model="editedPromo.judul"
                      label="Judul Promo"
                      :rules="[rules.judul]"
                    ></v-text-field>
                    <p class="mt-5 mb-1 subtitle-1">Keterangan Promo</p>
                    <v-sheet elevation="6">
                      <ckeditor
                        :editor="editor"
                        v-model="editedPromo.keterangan"
                        :config="editorConfig"
                      ></ckeditor>
                    </v-sheet>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              ref="periode_awal_promo"
                              v-model="editedPromo.periode_awal"
                              label="Periode Awal"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :rules="[rules.periode_awal_promo]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            header-color="primary"
                            color="secondary"
                            v-model="editedPromo.periode_awal"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu4"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              ref="periode_akhir_promo"
                              v-model="editedPromo.periode_akhir"
                              label="Periode Akhir"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :rules="[rules.periode_akhir_promo]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            header-color="primary"
                            color="secondary"
                            v-model="editedPromo.periode_akhir"
                            @input="menu4 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          ref="limit_tukar"
                          v-model="editedPromo.limit_tukar"
                          min="0"
                          label="Maksimal penukaran promo"
                          :rules="[rules.limit_tukar_null, rules.limit_tukar]"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          ref="minimal_belanja"
                          v-model="editedPromo.minimal_belanja"
                          label="Minimal Belanja"
                          :rules="[
                            rules.minimal_belanja,
                            rules.minimal_belanja_null,
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-combobox
                          ref="tipe"
                          v-model="tipePromo"
                          @change="changeTipe"
                          :items="[
                            'Persentase',
                            'Potongan Rupiah'
                          ]"
                          label="Tipe Promo"
                          :rules="rules.tipe_promo"
                        ></v-combobox>
                      </v-col>
                      <v-col>
                        <v-text-field
                          ref="nilai"
                          v-model="editedPromo.nilai"
                          min="0"
                          :prefix="prefixNilai"
                          :suffix="suffixNilai"
                          label="Nilai"
                          :rules="[rules.nilai, rules.nilai_null]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                      <v-col cols="12" md="10">
                        <v-combobox
                          ref="toko"
                          v-model="selectedDepartments"
                          :items="listDepartment"
                          item-text="department"
                          label="Pilih Toko"
                          multiple
                          return-object
                          :disabled="showTabel"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn @click="selectAll" color="warn" class="mr-1"
                          >Pilih Semua</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" sm="6">
                        <v-file-input
                          ref="foto"
                          :rules="fotoValidator"
                          @change="onAddFiles"
                          accept="image/png, image/jpeg, image/bmp, image/jpg"
                          label="Foto Thumbnail (Landscape 16:9)"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="12" md="6" sm="6">
                        <v-file-input
                          ref="foto_thumbnail"
                          :rules="fotoThumbnailValidator"
                          @change="onAddFilesThumbnail"
                          accept="image/png, image/jpeg, image/bmp, image/jpg"
                          label="Foto Thumbnail (Landscape 16:9)"
                          prepend-icon="mdi-camera"
                        ></v-file-input>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <span class="title"
                          >Foto Thumbnail (Landscape 16:9)</span
                        >
                        <v-img :key="keyFoto" :src="fotoURL" v-if="showFoto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                      <v-col cols="12" md="6">
                        <span class="title"
                          >Foto Thumbnail (Landscape 16:9)</span
                        >
                        <v-img
                          :key="keyThumbnail"
                          :src="thumbnailURL"
                          :v-if="showThumbnail"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                    </v-row>
                    <v-switch
                      v-model="editedPromo.is_share"
                      inset
                      :label="`Share`"
                    ></v-switch>
                    <v-text-field
                      ref="link_share"
                      v-model="editedPromo.link_share"
                      label="Link Share"
                    ></v-text-field>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="textGrey" text @click="close">Batal</v-btn>
                  <v-btn color="accent" text @click="savePromo">Simpan</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-divider vertical light class="mx-2"></v-divider>
            <v-row align="center">
              <v-col>
                <v-combobox
                  v-model="filterKontrak"
                  :items="listKontrak"
                  label="Periode Kontrak"
                  hide-details
                  return-object
                  :item-text="
                    (item) => item.periode_awal + ' s/d ' + item.periode_akhir
                  "
                  clearable="true"
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.periode_awal + " s/d " + item.periode_akhir }}
                  </template>
                </v-combobox>
              </v-col>
              <v-col>
                <v-btn color="primary" @click="praSearchPromo"
                  ><v-icon>mdi-magnify</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:no-data> Belum ada promo... </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            @click="editPromo(item)"
            width="70"
            color="warn"
            class="mr-1"
          >
            <v-icon small>mdi-pencil</v-icon> Edit
          </v-btn>
          <v-btn
            small
            @click="deletePromo(item)"
            width="70"
            color="error"
            class="mr-1"
          >
            <v-icon small>mdi-delete</v-icon> Hapus
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
import BarangAPIServices from "../../services/BarangAPIServices";
import KodePosAPIService from "../../services/KodePosAPIServices";
import KontrakMerchantAPIServices from "../../services/KontrakMerchantAPIServices";
import PromoMerchantAPIServices from "../../services/PromoMerchantAPIServices";
import firebase from "firebase";
require("firebase/auth");

import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import { mapMutations } from "vuex";

export default {
  name: "SetupMerchant",

  data: () => ({
    rules: {
      periode_awal: (value) => !!value || "Masukkan tanggal mulai kontrak.",
      periode_akhir: (value) => !!value || "Masukkan tanggal akhir kontrak.",
      pin_merchant: (value) => !!value || "Masukkan pin.",

      periode_kontrak: (value) => !!value || "Pilih periode kontrak.",
      penukaran_di: (value) => !!value || "Pilih lokasi penukaran promo.",
      judul: (value) => !!value || "Masukkan Judul Promo.",
      periode_awal_promo: (value) => !!value || "Masukkan tanggal mulai promo.",
      periode_akhir_promo: (value) =>
        !!value || "Masukkan tanggal akhir promo.",
      limit_tukar_null: (value) =>
        !!value || "Masukkan jumalah maksimal penukaran",
      limit_tukar: (value) => {
        const pattern = /^[0-9]*$/;
        return (
          pattern.test(value) || "Maksimal penukaran hanya mengandung angka"
        );
      },
      minimal_belanja_null: (value) =>
        !!value || "Masukkan jumalah maksimal penukaran",
      minimal_belanja: (value) => {
        const pattern = /^[0-9]*$/;
        return (
          pattern.test(value) || "Maksimal penukaran hanya mengandung angka"
        );
      },
      nilai_null: (value) => !!value || "Masukkan jumalah maksimal penukaran",
      nilai: (value) => {
        const pattern = /^[0-9]*$/;
        return (
          pattern.test(value) || "Maksimal penukaran hanya mengandung angka"
        );
      },
    },

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    },

    sessionNIK: "",
    token: "",

    selectedDepartments: [],
    selectedKode: [],
    listDepartment: [],
    departments: [],

    listKontrakMerchant: [],

    listKontrak: [],
    filterKontrak: "",

    historyPromo: [],

    listKontrakForm: [],
    filterKontrakForm: "",

    inputKodePos: "",
    listKodePos: [],
    kodepos: "",

    center: { lat: 7.74, lng: 110.6646 },
    markers: [],
    places: [],
    currentPlace: null,
    selectedPlace: "",

    selectedKontrak: "",
    currentKontrak: "",

    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    prefixNilai: "",
    suffixNilai: "",

    tempMou: null,
    keyMou: 0,

    tempLogo: null,
    keyLogo: 0,
    logoURL: "",
    showLogo: false,

    tempFoto: null,
    tempThumbnail: null,
    showFoto: false,
    showThumbnail: false,
    fotoURL: "",
    thumbnailURL: "",
    keyFoto: 0,
    keyThumbnail: 0,

    dialogKontrak: false,
    dialogPromo: false,

    headers: [
      {
        text: "Periode Awal",
        align: "start",
        value: "periode_awal",
        sortable: false,
      },
      { text: "Periode Akhir", value: "periode_akhir", sortable: false },
      // { text: "Fasilitas", value: "fasilitas", sortable: false },
      { text: "Pin", value: "pin", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],

    headersPromo: [
      {
        text: "Kode",
        align: "start",
        value: "kode",
        sortable: false,
      },
      { text: "Judul Promo", value: "judul", sortable: false },
      { text: "Periode Awal", value: "periode_awal", sortable: false },
      { text: "Periode Akhir", value: "periode_akhir", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],

    editedIndexMerchant: -1,
    editedMerchant: {
      id_kodepos: "",
      nama: "",
      alamat: "",
      no_telpon: "",
      no_ponsel: "",
      logo: "",
      longitude: "",
      latitude: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },

    editedIndexKontrak: -1,
    editedKontrak: {
      id_merchant: "",
      periode_awal: "",
      periode_akhir: "",
      pin_merchant: "",
      fasilitas: "",
      mou: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },
    defaultKontrak: {
      id_merchant: "",
      periode_awal: "",
      periode_akhir: "",
      pin_merchant: "",
      fasilitas: "",
      mou: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },

    editedIndexPromo: -1,
    editedPromo: {
      foto: "",
      foto_thumbnail: "",
      highlight: "",
      is_share: "",
      judul: "",
      keterangan: "",
      kode: "",
      limit_tukar: "",
      link_share: "",
      minimal_belanja: "",
      nilai: "",
      periode_awal: "",
      periode_akhir: "",
      tipe: "",
      tukar_di_amigo: "",
    },
    defaultPromo: {
      foto: "",
      foto_thumbnail: "",
      highlight: "",
      is_share: "",
      judul: "",
      keterangan: "",
      kode: "",
      limit_tukar: "",
      link_share: "",
      minimal_belanja: "",
      nilai: "",
      periode_awal: "",
      periode_akhir: "",
      tipe: "",
      tukar_di_amigo: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    mouValidator() {
      return [
        (this.tempMou != null && this.tempMou.size < 100000000) ||
          "MOU tidak boleh kosong, ukuran max 100 MB",
      ];
    },

    fotoValidator() {
      return [
        (this.tempFoto != null && this.tempFoto.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },

    fotoThumbnailValidator() {
      return [
        (this.tempThumbnail != null && this.tempThumbnail.size < 1000000) ||
          "Foto tidak boleh kosong, ukuran max 1 MB",
      ];
    },

    judulKontrak() {
      return this.editedIndexKontrak === -1 ? "Tambah Kontrak" : "Edit Kontrak";
    },

    judulPromo() {
      return this.editedIndexPromo === -1 ? "Tambah Promo" : "Edit Promo";
    },

    formKontrak() {
      return {
        periode_awal: this.editedKontrak.periode_awal,
        periode_akhir: this.editedKontrak.periode_akhir,
        pin_merchant: this.editedKontrak.pin_merchant,
        // mou: this.tempMou,
      };
    },

    formEditPromo() {
      return {
        periode_kontrak: this.selectedKontrak,
        penukaran_di: this.editedPromo.penukaran_di_amigo,
        judul: this.editedPromo.judul,
        periode_awal_promo: this.editedPromo.periode_awal,
        periode_akhir_promo: this.editedPromo.periode_akhir,
        minimal_belanja: this.editedPromo.minimal_belanja,
        tipe: this.tipePromo,
        nilai: this.editedPromo.nilai,
        limit_tukar: this.editedPromo.limit_tukar,
        toko: [],
      };
    },
    formTambahPromo() {
      return {
        periode_kontrak: this.selectedKontrak,
        penukaran_di: this.editedPromo.penukaran_di_amigo,
        judul: this.editedPromo.judul,
        periode_awal_promo: this.editedPromo.periode_awal,
        periode_akhir_promo: this.editedPromo.periode_akhir,
        minimal_belanja: this.editedPromo.minimal_belanja,
        tipe: this.tipePromo,
        nilai: this.editedPromo.nilai,
        limit_tukar: this.editedPromo.limit_tukar,
        toko: [],
        foto: this.tempFoto,
        foto_thumbnail: this.tempThumbnail,
      };
    },

    validateKontrak() {
      var formHasErrors = false;

      // if (this.formTitle == "Tambah Kontrak")
      Object.keys(this.formKontrak).forEach((f) => {
        if (!this.formKontrak[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });
      // else
      // Object.keys(this.formEditKontrak).forEach((f) => {
      //   if (!this.formEditKontrak[f]) formHasErrors = true;

      //   this.$refs[f].validateKontrak(true);
      // });

      return !formHasErrors;
    },
  },

  methods: {
    selectAll() {
      this.selectedDepartments = [];
      this.selectedDepartments = this.listDepartment;
    },

    onAddMou(e) {
      this.tempMou = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.mouURL = fr.result;
        });
      } else {
        this.tempMou = null;
      }
    },

    praSearchPromo() {
      this.page = 1;
      this.searchPromo();
    },

    searchPromo() {
      this.historyPromo = [];
      var body = {};
      body.id_kontrak_merchant = this.filterKontrak.id;
      this.$store.commit("showProgress", {
        message: "Mengambil data promo",
      });
      PromoMerchantAPIServices.getList(
        this.token,
        this.itemsPerPage,
        this.page,
        body
      )
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.historyPromo = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Promo gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    changeTipe() {
      this.suffixNilai = "";
      this.prefixNilai = "";
      if (this.tipePromo == "Persentase") {
        this.suffixNilai = "%";
        this.editedPromo.tipe = "P";
      } else if (this.tipePromo == "Potongan Rupiah") {
        this.prefixNilai = "Rp.";
        this.suffixNilai = ",00";
        this.editedPromo.tipe = "R";
      } else if (this.tipePromo == "Gift") {
        this.prefixNilai = "Rp.";
        this.suffixNilai = ",00";
        this.editedPromo.tipe = "G";
      } else if (this.tipePromo == "Double Poin") {
        this.prefixNilai = "";
        this.suffixNilai = "";
        this.editedPromo.tipe = "D";
      } else if (this.tipePromo == "Cashback") {
        this.prefixNilai = "Rp.";
        this.suffixNilai = ",00";
        this.editedPromo.tipe = "C";
      }
    },

    async reloadPromo() {
      if (this.filterKontrak != "") {
        this.searchPromo();
      }
    },

    async reloadKontrak() {
      //paginate
      this.$store.commit("showProgress", {
        message: "Mengambil data kontrak merchant",
      });

      var body = {};
      body.id_merchant = this.editedMerchant.id;
      // tambah limit dan page
      await KontrakMerchantAPIServices.getList(this.token, body)
        .then((response) => {
          this.listKontrakMerchant = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Daftar Kontrak gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      // non paginate
      this.$store.commit("showProgress", {
        message: "Mengambil data kontrak merchant",
      });

      var body2 = {};
      body2.id_merchant = this.$store.state.merchant.id;
      // tambah limit dan page
      await KontrakMerchantAPIServices.getList(this.token, body2)
        .then((response) => {
          this.listKontrak = response.data;
          this.listKontrakForm = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Daftar Kontrak gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.$store.commit("showProgress", {
        message: "Mengambil data toko",
      });

      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then((response) => {
          this.departments = [];
          this.listDepartment = [];
          this.listFilterDepartment = [];
          this.departments = response.data.data;
          this.departments.forEach((department) => {
            if (department.id_department != "00") {
              this.listDepartment.push(department.department);
              this.listFilterDepartment.push(department);
            }
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.editedMerchant = Object.assign({}, this.$store.state.merchant);
      this.editedIndexMerchant = this.$store.idxMerchant;

      if (
        this.editedMerchant.id_kodepos != null ||
        this.editedMerchant.id_kodepos != "" ||
        this.editedMerchant.kodepos != undefined
      ) {
        this.$store.commit("showProgress", {
          message: "Proses mengambil data merchant",
        });
        await KodePosAPIService.getByID(
          this.token,
          this.editedMerchant.id_kodepos
        )
          .then((response) => {
            var kodepos = response.data;
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
            this.kodepos = kodepos.kodepos;
            this.inputKodePos = kodepos.text;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kodepos gagal dimuat",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
      this.geolocate();

      this.logoURL = "https://" + this.editedMerchant.logo;
      this.showLogo = true;
      this.reloadKontrak();
      this.latestKontrak();
      // this.praSearch();
    },

    async latestKontrak() {
      this.$store.commit("showProgress", {
        message: "Proses mengambil data kontrak merchant",
      });
      await KontrakMerchantAPIServices.current(this.token, {
        id_merchant: this.$store.state.merchant.id,
      })
        .then((response) => {
          this.currentKontrak = response.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kodepos gagal dimuat",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    downloadMou(item) {
      window.open("https://" + item.mou, "_blank");
    },

    downloadCurrentMou() {
      this.downloadMou(this.currentKontrak);
    },

    geolocate: function () {
      if (
        (this.editedMerchant.latitude == "" &&
          this.editedMerchant.longitude == "") ||
        (this.editedMerchant.latitude == undefined &&
          this.editedMerchant.longitude == undefined) ||
        (this.editedMerchant.latitude == null &&
          this.editedMerchant.longitude == null)
      )
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        });
      else {
        const marker = {
          lat: this.editedMerchant.latitude,
          lng: this.editedMerchant.longitude,
        };
        this.markers.push({ position: marker });
        this.center = marker;
      }
    },

    onAddFiles(e) {
      this.tempFoto = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.fotoURL = fr.result;
          this.showFoto = true;
          this.editedPromo.logo = this.tempFoto;
        });
      } else {
        if (this.editedPromo.foto != "") {
          this.fotoURL = "http://" + this.editedPromo.foto;
          this.showFoto = true;
        } else {
          this.fotoURL = "";
          this.showFoto = false;
          this.tempFoto = null;
        }
      }
    },

    onAddFilesThumbnail(e) {
      this.tempThumbnail = e;
      if (e != null) {
        const fr = new FileReader();

        fr.readAsDataURL(e);

        fr.addEventListener("load", () => {
          this.thumbnailURL = fr.result;
          this.showThumbnail = true;
          this.editedPromo.foto_thumbnail = this.tempThumbnail;
        });
      } else {
        if (this.editedPromo.foto_thumbnail != "") {
          this.thumbnailURL = "http://" + this.editedPromo.foto_thumbnail;
          this.showThumbnail = true;
        } else {
          this.thumbnailURL = "";
          this.showThumbnail = false;
          this.tempThumbnail = null;
        }
      }
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    tambahMerchant() {},

    search() {},

    editKontrak(item) {
      this.editedIndexKontrak = this.listKontrakMerchant.indexOf(item);
      this.editedKontrak = Object.assign({}, item);

      this.dialogKontrak = true;
    },

    editPromo(item) {
      this.editedIndexPromo = this.historyPromo.indexOf(item);
      this.editedPromo = Object.assign({}, item);
      this.listKontrakForm.forEach((kontrak) => {
        if (item.id == this.filterKontrakForm.id_kontrak_merchant) {
          this.filterKontrakForm = kontrak;
        }
      });

      if (item.tipe == "P") {
        this.tipePromo = "Persentase";
      } else if (item.tipe == "R") {
        this.tipePromo = "Potongan Rupiah";
      } else if (item.tipe == "G") {
        this.tipePromo = "Gift";
      } else if (item.tipe == "D") {
        this.tipePromo = "Poin Double";
      } else if (item.tipe == "C") {
        this.tipePromo = "Cashback";
      }

      if (item.tukar_di_amigo == "1") {
        this.editedPromo.tukar_di_amigo = "Amigo";
      } else if (item.tukar_di_amigo == "0") {
        this.editedPromo.tukar_di_amigo = "Merchant";
      }

      this.changeTipe();

      this.selectedDepartments = [];
      this.editedPromo.promo_merchant_toko.forEach((toko) => {
        this.departments.forEach((department) => {
          if (toko.departments.id_department == department.id_department) {
            this.selectedDepartments.push(department.department);
            return;
          }
        });
      });

      this.fotoURL = this.editedPromo.foto;
      this.thumbnailURL = this.editedPromo.foto_thumbnail;
      this.showFoto = true;
      this.showThumbnail = true;
      this.keyFoto++;
      this.keyThumbnail++;

      this.dialogPromo = true;
    },

    deleteKontrak(item) {
      confirm(
        "Hapus kontrak " +
          item.periode_awal +
          " s/d " +
          item.periode_akhir +
          "?"
      ) &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus Kontrak",
        }),
        KontrakMerchantAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Kontrak berhasil dihapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kontrak gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
            this.reloadKontrak();
          }));
    },

    deletePromo(item) {
      confirm("Hapus Promo " + item.judul + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus Promo",
        }),
        PromoMerchantAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Promo berhasil dihapus",
            });
            this.reloadPromo();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Promo gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
            this.reloadPromo();
          }));
    },

    close() {
      this.dialogKontrak = false;
      this.dialogPromo = false;

      this.editedKontrak = Object.assign({}, this.defaultKontrak);
      this.editedIndexKontrak = -1;
      this.keyMou++;
      this.onAddMou(null);

      this.editedPromo = Object.assign({}, this.defaultPromo);
      this.editedIndexPromo = -1;

      this.tipePromo = "";
      this.selectedDepartments = [];
      this.keyFoto++;
      this.keyThumbnail++;
      this.showFoto = false;
      this.showThumbnail = false;
      this.onAddFiles(null);
      this.onAddFilesThumbnail(null);

      setTimeout(() => {
        Object.keys(this.formKontrak).forEach((f) => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    savePromo() {
      // if (this.validatePromo) {
      this.editedPromo.toko = [];
      this.selectedDepartments.forEach((toko) => {
        this.departments.forEach((department) => {
          if (toko == department.department) {
            this.editedPromo.toko.push(department.id_department);
            return;
          }
        });
      });
      this.changeTipe();

      if (this.judulPromo == "Tambah Promo") {
        this.$store.commit("showProgress", {
          message: "Proses menambah Promo Merchant",
        });
        this.editedPromo.user_create = this.sessionNIK;
        this.editedMerchant.id_kontrak_merchant = this.filterKontrak.id;

        let formData = new FormData();

        formData.append("toko", JSON.stringify(this.editedPromo.toko));
        formData.append("foto", this.tempFoto);
        formData.append("foto_thumbnail", this.tempThumbnail);
        if (this.editedPromo.highlight) formData.append("highlight", 1);
        else formData.append("highlight", 0);
        if (this.editedPromo.is_share) formData.append("is_share", 1);
        else formData.append("is_share", 0);
        formData.append("judul", this.editedPromo.judul);
        formData.append("keterangan", this.editedPromo.keterangan);
        formData.append("kode", this.editedPromo.kode);
        formData.append("limit_tukar", this.editedPromo.limit_tukar);
        formData.append("link_share", this.editedPromo.link_share);
        formData.append("minimal_belanja", this.editedPromo.minimal_belanja);
        formData.append("nilai", this.editedPromo.nilai);
        formData.append("periode_awal", this.editedPromo.periode_awal);
        formData.append("periode_akhir", this.editedPromo.periode_akhir);

        formData.append("tipe", this.editedPromo.tipe);
        if (this.editedPromo.tukar_di_amigo == "Amigo")
          formData.append("tukar_di_amigo", 1);
        else formData.append("tukar_di_amigo", 0);
        formData.append("id_kontrak_merchant", this.filterKontrak.id);
        formData.append("user_create", this.sessionNIK);

        PromoMerchantAPIServices.create(this.token, formData)
          .then(() => {
            this.close();
            this.showSnackbar({
              color: "secondary",
              message: "Data berhasil di simpan",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Promo Merchant gagal di simpan",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
            // reload kontrak
            this.reloadPromo();
          });
      } else {
        this.$store.commit("showProgress", {
          message: "Proses mengedit Promo Merchant",
        });

        this.editedPromo.user_update = this.sessionNIK;
        this.editedMerchant.id_kontrak_merchant = this.filterKontrak.id;

        let formData = new FormData();

        formData.append("toko", JSON.stringify(this.editedPromo.toko));
        formData.append("foto", this.tempFoto);
        formData.append("foto_thumbnail", this.tempThumbnail);
        if (this.editedPromo.highlight) formData.append("highlight", 1);
        else formData.append("highlight", 0);
        if (this.editedPromo.is_share) formData.append("is_share", 1);
        else formData.append("is_share", 0);
        formData.append("judul", this.editedPromo.judul);
        formData.append("keterangan", this.editedPromo.keterangan);
        formData.append("kode", this.editedPromo.kode);
        formData.append("limit_tukar", this.editedPromo.limit_tukar);
        formData.append("link_share", this.editedPromo.link_share);
        formData.append("minimal_belanja", this.editedPromo.minimal_belanja);
        formData.append("nilai", this.editedPromo.nilai);
        formData.append("periode_awal", this.editedPromo.periode_awal);
        formData.append("periode_akhir", this.editedPromo.periode_akhir);

        formData.append("tipe", this.editedPromo.tipe);
        if (this.editedPromo.tukar_di_amigo == "Amigo")
          formData.append("tukar_di_amigo", 1);
        else formData.append("tukar_di_amigo", 0);
        formData.append("id_kontrak_merchant", this.filterKontrak.id);
        formData.append("user_update", this.sessionNIK);

        PromoMerchantAPIServices.edit(this.token, this.editedPromo.id, formData)
          .then(() => {
            this.close();
            this.showSnackbar({
              color: "secondary",
              message: "Data berhasil di edit",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kontrak Merchant gagal di edit",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
            // reload kontrak
            this.reloadPromo();
          });
      }
      // } else {
      //   this.showSnackbar({
      //     color: "error",
      //     message: "Input tidak valid",
      //   });
      // }
    },

    saveKontrak() {
      if (this.validateKontrak) {
        if (this.judulKontrak == "Tambah Kontrak") {
          this.$store.commit("showProgress", {
            message: "Proses menambah Kontrak Merchant",
          });
          this.editedKontrak.user_create = this.sessionNIK;

          let formData = new FormData();
          formData.append("id_merchant", this.$store.state.merchant.id);
          formData.append("fasilitas", this.editedKontrak.fasilitas);
          formData.append("periode_awal", this.editedKontrak.periode_awal);
          formData.append("periode_akhir", this.editedKontrak.periode_akhir);
          formData.append("mou", this.tempMou);
          formData.append("pin_merchant", this.editedKontrak.pin_merchant);
          formData.append("user_create", this.editedKontrak.user_create);

          KontrakMerchantAPIServices.create(this.token, formData)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di simpan",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kontrak Merchant gagal di simpan",
                });
              }
              this.snackbar = true;
              this.snackbarColor = "error";
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              // reload kontrak
              this.reloadKontrak();
            });
        } else {
          this.$store.commit("showProgress", {
            message: "Proses mengedit Kontrak Merchant",
          });
          this.editedKontrak.user_update = this.sessionNIK;
          let formData = new FormData();

          formData.append("id", this.editedKontrak.id);
          formData.append("id_merchant", this.$store.state.merchant.id);
          formData.append("fasilitas", this.editedKontrak.fasilitas);
          formData.append("periode_awal", this.editedKontrak.periode_awal);
          formData.append("periode_akhir", this.editedKontrak.periode_akhir);
          formData.append("mou", this.tempMou);
          formData.append("pin_merchant", this.editedKontrak.pin_merchant);
          formData.append("user_update", this.editedKontrak.user_update);

          KontrakMerchantAPIServices.edit(
            this.token,
            this.editedKontrak.id,
            formData
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Kontrak Merchant gagal di edit",
                });
              }
              this.snackbar = true;
              this.snackbarColor = "error";
            })
            .finally(() => {
              this.$store.commit("closeProgress");
              // reload kontrak
              this.reloadKontrak();
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
