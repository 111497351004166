<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="members"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row align="center">
            <v-toolbar-title class="mx-2">Member</v-toolbar-title>
            <v-dialog v-model="dialog" max-width="1000px">
              <!-- <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template> -->
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text ref="form">
                  <v-container>
                    <!-- <v-btn
                    class="mb-2"
                    v-if="!editedItem.alias"
                    color="warn"
                    @click="isiAlias"
                    >Generate Kode</v-btn
                  > -->
                    <v-combobox
                      v-show="formTitle == 'Tambah Member'"
                      ref="kodeToko"
                      v-model="department"
                      :items="listDepartment"
                      label="Toko Cabang"
                      :rules="[rules.kodeToko]"
                    ></v-combobox>
                    <v-text-field
                      ref="nama"
                      v-model="editedItem.nama"
                      label="Nama"
                      counter="100"
                      :rules="[rules.nama, rules.counter_nama]"
                    ></v-text-field>
                    <v-text-field
                      ref="ktp"
                      v-model="editedItem.ktp"
                      label="KTP/ SIM/ KARTU PELAJAR"
                      counter="16"
                      :rules="[rules.ktpAngka, rules.ktp, rules.counter_ktp]"
                    ></v-text-field>
                    <v-textarea
                      ref="alamat"
                      v-model="editedItem.alamat"
                      label="Keterangan Alamat"
                      placeholder="Nomor rumah, Nama jalan, RT/RW, Dukuh, Desa"
                      auto-grow
                      rows="1"
                      counter="100"
                      :rules="[rules.alamat, rules.counter_alamat]"
                    ></v-textarea>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-combobox
                          ref="kodepos"
                          v-model="inputKodePos"
                          :items="listKodePos"
                          label="Pencarian kodepos"
                          placeholder="Kelurahan/ Kecamatan/ Kabupaten/ Provinsi"
                          @input="selectKodePos"
                          @keyup.enter="searchKodePos"
                          :rules="[rules.kodepos]"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          disabled="true"
                          v-model="kodepos"
                          label="Kodepos"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          v-model="editedItem.tempat_lahir"
                          label="Tempat Lahir"
                          counter="50"
                          :rules="[rules.counter_tempat]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="6">
                        <v-menu
                          v-model="menu2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              ref="tanggalLahir"
                              v-model="editedItem.tgl_lahir"
                              label="Tanggal Lahir"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-on="on"
                              :rules="[rules.tanggalLahir]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            header-color="primary"
                            color="secondary"
                            v-model="editedItem.tgl_lahir"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-combobox
                          ref="jenisKelamin"
                          v-model="jenisKelamin"
                          :items="jenisKelamins"
                          label="Jenis Kelamin"
                          :rules="[rules.jenisKelamin]"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-combobox
                          v-model="agama"
                          :items="listAgama"
                          label="Agama"
                        ></v-combobox>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-combobox
                          v-model="pekerjaan"
                          :items="listPekerjaan"
                          label="Pekerjaan"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          ref="ponsel"
                          v-model="editedItem.ponsel"
                          label="Nomor HP"
                          counter="16"
                          :rules="[
                            rules.ponselAngka,
                            rules.ponsel,
                            rules.counter_nomor
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          ref="email"
                          v-model="editedItem.email"
                          label="Email"
                          counter="64"
                          :rules="[
                            rules.email,
                            rules.emailNull,
                            ,
                            rules.counter_email
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="textGrey" text @click="close">Batal</v-btn>
                  <v-btn color="accent" text @click="save">Simpan</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider vertical light class="mx-2"></v-divider>

            <v-col cols="2">
              <v-combobox
                v-model="pencarianTipe"
                :items="['Umum', 'Ulang Tahun']"
                label="Tipe Pencarian"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="3" v-if="tipePencarian">
              <v-text-field
                prepend-icon="mdi-search"
                v-model="searchText"
                label="Pencarian Member"
                append-icon="mdi-magnify"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="2" v-if="tipePencarian">
              <v-combobox
                v-model="urutBerdasar"
                :items="urutBerdasars"
                label="Urut berdasarkan"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="2" v-if="tipePencarian">
              <v-combobox
                v-model="urutan"
                :items="urutans"
                label=""
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="3" v-if="!tipePencarian">
              <v-combobox
                ref="bulanUltah"
                v-model="bulanUltah"
                :items="bulan"
                label="Bulan Ulang tahun"
                hide-details
              ></v-combobox>
            </v-col>
            <v-col cols="3" v-if="!tipePencarian">
              <v-text-field
                ref="tanggalUltah"
                v-model="tanggalUltah"
                label="Tanggal Ulang Tahun"
                type="number"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row
            ><v-col>
              <v-combobox
                ref="limit"
                v-model="itemsPerPage"
                :items="[10, 50, 100]"
                label="Per Halaman"
                hide-details
              ></v-combobox>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>

      <!-- <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row align="center" justify="center">
            <v-col cols="1">
              <v-toolbar-title>
                MEMBER
              </v-toolbar-title>
            </v-col>
            <v-col cols="2">
              <v-combobox
                width
                v-model="pencarianTipe"
                :items="['Umum', 'Ulang Tahun']"
                label="Tipe Pencarian"
              ></v-combobox>
            </v-col>
            <v-col cols="9">
              <v-row ref="formUltah">
                <v-col v-if="tipePencarian">
                  <v-text-field
                    prepend-icon="mdi-search"
                    v-model="searchText"
                    label="Pencarian Member"
                    append-icon="mdi-magnify"
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col v-if="tipePencarian">
                  <v-combobox
                    v-model="urutBerdasar"
                    :items="urutBerdasars"
                    label="Urut berdasarkan"
                  ></v-combobox>
                </v-col>
                <v-col v-if="tipePencarian">
                  <v-combobox
                    v-model="urutan"
                    :items="urutans"
                    label=""
                  ></v-combobox>
                </v-col>
                <v-col v-if="!tipePencarian">
                  <v-combobox
                    ref="bulanUltah"
                    v-model="bulanUltah"
                    :items="bulan"
                    label="Bulan Ulang tahun"
                  ></v-combobox>
                </v-col>
                <v-col v-if="!tipePencarian">
                  <v-text-field
                    ref="tanggalUltah"
                    v-model="tanggalUltah"
                    label="Tanggal Ulang Tahun"
                    type="number"
                    single-line
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn color="primary" @click="praSearch"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </v-col>
                <v-col>
                  <v-combobox
                    ref="limit"
                    v-model="itemsPerPage"
                    :items="[10, 50, 100]"
                    label="Per Halaman"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn small @click="resetItem(item)" width="70" color="error">
          <v-icon small>mdi-restore</v-icon> Reset
        </v-btn>
      </template>
      <template v-slot:no-data>
        Silahkan melakukan pencarian member...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="cekSearch"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import PelangganAPIService from "../services/PelangganAPIServices";
import BarangAPIServices from "../services/BarangAPIServices";
import KodePosAPIService from "../services/KodePosAPIServices";
import firebase from "firebase";
import moment from "moment";
require("firebase/auth");

import { mapMutations } from "vuex";

export default {
  name: "Member",

  data: () => ({
    rules: {
      nama: value => !!value || "Masukkan nama.",
      alamat: value => !!value || "Masukkan alamat.",
      jenisKelamin: value => value != "" || "Masukkan jenis kelamin.",
      tanggalLahir: value => !!value || "Masukkan tanggal lahir.",
      kodepos: value => !!value || "Lakukan pencarian kodepos.",
      kodeToko: value => !!value || "Pilih toko cabang.",
      ktp: value =>
        value.length >= 6 || "Masukkan nomor identitas dengan benar",

      ktpAngka: value => {
        const pattern = /^[0-9]*$/;
        return pattern.test(value) || "Nomor identitas hanya mengandung angka";
      },
      ponsel: value => value.length >= 10 || "Masukkan nomor hp dengan benar",
      ponselAngka: value => {
        const pattern = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{9,14}$)/;
        return pattern.test(value) || "Nomor HP hanya mengandung angka";
      },
      itemsPerPage: value => {
        const pattern = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{9,14}$)/;
        return pattern.test(value) || "Per Halaman hanya mengandung angka";
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Masukkan email dengan benar.";
      },
      emailNull: value => !!value || "Masukkan email.",
      tanggalUltah: value =>
        value > 32 || value < 1 || value || "Tanggal tidak valid",
      bulanUltah: value => !!value || "Masukan bulan",
      counter_nama: value => value.length <= 100 || "Nama terlalu panjang.",
      counter_ktp: value =>
        value.length <= 16 || "Nomor identitas terlalu panjang.",
      counter_alamat: value => value.length <= 100 || "Alamat terlalu panjang.",
      counter_tempat: value =>
        value.length <= 50 || "Tempat lahir terlalu panjang.",
      counter_nomor: value => value.length <= 16 || "Nomor hp terlalu panjang.",
      counter_email: value => value.length <= 64 || "Email terlalu panjang."
    },

    listKodePos: [],
    inputKodePos: "",
    sessionNIK: "",
    token: "",
    pencarianTipe: "Umum",
    urutBerdasar: "Nama",
    urutan: "Ascending",
    urutBerdasars: [
      {
        text: "Kode",
        align: "start",
        value: "alias",
        sortable: false
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Nomor HP", value: "ponsel", sortable: false },
      { text: "Poin", value: "poin", sortable: false }
    ],
    urutans: ["Ascending", "Descending"],
    kodeToko: "",
    department: "",
    departments: [],
    listDepartment: [],

    upline: "",
    menu2: false,
    searchText: "",
    dialog: false,
    jenisKelamin: "",
    jenisKelamins: ["Laki-laki", "Perempuan"],
    agama: "",
    agamas: [],
    listAgama: [],
    pekerjaan: "",
    pekerjaans: [],
    listPekerjaan: [],
    kodepos: "",
    headers: [
      {
        text: "Kode",
        align: "start",
        value: "alias",
        sortable: false
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat_lengkap", sortable: false },
      { text: "Wilayah", value: "wilayah_lengkap", sortable: false },
      { text: "Nomor HP", value: "ponsel", sortable: false },
      { text: "Tanggal Lahir", value: "birthday", sortable: false },
      { text: "Tanggal Daftar", value: "tgl_entry", sortable: false },
      { text: "Poin", value: "poin", sortable: false },
      { text: "Action", value: "actions", sortable: false }
    ],
    members: [],
    editedIndex: -1,
    jenis_komisi: [
      {
        value: 0,
        text: "0%"
      },
      {
        value: 5,
        text: "5%"
      },
      {
        value: 10,
        text: "10%"
      }
    ],
    jenis_customer: [
      {
        value: 1,
        text: "B2B"
      },
      {
        value: 2,
        text: "Pelanggan Umum"
      },
      {
        value: 3,
        text: "Karyawan"
      },
      {
        value: 4,
        text: "Mantan Karyawan"
      },
      {
        value: 5,
        text: "Mantan BA (Brayat Ageng)"
      },
      {
        value: 6,
        text: "Relasi Toko"
      },
      {
        value: 7,
        text: "Relasi Khusus"
      },
      {
        value: 8,
        text: "Sponsorship"
      }
    ],
    jenis_customer_bon: [
      {
        value: 1,
        text: "Penanggung Jawab Eksternal"
      },
      {
        value: 2,
        text: "Penanggung Jawab Internal"
      },
      {
        value: 3,
        text: "Person Eksternal"
      },
      {
        value: 4,
        text: "Person Internal"
      },
      {
        value: 5,
        text: "Downline"
      }
    ],
    bulan: [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember"
    ],
    editedItem: {
      ktp: "",
      alias: "",
      nama: "",
      alamat: "",
      tempat_lahir: "",
      tgl_lahir: "",
      jns_kelamin: "",
      agama: "",
      pekerjaan: "",
      ponsel: "",
      email: "",
      id_kodepos: "",
      user_entry: "",
      user_update: ""
    },
    defaultItem: {
      ktp: "",
      alias: "",
      nama: "",
      alamat: "",
      tempa_lahir: "",
      tgl_lahir: "",
      jns_kelamin: "",
      agama: "",
      pekerjaan: "",
      ponsel: "",
      email: "",
      id_kodepos: "",
      user_entry: "",
      user_update: ""
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10
  }),

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Member" : "Edit Member";
    },

    tipePencarian() {
      return this.pencarianTipe === "Umum" ? true : false;
    },

    form() {
      return {
        nama: this.editedItem.nama,
        ktp: this.editedItem.ktp,
        alamat: this.editedItem.alamat,
        tanggalLahir: this.editedItem.tgl_lahir,
        jenisKelamin: this.editedItem.jns_kelamin,
        ponsel: this.editedItem.ponsel,
        email: this.editedItem.email,
        kodepos: this.editedItem.id_kodepos
      };
    },

    formTambah() {
      return {
        kodeToko: this.department,
        nama: this.editedItem.nama,
        ktp: this.editedItem.ktp,
        alamat: this.editedItem.alamat,
        tanggalLahir: this.editedItem.tgl_lahir,
        jenisKelamin: this.editedItem.jns_kelamin,
        ponsel: this.editedItem.ponsel,
        email: this.editedItem.email,
        kodepos: this.editedItem.id_kodepos
      };
    },

    validate() {
      var formHasErrors = false;
      if (this.formTitle == "Tambah Member") {
        Object.keys(this.formTambah).forEach(f => {
          if (!this.formTambah[f]) formHasErrors = true;
          this.$refs[f].validate(true);
        });
        return !formHasErrors;
      } else {
        Object.keys(this.form).forEach(f => {
          if (!this.form[f]) formHasErrors = true;
          this.$refs[f].validate(true);
        });
        return !formHasErrors;
      }
    }
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(token => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
      this.$store.commit("showProgress", {
        message: "Mengambil data toko"
      });
      await BarangAPIServices.getAllActiveDept(this.token, 100, 1)
        .then(response => {
          this.departments = response.data.data;
          this.departments.forEach(department => {
            this.listDepartment.push(department.department);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Toko gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.$store.commit("showProgress", {
        message: "Mengambil data agama"
      });
      await PelangganAPIService.getAgamas(this.token)
        .then(response => {
          this.agamas = response.data;
          this.agamas.forEach(agama => {
            this.listAgama.push(agama.nama);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Agama gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.$store.commit("showProgress", {
        message: "Mengambil data pekerjaan"
      });
      await PelangganAPIService.getPekerjaans(this.token)
        .then(response => {
          this.pekerjaans = response.data;
          this.pekerjaans.forEach(pekerjaan => {
            this.listPekerjaan.push(pekerjaan.nama);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Pekerjaan gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });

      this.urutBerdasar = this.urutBerdasars[
        this.urutBerdasars.findIndex(urut => urut.text == "Nama")
      ];
    },

    // getUpline() {
    //   if (this.editedItem.upline != "") {
    //     this.$store.commit("showProgress", {
    //       message: "Mencari data Upline",
    //     });
    //     PelangganAPIService.getPelangganByKDCust(
    //       this.token,
    //       this.editedItem.upline
    //     )
    //       .then((response) => {
    //         this.upline = response.data;
    //         this.upline.text = this.upline.nama + " - " + this.upline.alamat;
    //       })
    //       .catch((error) => {
    //         if (error.response) {
    //           if (error.response.data.message) {
    //             this.showSnackbar({
    //               color: "error",
    //               message: error.response.data.message,
    //             });
    //           }
    //         } else if (error.request) {
    //           this.showSnackbar({
    //             color: "error",
    //             message: "Koneksi ke server gagal (ERROR)",
    //           });
    //         } else {
    //           this.showSnackbar({
    //             color: "error",
    //             message: "Gagal melakukan pengecekan Upline.",
    //           });
    //         }
    //       })
    //       .finally(() => {
    //         this.$store.commit("closeProgress");
    //       });
    //   }
    // },

    cekSearch() {
      if (this.tipePencarian) {
        this.search();
      } else {
        this.searchUltah();
      }
    },
    praSearch() {
      this.page = 1;
      this.cekSearch();
    },
    searchUltah() {
      if (this.bulanUltah != null) {
        this.members = [];
        this.$store.commit("showProgress", {
          message: "Sedang mencari member"
        });
        var bulan = this.bulan.indexOf(this.bulanUltah) + 1;
        var tanggal;
        if (this.tanggalUltah == null) tanggal = 0;
        else tanggal = this.tanggalUltah;

        if (this.tanggalUltah > 31 || this.tanggalUltah < 1) {
          this.showSnackbar({
            color: "error",
            message: "Tanggal ulang tahun tidak valid"
          });
          return;
        }

        PelangganAPIService.searchUltah(
          this.token,
          bulan,
          tanggal,
          this.itemsPerPage,
          this.page
        )
          .then(response => {
            this.page = response.data.current_page;
            this.pageCount = response.data.last_page;

            response.data.data.forEach(async member => {
              member.alamat_lengkap = member.alamat;
              if (member.kodepos != null) {
                member.wilayah_lengkap =
                  member.kodepos.kelurahan +
                  ", " +
                  member.kodepos.kecamatan +
                  ", " +
                  member.kodepos.kabupaten +
                  ", " +
                  member.kodepos.provinsi;
              } else {
                member.wilayah_lengkap = member.wilayah;
              }
              member.birthday = moment(member.tgl_lahir)
                .locale("id")
                .format("LL");
              this.members.push(member);
            });
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Member gagal di muat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    async search() {
      var kolomUrut = this.urutBerdasar.value;
      var desc = this.urutans.findIndex(urutan => urutan === this.urutan);

      // if (this.searchText.length >= 3) {
      this.members = [];
      this.$store.commit("showProgress", {
        message: "Sedang mencari member"
      });
      await PelangganAPIService.search2(
        this.token,
        this.searchText,
        this.itemsPerPage,
        kolomUrut,
        desc,
        this.page
      )
        .then(response => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;

          response.data.data.forEach(async member => {
            member.alamat_lengkap = member.alamat;
            if (member.kodepos != null) {
              member.wilayah_lengkap =
                member.kodepos.kelurahan +
                ", " +
                member.kodepos.kecamatan +
                ", " +
                member.kodepos.kabupaten +
                ", " +
                member.kodepos.provinsi;
            } else {
              member.wilayah_lengkap = member.wilayah;
            }
            member.birthday = moment(member.tgl_lahir)
              .locale("id")
              .format("LL");
            this.members.push(member);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Member gagal di muat"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
      // }
    },

    async editItem(item) {
      this.editedIndex = this.members.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.jns_kelamin == "P") {
        this.jenisKelamin = this.jenisKelamins[1];
      } else if (this.editedItem.jns_kelamin == "L") {
        this.jenisKelamin = this.jenisKelamins[0];
      }

      this.agamas.forEach(agama => {
        if (agama.id == this.editedItem.agama) {
          this.agama = agama.nama;
        }
      });

      this.pekerjaans.forEach(pekerjaan => {
        if (pekerjaan.id == this.editedItem.pekerjaan) {
          this.pekerjaan = pekerjaan.nama;
        }
      });

      this.jenis_customer.forEach(jenis => {
        if (jenis.value == item.jenis_customer) {
          this.jenisCustomer = jenis.text;
        }
      });

      this.jenis_customer_bon.forEach(jenis => {
        if (jenis.value == item.jenis_customer_bon) {
          this.jenisCustomerBon = jenis.text;
        }
      });

      this.jenis_komisi.forEach(jenis => {
        if (jenis.value == item.jenis_komisi) {
          this.jenisKomisi = jenis.text;
        }
      });

      if (
        this.editedItem.id_kodepos != null ||
        this.editedItem.id_kodepos != "" ||
        this.editedItem.kodepos != undefined
      ) {
        this.$store.commit("showProgress", {
          message: "Proses mengambil data kodepos"
        });
        await KodePosAPIService.getByID(this.token, this.editedItem.id_kodepos)
          .then(response => {
            var kodepos = response.data;
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
            this.kodepos = kodepos.kodepos;
            this.inputKodePos = kodepos.text;
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Kodepos gagal dimuat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }

      if (
        item.upline != null ||
        item.upline != "" ||
        item.upline != undefined ||
        item.upline != "#"
      )
        // this.getUpline();

        this.dialog = true;
    },

    resetItem(item) {
      confirm(
        "Reset Pelanggan " +
          item.nama +
          "?\n\nReset akan mengharuskan pelanggan melakukan sinkronisasi ulang di Member App."
      ) &&
        (this.$store.commit("showProgress", {
          message: "Proses mereset member"
        }),
        PelangganAPIService.resetUID(this.token, item.alias, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Pelanggan berhasil di reset"
            });
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Member gagal di reset"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    selectKodePos() {
      this.kodepos = this.inputKodePos.kodepos;
    },

    searchKodePos() {
      this.listKodePos = [];
      this.$store.commit("showProgress", {
        message: "Mencari kodepos " + this.inputKodePos
      });
      KodePosAPIService.search(this.token, this.inputKodePos)
        .then(response => {
          response.data.data.forEach(kodepos => {
            kodepos.text =
              kodepos.kelurahan +
              ", " +
              kodepos.kecamatan +
              ", " +
              kodepos.kabupaten +
              ", " +
              kodepos.provinsi;
            this.listKodePos.push(kodepos);
          });
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Kodepos gagal dicari"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    close() {
      Object.keys(this.formTambah).forEach(f => {
        this.$refs[f].resetValidation();
      });
      this.kodepos = "";
      this.listKodePos = [];
      this.inputKodePos = "";
      this.dialog = false;
      this.jenisKelamin = "";
      this.agama = "";
      this.pekerjaan = "";
      this.jenisCustomer = "";
      this.jenisCustomerBon = "";
      this.jenisKomisi = "";
      this.upline = "";
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.jenisKelamin == this.jenisKelamins[1]) {
        this.editedItem.jns_kelamin = "P";
      } else if (this.jenisKelamin == this.jenisKelamins[0]) {
        this.editedItem.jns_kelamin = "L";
      }

      this.agamas.forEach(agama => {
        if (this.agama == agama.nama) {
          this.editedItem.agama = agama.id;
        }
      });

      this.pekerjaans.forEach(pekerjaan => {
        if (this.pekerjaan == pekerjaan.nama) {
          this.editedItem.pekerjaan = pekerjaan.id;
        }
      });
      this.listKodePos.forEach(kodepos => {
        if (this.inputKodePos.text == kodepos.text) {
          this.editedItem.id_kodepos = kodepos.id;
        }
      });

      this.jenis_customer.forEach(jenis => {
        if (this.jenisCustomer == jenis) {
          this.editedItem.jenis_customer = jenis.value;
        }
      });

      this.jenis_customer_bon.forEach(jenis => {
        if (this.jenisCustomerBon == jenis) {
          this.editedItem.jenis_customer_bon = jenis.value;
        }
      });

      this.jenis_komisi.forEach(jenis => {
        if (this.jenisKomisi == jenis) {
          this.editedItem.jenis_komisi = jenis.value;
        }
      });

      // if (this.upline != null || this.upline != "") {
      //   this.editedItem.upline = this.upline.kd_customer;
      // }

      if (this.validate) {
        if (this.formTitle == "Tambah Member") {
          this.$store.commit("showProgress", {
            message: "Proses menambah member"
          });

          this.departments.forEach(department => {
            if (this.department == department.department) {
              this.kodeToko = department.id_department;
            }
          });
          this.editedItem.user_entry = this.sessionNIK;
          PelangganAPIService.create(this.token, this.kodeToko, this.editedItem)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah"
              });
              this.search();
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)"
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Member gagal ditambah"
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        } else {
          this.editedItem.user_update = this.sessionNIK;
          this.$store.commit("showProgress", {
            message: "Proses mengedit member"
          });
          PelangganAPIService.editProfile(
            this.token,
            this.editedItem.alias,
            this.editedItem
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit"
              });
              this.search();
            })
            .catch(error => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)"
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Member gagal di edit"
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid"
        });
      }
    },

    isiAlias() {
      this.$store.commit("showProgress", {
        message: "Proses generate kode member"
      });

      PelangganAPIService.generateAlias(this.token)
        .then(response => {
          this.editedItem.alias = response.data;
          if (this.jenisKelamin == this.jenisKelamins[1]) {
            this.editedItem.jns_kelamin = "P";
          } else if (this.jenisKelamin == this.jenisKelamins[0]) {
            this.editedItem.jns_kelamin = "L";
          }

          this.agamas.forEach(agama => {
            if (this.agama == agama.nama) {
              this.editedItem.agama = agama.id;
            }
          });

          this.pekerjaans.forEach(pekerjaan => {
            if (this.pekerjaan == pekerjaan.nama) {
              this.editedItem.pekerjaan = pekerjaan.id;
            }
          });
          this.listKodePos.forEach(kodepos => {
            if (this.inputKodePos.text == kodepos.text) {
              this.editedItem.id_kodepos = kodepos.id;
            }
          });

          this.editedItem.user_update = this.sessionNIK;
          PelangganAPIService.editProfile(
            this.token,
            this.editedItem.alias,
            this.editedItem
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit"
              });
              this.search();
            })
            .catch(error => {
              this.editedItem.alias = "";
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)"
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Member gagal di edit"
                });
              }
            })
            .finally(() => {});
        })
        .catch(error => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)"
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Gagal generate alias"
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    ...mapMutations(["showSnackbar"])
  }
};
</script>
<style scoped>
.v-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-outer-spin-button,
.v-input
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
