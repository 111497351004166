import axios from "axios";
import Properties from "../properties"

const API_URL = "https://pelanggan." +Properties.DOMAIN_URL +"/api/v1";

export default class KategoriPromoAPIService {
  constructor() {}
  static getAll = (token, limit, page) => {
    return axios.get(
      API_URL + "/kategori-promos/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
      }
    );
  }
  static getKode = (token, limit, page, kode) =>
    axios.get(
      API_URL + "/kategori-promos/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
        params: {kode: kode}
      }
    );

  static getAllUmum = (token, limit, page) =>
    axios.get(
      API_URL + "/kategori-promos/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
        params: { khusus: 0, status: "A" },
      }
    );

  static getAllKhusus = (token, limit, page) =>
    axios.get(
      API_URL + "/kategori-promos/limit/" + limit + "?page=" + page,
      {
        headers: { Authorization: "Bearer " + token },
        params: { khusus: 1, status: "A" },
      }
    );

  static edit = (token, kode, promo) =>
    axios.put(API_URL + "/kategori-promos/" + kode, promo, {
      headers: { Authorization: "Bearer " + token },
    });
  static create = (token, notif) =>
    axios.post(API_URL + "/kategori-promos", notif, {
      headers: { Authorization: "Bearer " + token },
    });
  static delete = (token, kode, nik) =>
    axios.delete(API_URL + "/kategori-promos/" + kode, {
      params: {
        user_update: nik,
      },
      headers: { Authorization: "Bearer " + token },
    });
}
