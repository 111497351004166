<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="admins"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">ADMIN SETUP</v-toolbar-title>
          <v-dialog v-model="dialog" max-width="500px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on" small
                ><v-icon class="white--text">mdi-plus</v-icon></v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text ref="form">
                <v-container>
                  <v-row align="center">
                    <v-col cols="12" md="10">
                      <v-text-field
                        :disabled="formTitle != 'Tambah Admin'"
                        ref="nik"
                        v-model="editedItem.nik"
                        label="NIK"
                        :rules="[rules.nik]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-btn
                        :disabled="formTitle != 'Tambah Admin'"
                        color="warn"
                        @click="getKaryawan"
                        >Cek</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-text-field
                    disabled="true"
                    ref="nama"
                    v-model="editedItem.nama"
                    label="Nama"
                    :rules="[rules.nama]"
                    key="nama"
                  ></v-text-field>
                  <v-combobox
                    ref="role"
                    v-model="editedItem.role"
                    :items="roles"
                    item-value="name"
                    item-text="name"
                    :return-object="true"
                    label="Role"
                    :rules="[rules.role]"
                  ></v-combobox>
                  <v-switch
                    v-model="editedItem.statusBool"
                    inset
                    :label="watchStatus"
                  ></v-switch>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="textGrey" text @click="close">Batal</v-btn>
                <v-btn color="accent" text @click="save">Simpan</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center" width="500px">
            <v-col cols="12" md="3">
              <v-text-field
                v-model="filterNIK"
                label="NIK"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field
                v-model="filterNama"
                label="Nama"
                hide-details
                clearable="true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="1">
              <v-btn color="primary" @click="praSearch"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="editItem(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn
          small
          @click="resetPass(item)"
          width="140"
          color="error"
          class="mr-1"
        >
          <v-icon small>mdi-refresh</v-icon> Reset Password
        </v-btn>
        <!-- <v-btn small @click="hapusItem(item)" width="70" color="error">
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn> -->
      </template>
      <template v-slot:no-data>
        Belum ada data admin...
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import KaryawanAPIServices from "../services/KaryawanAPIServices";
import AdminAPIServices from "../services/AdminAPIServices";
import firebase from 'firebase';
require('firebase/auth');

import { mapMutations } from "vuex";

export default {
  name: "AdminSetup",

  data: () => ({
    rules: {
      nik: (value) => !!value || "Pilih kategori.",
      role: (value) => !!value || "Masukkan role.",
      nama: (value) => !!value || "Silahkan melakukan pengecekan NIK.",
    },

    sessionNIK: "",
    token: "",

    dialog: false,

    headers: [
      {
        text: "NIK",
        align: "start",
        value: "nik",
        sortable: false,
      },
      { text: "Nama", value: "nama", sortable: false },
      { text: "Role", value: "role", sortable: false },
      { text: "Status", value: "textStatus", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],
    admins: [],
    roles: [],
    editedIndex: -1,
    filterNIK: "",
    filterNama: "",
    editedItem: {
      nik: "",
      nama: "",
      role: "",
      role_id: "",
      status: "",
      statusBool: true,
      textStatus: "",
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      nik: "",
      nama: "",
      role: "",
      status: "",
      statusBool: true,
      textStatus: "",
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Admin" : "Edit Admin";
    },

    watchStatus() {
      return this.editedItem.statusBool === true ? "Aktif" : "Tidak Aktif";
    },

    form() {
      return {
        nik: this.editedItem.nik,
        nama: this.editedItem.nama,
        role: this.editedItem.role,
      };
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    },
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit('logout');
        }
      } else {
        this.$store.commit('logout');
      }
      this.praSearch();
      this.getListRole();
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    search() {
      this.admins = [];
      this.$store.commit("showProgress", {
        message: "Mengambil data admin",
      });
      var body = {};
      if(this.filterNIK != "" && this.filterNIK != null)
        body.nik = this.filterNIK;
      if(this.filterNama != "" && this.filterNama != null)
        body.nama = this.filterNama;
      
      body.limit = this.itemsPerPage;
      AdminAPIServices.filter(this.token, body, this.page)
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.$store.commit("showProgress", {
            message: "Mengambil data nama admin",
          });
          response.data.data.forEach((admin) => {
            KaryawanAPIServices.getByNIK(this.token, admin.nik)
              .then((response) => {
                admin.nama = response.data.nama;
                if (admin.status == "A") {
                  admin.statusBool = true;
                  admin.textStatus = "Aktif";
                } else {
                  admin.statusBool = false;
                  admin.textStatus = "Tidak aktif";
                }
                this.admins.push(admin);
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.message) {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                } else if (error.request) {
                  this.showSnackbar({
                    color: "error",
                    message: "Koneksi ke server gagal (ERROR)",
                  });
                } else {
                  this.showSnackbar({
                    color: "error",
                    message: "Nama admin gagal dimuat.",
                  });
                }
              })
              .finally(() => {
                this.$store.commit("closeProgress");
              });
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Admin gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },
    getListRole(){
      KaryawanAPIServices.getRoleKaryawan(this.token).then((res)=>{
        this.roles = res.data
      })
    },
    getKaryawan() {
      if (this.editedItem.nik != "") {
        this.$store.commit("showProgress", {
          message: "Mencari data karyawan",
        });
        KaryawanAPIServices.getByNIK(this.token, this.editedItem.nik)
          .then((response) => {
            this.editedItem.nama = response.data.nama;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Gagal melakukan pengecekan NIK.",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.admins.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    resetPass(item) {
      confirm("Reset password " + item.nama + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses mereset password admin",
        }),
        KaryawanAPIServices.resetPassword(this.token, item.nik)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Password admin berhasil direset",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Admin gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          }));
    },

    close() {
      Object.keys(this.form).forEach((f) => {
        this.$refs[f].resetValidation();
      });
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.validate) {
        if (this.editedItem.statusBool == true) this.editedItem.status = "A";
        else this.editedItem.status = "T";

        if (this.formTitle == "Tambah Admin") {
          this.$store.commit("showProgress", {
            message: "Proses menambah admin",
          });
          this.editedItem.user_create = this.sessionNIK;
          this.editedItem.role_id = this.editedItem.role.id
          this.editedItem.role = this.editedItem.role.name
          
          
          AdminAPIServices.create(this.token, this.editedItem)
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di tambah",
              });
              KaryawanAPIServices.defaultPassword(
                this.token,
                this.editedItem.nik
              )
                .then(() => {})
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.message) {
                      if (error.response.data.message.includes("PRIMARY")) {
                        this.showSnackbar({
                          color: "error",
                          message: "Admin sudah ditambahkan.",
                        });
                      } else {
                        this.showSnackbar({
                          color: "error",
                          message: error.response.data.message,
                        });
                      }
                    }
                  } else if (error.request) {
                    this.showSnackbar({
                      color: "error",
                      message: "Koneksi ke server gagal (ERROR)",
                    });
                  } else {
                    this.showSnackbar({
                      color: "error",
                      message: "Admin gagal di tambah",
                    });
                  }
                })
                .finally(() => {});
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  if (error.response.data.message.includes("PRIMARY")) {
                    this.showSnackbar({
                      color: "error",
                      message: "Admin sudah ditambahkan",
                    });
                  } else {
                    this.showSnackbar({
                      color: "error",
                      message: error.response.data.message,
                    });
                  }
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Admin gagal di tambah",
                });
              }
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        } else {
          this.$store.commit("showProgress", {
            message: "Proses mengedit admin",
          });
          //change to ID
          this.editedItem.user_update = this.sessionNIK;
          this.editedItem.role_id = this.editedItem.role.id
          this.editedItem.role = this.editedItem.role.name
          AdminAPIServices.edit(
            this.token,
            this.editedItem.id,
            this.editedItem
          )
            .then(() => {
              this.close();
              this.showSnackbar({
                color: "secondary",
                message: "Data berhasil di edit",
              });
              this.initialize();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.message) {
                  this.showSnackbar({
                    color: "error",
                    message: error.response.data.message,
                  });
                }
              } else if (error.request) {
                this.showSnackbar({
                  color: "error",
                  message: "Koneksi ke server gagal (ERROR)",
                });
              } else {
                this.showSnackbar({
                  color: "error",
                  message: "Admin gagal di muat",
                });
              }
              this.snackbar = true;
              this.snackbarColor = "error";
            })
            .finally(() => {
              this.$store.commit("closeProgress");
            });
        }
      } else {
        this.showSnackbar({
          color: "error",
          message: "Input tidak valid",
        });
      }
    },

    ...mapMutations(["showSnackbar"]),
  },
};
</script>
