import axios from "axios";
import Properties from "../properties";

// const API_URL = "https://api." + Properties.DOMAIN_URL + "/poin-gifts/gifts";
const API_URL =
    "https://backend." + Properties.DOMAIN_URL + "/poin-gift/api/gifts";

export default class GiftPoinAPIService {
    constructor() {}
    config = this.$session.get("jwt");
    headers = {};

    static getAll = (token, limit, page) =>
        axios.get(API_URL + "/limit/" + limit + "?page=" + page, {
            Authorization: "Bearer " + token,
        });

    static filter = (token, body, page) =>
        axios.get(API_URL + "/searching/filter-gift?page=" + page, {
            params: body,
            Authorization: "Bearer " + token,
        });

    static filterOnPeriode = (token) =>
        axios.get(API_URL + "/filter/periode", {
            // params: body,
            Authorization: "Bearer " + token,
        });

    static getByID = (token, id) =>
        axios.get(API_URL + "/" + id, {
            Authorization: "Bearer " + token,
        });

    static edit = (token, id, gift) =>
        axios.post(API_URL + "/" + id, gift, {
            Authorization: "Bearer " + token,
        });
    static create = (token, gift) => {
        return axios.post(API_URL + "/", gift, {
            Authorization: "Bearer " + token,
        });
    };
    static delete = (token, id, nik) =>
        axios.delete(API_URL + "/" + id, {
            data: { user_update: nik },
            Authorization: "Bearer " + token,
        });
}
