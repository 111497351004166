<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="merchants"
      hide-default-footer
      class="elevation-1"
      :items-per-page="itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title class="mr-2">Setup Merchant</v-toolbar-title>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            v-on="on"
            small
            @click="tambahMerchant()"
            ><v-icon class="white--text">mdi-plus</v-icon></v-btn
          >
          <v-divider vertical light class="mx-2"></v-divider>
          <v-row align="center" width="500px"> </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          @click="detailMerchant(item)"
          width="70"
          color="green"
          class="mr-1 white--text"
        >
          <v-icon small>mdi-file</v-icon> Detail
        </v-btn>
        <v-btn
          small
          @click="editMerchant(item)"
          width="70"
          color="warn"
          class="mr-1"
        >
          <v-icon small>mdi-pencil</v-icon> Edit
        </v-btn>
        <v-btn
          small
          @click="deleteItem(item)"
          width="70"
          color="error"
          class="mr-1"
        >
          <v-icon small>mdi-delete</v-icon> Hapus
        </v-btn>
      </template>
      <template v-slot:no-data> Belum ada data merchant... </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        @input="search"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import MerchantAPIServices from "../../services/MerchantAPIServices";
import firebase from "firebase";
require("firebase/auth");

import ClassicEditor from "@ckeditor/ckeditor5-build-inline";
import { mapMutations } from "vuex";

export default {
  name: "SetupMerchant",

  data: () => ({
    rules: {},

    editor: ClassicEditor,
    editorConfig: {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "|",
        "indent",
        "outdent",
        "|",
        "undo",
        "redo",
      ],
    },

    merchants: [],

    sessionNIK: "",
    token: "",

    dialog: false,
    dialogCode: "",

    headers: [
      {
        text: "Nama",
        align: "start",
        value: "nama",
        sortable: false,
      },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Nomor Handphone", value: "no_ponsel", sortable: false },
      { text: "Nomor Telepon", value: "no_telpon", sortable: false },
      { text: "Action", value: "actions", sortable: false },
    ],

    editedIndex: -1,
    editedItem: {
      nama: "",
      alamat: "",
      contact: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },
    defaultItem: {
      nama: "",
      alamat: "",
      contact: "",
      created_at: "",
      updated_at: "",
      user_create: "",
      user_update: "",
    },

    page: 1,
    pageCount: 1,
    itemsPerPage: 10,
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then((token) => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }

      this.praSearch();
    },

    praSearch() {
      this.page = 1;
      this.search();
    },

    tambahMerchant() {
      this.storeMerchant({
        merchant: null,
        idxMerchant: -1,
      });
      this.$router.push("/setup-merchant/form-merchant");
    },

    detailMerchant(item) {
      this.storeMerchant({
        merchant: item,
        idxMerchant: this.merchants.indexOf(item),
      });
      this.$router.push("/setup-merchant/detail-merchant");
    },

    editMerchant(item) {
      this.storeMerchant({
        merchant: item,
        idxMerchant: this.merchants.indexOf(item),
      });
      this.$router.push("/setup-merchant/form-merchant");
    },

    deleteItem(item) {
      confirm("Hapus merchant " + item.nama + "?") &&
        (this.$store.commit("showProgress", {
          message: "Proses menghapus merchant",
        }),
        MerchantAPIServices.delete(this.token, item.id, this.sessionNIK)
          .then(() => {
            this.showSnackbar({
              color: "secondary",
              message: "Merchant berhasil dihapus",
            });
            this.initialize();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message,
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)",
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal dihapus",
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
            this.initialize();
          }));
    },

    search() {
      this.$store.commit("showProgress", {
        message: "Mengambil data merchant",
      });
      // var body = {};
      // if(this.filterDepartment != "" && this.filterDepartment != null)
      //   body.toko = this.filterDepartment.id_department;
      // if(this.searchText != "" && this.searchText != null)
      //   body.judul = this.searchText;
      // if(this.filterTahun != "" && this.filterTahun != null)
      //   body.tahun = this.filterTahun;
      // body.limit = this.itemsPerPage;
      MerchantAPIServices.get(this.token, 10, this.page)
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.merchants = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.message) {
              this.showSnackbar({
                color: "error",
                message: error.response.data.message,
              });
            }
          } else if (error.request) {
            this.showSnackbar({
              color: "error",
              message: "Koneksi ke server gagal (ERROR)",
            });
          } else {
            this.showSnackbar({
              color: "error",
              message: "Gift gagal dimuat.",
            });
          }
        })
        .finally(() => {
          this.$store.commit("closeProgress");
        });
    },

    close() {
      this.dialog = false;
      this.dialogCode = "";
      this.selectedDepartments = [];
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.keyFoto++;
        this.keyThumbnail++;
        this.showFoto = false;
        this.showThumbnail = false;

        this.onAddFiles(null);
        this.onAddFilesThumbnail(null);

        Object.keys(this.formTambah).forEach((f) => {
          this.$refs[f].resetValidation();
        });
      }, 300);
    },

    ...mapMutations(["showSnackbar", "storeMerchant"]),
  },
};
</script>
