<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="9">
        <v-card class="elevation-1">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title
              >Form Rekap Penukaran Promo Merchant</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-container>
            <v-card-text ref="form">
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAwal"
                        v-model="periode_awal"
                        label="Periode Awal"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAwal]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_awal"
                      @input="
                        menu1 = false;
                        getMerchant();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" sm="6" md="6">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        ref="periodeAkhir"
                        v-model="periode_akhir"
                        label="Periode Akhir"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on"
                        :rules="[rules.periodeAkhir]"
                        :disabled="showTabel"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      header-color="primary"
                      color="secondary"
                      v-model="periode_akhir"
                      @input="
                        menu2 = false;
                        getMerchant();
                      "
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-combobox
                ref="merchant"
                :rules="[rules.merchant]"
                v-model="selectedMerchant"
                :items="merchants"
                label="Pilih Merchant"
                item-text="nama"
                :disabled="showTabel"
                multiple
              ></v-combobox>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-btn color="blue" text @click="reset">Reset Form</v-btn>
            <v-spacer />
            <v-btn color="warn" @click="buat">Buat Laporan</v-btn>
            <v-btn color="primary" :disabled="disableTukar" @click="cetak"
              >Cetak PDF</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-show="showTabel">
      <v-col cols="12" md="12">
        <v-list>
          <div class="text-h5 mt-5">
            Rekap Penukaran Promo Merchant
          </div>
          <v-list-item>Laporan dicetak pada: {{ fnow }}</v-list-item>
          <v-list-item
            >Periode Laporan Promo: {{ fperiode_awal }} s/d
            {{ fperiode_akhir }}</v-list-item
          >
          <v-list-item>Penukaran di: Amigo</v-list-item>
          <v-data-table
            :headers="headers"
            :items="unit"
            hide-default-footer
            class="elevation-1"
            :items-per-page="1000"
          >
            <template v-slot:no-data>
              Belum ada data penukaran promo...
            </template>
          </v-data-table>
          <v-list-item></v-list-item>
          <v-list-item>Penukaran di: Merchant</v-list-item>
          <v-data-table
            :headers="headers2"
            :items="unit2"
            hide-default-footer
            class="elevation-1"
            :items-per-page="1000"
          >
            <template v-slot:no-data>
              Belum ada data penukaran promo...
            </template>
          </v-data-table>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
// import BarangAPIServices from "../../services/BarangAPIServices";
import LaporanTukarMerchantServices from "../../services/LaporanTukarMerchantServices";
import firebase from "firebase";
require("firebase/auth");
import moment from "moment";

import { mapMutations } from "vuex";

export default {
  data: () => ({
    rules: {
      periodeAwal: value => !!value || "Masukkan periode awal.",
      periodeAkhir: value => !!value || "Masukkan periode akhir.",
      toko: value => !!value || "Masukkan toko.",
      merchant: value => !!value || "Masukkan merchant.",
      promo: value => !!value || "Masukkan promo.",
      penukaran_di: value => !!value || "Masukkan tempat penukaran."
    },
    headers: [
      {
        text: "No.",
        align: "start",
        value: "no",
        sortable: false,
        title: "No.",
        dataKey: "no"
      },
      {
        text: "Jenis Promo",
        align: "start",
        value: "jenis_promo",
        sortable: false,
        title: "Jenis Promo",
        dataKey: "jenis_promo"
      },
      {
        text: "Unit Kerja",
        align: "start",
        value: "toko",
        sortable: false,
        title: "Unit Kerja",
        dataKey: "toko"
      },
      {
        text: "Jumlah Kode Voucher",
        align: "start",
        value: "jml_voucher",
        sortable: false,
        title: "Jumlah Kode Voucher",
        dataKey: "jml_voucher"
      },
      {
        text: "Jumlah Pelanggan",
        align: "start",
        value: "jml_pelanggan",
        sortable: false,
        title: "Jumlah Pelanggan",
        dataKey: "jml_pelanggan"
      },
      {
        text: "Total Belanja",
        align: "end",
        value: "total",
        sortable: false,
        title: "Total Belanja",
        dataKey: "total"
      },
      {
        text: "Total Belanja (Nett)",
        align: "end",
        value: "nett",
        sortable: false,
        title: "Total Belanja (Nett)",
        dataKey: "nett"
      },
      {
        text: "Total Biaya",
        align: "end",
        value: "biaya",
        sortable: false,
        title: "Total Biaya",
        dataKey: "biaya"
      }
    ],

    headers2: [
      {
        text: "No.",
        align: "start",
        value: "no",
        sortable: false,
        title: "No.",
        dataKey: "no"
      },
      {
        text: "Merchant",
        align: "start",
        value: "merchant",
        sortable: false,
        title: "Merchant",
        dataKey: "merchant"
      },
      {
        text: "Jenis Promo",
        align: "start",
        value: "promo",
        sortable: false,
        title: "Jenis Promo",
        dataKey: "promo"
      },
      {
        text: "Jumlah Kode Voucher",
        align: "start",
        value: "jml_kode",
        sortable: false,
        title: "Jumlah Kode Voucher",
        dataKey: "jml_kode"
      },
      {
        text: "Jumlah Pelanggan",
        align: "start",
        value: "jml_pelanggan",
        sortable: false,
        title: "Jumlah Pelanggan",
        dataKey: "jml_pelanggan"
      }
    ],

    token: "",
    sessionNik: "",
    showTabel: false,
    disableTukar: true,

    selectedDepartments: [],
    departments: [],
    listDepartment: [],

    selectedMerchant: [],
    merchants: [],

    selectedPromo: [],
    promos: [],

    penukaran_di: "",

    menu1: false,
    periode_awal: "",
    menu2: false,
    periode_akhir: "",
    unit: []
  }),

  created() {
    this.initialize();
  },

  computed: {
    form() {
      return {};
    },

    validate() {
      var formHasErrors = false;

      Object.keys(this.form).forEach(f => {
        if (!this.form[f]) formHasErrors = true;

        this.$refs[f].validate(true);
      });

      return !formHasErrors;
    }
  },

  methods: {
    async initialize() {
      if (this.$session.exists()) {
        try {
          await firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(token => {
              this.token = token.token;
              this.sessionNIK = this.$session.get("nik");
            });
        } catch (e) {
          this.$store.commit("logout");
        }
      } else {
        this.$store.commit("logout");
      }
    },

    async getMerchant() {
      if (this.periode_awal != "" && this.periode_akhir != "") {
        var body = {};
        body.periode_awal = this.periode_awal;
        body.periode_akhir = this.periode_akhir;

        this.$store.commit("showProgress", {
          message: "Mengambil data Merchant"
        });
        await LaporanTukarMerchantServices.getMerchant(body, this.token)
          .then(response => {
            this.merchants = [];
            this.selectedMerchant = [];
            this.merchants = response.data;
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    async buat() {
      this.now = new Date().toLocaleString();
      this.fnow = moment(this.now)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_awal = moment(this.periode_awal)
        .locale("id")
        .format("DD MMMM yyyy");
      this.fperiode_akhir = moment(this.periode_akhir)
        .locale("id")
        .format("DD MMMM yyyy");
      if (
        this.periode_awal != "" &&
        this.periode_akhir != "" &&
        this.selectedMerchant != {}
      ) {
        var body = {};
        body.periode_awal = this.periode_awal;
        body.periode_akhir = this.periode_akhir;
        body.id_merchant = [];
        this.selectedMerchant.forEach(merchant => {
          body.id_merchant.push(merchant.id);
        });

        this.$store.commit("showProgress", {
          message: "Mengambil data Promo"
        });
        await LaporanTukarMerchantServices.getRekap(body, this.token)
          .then(response => {
            this.unit = [];
            var rekapAmigo = response.data.rekap_amigo;
            var counter = 0;
            var stopper = false;
            var tempPromo = [];
            rekapAmigo.rekap.forEach(promo => {
              
              tempPromo.forEach(isi => {
                if (isi.judul == promo.judul) {
                  stopper = true;
                }
                if (stopper) return;
              });
              if (!stopper) {
                tempPromo.push(promo);
                if (promo.items.length == 0) {
                  counter++;
                  this.unit.push({
                    no: counter,
                    jenis_promo: promo.judul,
                    toko: "-",
                    jml_voucher: "-",
                    jml_pelanggan: "-",
                    total: "-",
                    nett: "-",
                    biaya: "-"
                  });
                }
                promo.items.forEach(toko => {
                  counter++;
                  this.unit.push({
                    no: counter,
                    jenis_promo: promo.judul,
                    toko: toko.kode_toko,
                    jml_voucher: toko.jml_kode,
                    jml_pelanggan: toko.jml_pelanggan,
                    total: toko.total_belanja,
                    nett: toko.total_nett,
                    biaya: toko.total_biaya
                  });
                });
              }
            });
            this.unit.push({
              no: "",
              jenis_promo: "Sub Total:",
              toko: "",
              jml_voucher: rekapAmigo.total_kode,
              jml_pelanggan: rekapAmigo.total_pelanggan,
              total: rekapAmigo.sub_total_belanja,
              nett: rekapAmigo.sub_total_nett,
              biaya: rekapAmigo.sub_total_biaya
            });

            this.unit2 = [];
            var rekapMerchant = response.data.rekap_merchant;
            var counter2 = 0;

            rekapMerchant.rekap.forEach(promo => {
              counter2++;
              this.unit2.push({
                no: counter2,
                merchant: promo.merchant,
                promo: promo.promo,
                jml_kode: promo.jml_kode,
                jml_pelanggan: promo.jml_pelanggan
              });
            });
            this.unit2.push({
              no: "",
              merchant: "",
              promo: "Sub Total: ",
              jml_kode: rekapMerchant.total_kode,
              jml_pelanggan: rekapMerchant.total_pelanggan
            });

            this.showTabel = true;
            this.disableTukar = false;
          })
          .catch(error => {
            if (error.response) {
              if (error.response.data.message) {
                this.showSnackbar({
                  color: "error",
                  message: error.response.data.message
                });
              }
            } else if (error.request) {
              this.showSnackbar({
                color: "error",
                message: "Koneksi ke server gagal (ERROR)"
              });
            } else {
              this.showSnackbar({
                color: "error",
                message: "Merchant gagal di muat"
              });
            }
          })
          .finally(() => {
            this.$store.commit("closeProgress");
          });
      }
    },

    cetak() {
      var doc = new jsPDF("l", "pt", "a4");

      doc.setFontSize(14);
      doc.setFontType("bold");
      doc.text(25, 50, "Rekap Penukaran Promo Merchant");
      doc.setFontSize(10);
      doc.setFontType("normal");

      doc.text(
        25,
        65,
        "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
      );

      var counterMerch = 0;
      var listMerch = "";
      var maxMerch = this.selectedMerchant.length;
      this.selectedMerchant.forEach(item => {
        if (counterMerch < maxMerch - 1) listMerch += item.nama + ", ";
        else listMerch += item.nama;
        counterMerch++;
      });

      doc.text(25, 80, "List Merchant: " + listMerch);
      doc.text(25, 95, "Tempat Penukaran: Amigo");

      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.setFontType("normal");

      doc.autoTable(this.headers, this.unit, { margin: { top: 110 } });

      doc.addPage();

      doc.setFontSize(10);
      doc.text(25, 95, "Tempat Penukaran: Merchant");

      doc.setFontSize(12);
      doc.setFontType("bold");
      doc.setFontType("normal");

      doc.autoTable(this.headers2, this.unit2, { margin: { top: 110 } });

      var pageCount = doc.internal.getNumberOfPages();

      doc.setFont("helvetica");
      doc.setFontType("bold");
      doc.setFontSize(9);
      doc.setTextColor(125, 125, 125);

      for (var i = 0; i < pageCount; i++) {
        doc.text(
          25,
          25,
          "Periode: " + this.fperiode_awal + " s/d " + this.fperiode_akhir
        );

        doc.setPage(i);
        doc.text(
          25,
          575,
          "Halaman: " +
            doc.internal.getCurrentPageInfo().pageNumber +
            " dari " +
            pageCount
        );
        doc.text(680, 575, "Dicetak pada: " + this.fnow);
      }
      doc.save("Rekap_Promo_Merchant_" + this.now + ".pdf");
    }
  },
  ...mapMutations(["showSnackbar"])
};
</script>
