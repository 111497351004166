import axios from "axios";
import Properties from "../properties"

const API_URL = "https://pelanggan." +Properties.DOMAIN_URL +"/api/v1";

export default class LaporanTukarServices {
  constructor() {}
  config = this.$session.get("jwt");

  static getLaporan = (tglAwal, tglAkhir, body, token) =>
    axios.get(
      API_URL + "/rekap-vocs/periode-awal/" +tglAwal +"/periode-akhir/" +tglAkhir,
      {
        params: body,
        headers: { Authorization: "Bearer " + token },
      },
    );
}
